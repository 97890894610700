import firebase from 'firebase/app';
import * as actions from './actionTypes';

export const addAnalyteadditionalinformation = (analyteadditionalinformation) => async (dispatch, getState) => {
    console.log('bbbb', analyteadditionalinformation);
  try {
    dispatch({ type: actions.ADD_TODO_START });
    const uid = getState().firebase.auth.uid;
    const firestore = firebase.firestore();
    await firestore.collection('analyteadditionalinformations').add({
      ...analyteadditionalinformation,
      authorId: uid,
      createdAt: new Date(),
      updatedAt: new Date(),
    });
    dispatch({ type: actions.ADD_TODO_SUCESS });
  } catch (err) {
    dispatch({ type: actions.ADD_TODO_FAIL, err });
  }
};

export const updateAnalyteadditionalinformation = (analyteadditionalinformation, id) => async (dispatch) => {
    console.log('bbbb', analyteadditionalinformation);
  try {
    dispatch({ type: actions.UPDATE_TODO_START });
    const firestore = firebase.firestore();
    await firestore.collection('analyteadditionalinformations').doc(id).update({    
      name: analyteadditionalinformation.name,
      analyte: analyteadditionalinformation.analyte,
      explanation: analyteadditionalinformation.explanation,
      mandatory: analyteadditionalinformation.mandatory,
      comment: analyteadditionalinformation.comment,
      options: analyteadditionalinformation.options,
      updatedAt: new Date(),
    });
    dispatch({ type: actions.UPDATE_TODO_SUCESS });
  } catch (err) {
    dispatch({ type: actions.UPDATE_TODO_FAIL, err });
  }
};

export const deleteAnalyteadditionalinformation = (id) => async (dispatch) => {
    console.log('bbbb', id);
  try {
    dispatch({ type: actions.DELETE_TODO_START });
    const firestore = firebase.firestore();
    await firestore.collection('analyteadditionalinformations').doc(id).delete();
    dispatch({ type: actions.DELETE_TODO_SUCESS });
  } catch (err) {
    dispatch({ type: actions.DELETE_TODO_FAIL, err });
  }
};
