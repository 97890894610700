export const meta = {
  parameters: {
    "ALT - GPT": {
      "target": 21,
      "bias": 9.5,
      "limit": 30
    },
    "AST - GOT": {
      "target": 23,
      "bias": 6.5,
      "limit": 30
    },
    "Albumin": {
      "target": 42,
      "bias": 2.4,
      "limit": 50
    },
    "Alkaline Phosphatase": {
      "target": 73,
      "bias": 6.8,
      "limit": 30
    },
    "CRP": {
      "target": 2.6,
      "bias": 9.6,
      "limit": 30
    },
    "Calcium": {
      "target": 2.35,
      "bias": 1.7,
      "limit": 700
    },
    "Chloride": {
      "target": 102.5,
      "bias": 1,
      "limit": 50
    },
    "Creatinine": {
      "target": 77,
      "bias": 3.9,
      "limit": 30
    },
    "FT4": {
      "target": 11.94, //15,
      "bias": 0.5, //3.3  
      "limit": 30
    },
    "GGT": {
      "target": 22,
      "bias": 9.1,
      "limit": 30
    },
    "Glucose": {
      "target": 4.9,
      "bias": 3.1,
      "limit": 30
    },
    "Inorganic phosphor": {
      "target": 1.13,
      "bias": 4.4,
      "limit": 20
    },
    "LDH": {
      "target": 175,
      "bias": 4.6,
      "limit": 30
    },
    "Magnesium": {
      "target": 0.83,
      "bias": 3,
      "limit": 70
    },
    "K": {
      "target": 4.25,
      "bias": 2.4,
      "limit": 30
    },
    "Na": {
      "target": 140.5,
      "bias": 0.7,
      "limit": 30
    },
    "Sodium": {
      "target": 140.5,
      "bias": 0.7,
      "limit": 70
    },
    "TSH": {
      "target": 1.56,
      "bias": 7.7,
      "limit": 30
    },
    "Urea": {
      "target": 5,
      "bias": 6,
      "limit": 30
    },
    "Uric acid": {
      "target": 310,
      "bias": 4.8,
      "limit": 50
    },
    "total-Bilirubin": {
      "target": 10,
      "bias": 10,
      "limit": 30
    },
    "total-Cholesterol": {
      "target": 5.2,
      "bias": 3.8,
      "limit": 20
    },
    "total-Protein": {
      "target": 72,
      "bias": 1.4,
      "limit": 50
    },
    "Potassium": {
      "target": 4.25,
      "bias": 2.4,
      "limit": 30
    }
  },
  params: [
    "Albumin",
    "Alkaline Phosphatase",
    "ALT - GPT",
    "AST - GOT",
    "Calcium",
    "Chloride",
    "Creatinine",
    "FT4",
    "GGT",
    "Glucose",
    "Inorganic phosphor",
    "LDH",
    "Magnesium",
    "Potassium",
    "Sodium",
    "total-Bilirubin",
    "total-Cholesterol",
    "total-Protein",
    "TSH",
    "Urea",
    "Uric acid",
  ],
  units: ["g/L", "mg/L", "mIU/L", "mmol/L", "pmol/L", "U/L", "µmol/L"],
  instruments: [
    {
      id: 1,
      name: "Instrument 1",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 2,
      name: "Instrument 2",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 3,
      name: "Instrument 3",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 4,
      name: "Instrument 4",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 5,
      name: "Instrument 5",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 6,
      name: "Instrument 6",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 7,
      name: "Instrument 7",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 8,
      name: "Instrument 8",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 9,
      name: "Instrument 9",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 10,
      name: "Instrument 10",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 11,
      name: "Instrument 11",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 12,
      name: "Instrument 12",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 13,
      name: "Instrument 13",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 14,
      name: "Instrument 14",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 15,
      name: "Instrument 15",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 16,
      name: "Instrument 16",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 17,
      name: "Instrument 17",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 18,
      name: "Instrument 18",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 19,
      name: "Instrument 19",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 20,
      name: "Instrument 20",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 21,
      name: "Instrument 21",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 22,
      name: "Instrument 22",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 23,
      name: "Instrument 23",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 24,
      name: "Instrument 24",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 25,
      name: "Instrument 25",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 26,
      name: "Instrument 26",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 27,
      name: "Instrument 27",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 28,
      name: "Instrument 28",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 29,
      name: "Instrument 29",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 30,
      name: "Instrument 30",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 31,
      name: "Instrument 31",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 32,
      name: "Instrument 32",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 33,
      name: "Instrument 33",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 34,
      name: "Instrument 34",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 35,
      name: "Instrument 35",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 36,
      name: "Instrument 36",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 37,
      name: "Instrument 37",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 38,
      name: "Instrument 38",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 39,
      name: "Instrument 39",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 40,
      name: "Instrument 40",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 41,
      name: "Instrument 41",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 42,
      name: "Instrument 42",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 43,
      name: "Instrument 43",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 44,
      name: "Instrument 44",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 45,
      name: "Instrument 45",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 46,
      name: "Instrument 46",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 47,
      name: "Instrument 47",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 48,
      name: "Instrument 48",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 49,
      name: "Instrument 49",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 50,
      name: "Instrument 50",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 51,
      name: "Instrument 51",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 52,
      name: "Instrument 52",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 53,
      name: "Instrument 53",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 54,
      name: "Instrument 54",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 55,
      name: "Instrument 55",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 56,
      name: "Instrument 56",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 57,
      name: "Instrument 57",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 58,
      name: "Instrument 58",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 59,
      name: "Instrument 59",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 60,
      name: "Instrument 60",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 61,
      name: "Instrument 61",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 62,
      name: "Instrument 62",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 63,
      name: "Instrument 63",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 64,
      name: "Instrument 64",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 65,
      name: "Instrument 65",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 66,
      name: "Instrument 66",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 67,
      name: "Instrument 67",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 68,
      name: "Instrument 68",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 69,
      name: "Instrument 69",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 70,
      name: "Instrument 70",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 71,
      name: "Instrument 71",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 72,
      name: "Instrument 72",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 73,
      name: "Instrument 73",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 74,
      name: "Instrument 74",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 75,
      name: "Instrument 75",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 76,
      name: "Instrument 76",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 77,
      name: "Instrument 77",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 78,
      name: "Instrument 78",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 79,
      name: "Instrument 79",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 80,
      name: "Instrument 80",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 81,
      name: "Instrument 81",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 82,
      name: "Instrument 82",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 83,
      name: "Instrument 83",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 84,
      name: "Instrument 84",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 85,
      name: "Instrument 85",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 86,
      name: "Instrument 86",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 87,
      name: "Instrument 87",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 88,
      name: "Instrument 88",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 89,
      name: "Instrument 89",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 90,
      name: "Instrument 90",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 91,
      name: "Instrument 91",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 92,
      name: "Instrument 92",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 93,
      name: "Instrument 93",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 94,
      name: "Instrument 94",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 95,
      name: "Instrument 95",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 96,
      name: "Instrument 96",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 97,
      name: "Instrument 97",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 98,
      name: "Instrument 98",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 99,
      name: "Instrument 99",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 100,
      name: "Instrument 100",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 101,
      name: "Instrument 101",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 102,
      name: "Instrument 102",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 103,
      name: "Instrument 103",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 104,
      name: "Instrument 104",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 105,
      name: "Instrument 105",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 106,
      name: "Instrument 106",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 107,
      name: "Instrument 107",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 108,
      name: "Instrument 108",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 109,
      name: "Instrument 109",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 110,
      name: "Instrument 110",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 111,
      name: "Instrument 111",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 112,
      name: "Instrument 112",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 113,
      name: "Instrument 113",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 114,
      name: "Instrument 114",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 115,
      name: "Instrument 115",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 116,
      name: "Instrument 116",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 117,
      name: "Instrument 117",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 118,
      name: "Instrument 118",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 119,
      name: "Instrument 119",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 120,
      name: "Instrument 120",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 121,
      name: "Instrument 121",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 122,
      name: "Instrument 122",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 123,
      name: "Instrument 123",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 124,
      name: "Instrument 124",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 125,
      name: "Instrument 125",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 126,
      name: "Instrument 126",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 127,
      name: "Instrument 127",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 128,
      name: "Instrument 128",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 129,
      name: "Instrument 129",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 130,
      name: "Instrument 130",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 131,
      name: "Instrument 131",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 132,
      name: "Instrument 132",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 133,
      name: "Instrument 133",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 134,
      name: "Instrument 134",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 135,
      name: "Instrument 135",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 136,
      name: "Instrument 136",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 137,
      name: "Instrument 137",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 138,
      name: "Instrument 138",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 139,
      name: "Instrument 139",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 140,
      name: "Instrument 140",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 141,
      name: "Instrument 141",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 142,
      name: "Instrument 142",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 143,
      name: "Instrument 143",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 144,
      name: "Instrument 144",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 145,
      name: "Instrument 145",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 146,
      name: "Instrument 146",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 147,
      name: "Instrument 147",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 148,
      name: "Instrument 148",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 149,
      name: "Instrument 149",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 150,
      name: "Instrument 150",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 151,
      name: "Instrument 151",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 152,
      name: "Instrument 152",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 153,
      name: "Instrument 153",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 154,
      name: "Instrument 154",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 155,
      name: "Instrument 155",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 156,
      name: "Instrument 156",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 157,
      name: "Instrument 157",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 158,
      name: "Instrument 158",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 159,
      name: "Instrument 159",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 160,
      name: "Instrument 160",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 161,
      name: "Instrument 161",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 162,
      name: "Instrument 162",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 163,
      name: "Instrument 163",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 164,
      name: "Instrument 164",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 165,
      name: "Instrument 165",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 166,
      name: "Instrument 166",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 167,
      name: "Instrument 167",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 168,
      name: "Instrument 168",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 169,
      name: "Instrument 169",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 170,
      name: "Instrument 170",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 171,
      name: "Instrument 171",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 172,
      name: "Instrument 172",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 173,
      name: "Instrument 173",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 174,
      name: "Instrument 174",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 175,
      name: "Instrument 175",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 176,
      name: "Instrument 176",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 177,
      name: "Instrument 177",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 178,
      name: "Instrument 178",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 179,
      name: "Instrument 179",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 180,
      name: "Instrument 180",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 181,
      name: "Instrument 181",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 182,
      name: "Instrument 182",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 183,
      name: "Instrument 183",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 184,
      name: "Instrument 184",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 185,
      name: "Instrument 185",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 186,
      name: "Instrument 186",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 187,
      name: "Instrument 187",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 188,
      name: "Instrument 188",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 189,
      name: "Instrument 189",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 190,
      name: "Instrument 190",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 191,
      name: "Instrument 191",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 192,
      name: "Instrument 192",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 193,
      name: "Instrument 193",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 194,
      name: "Instrument 194",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 195,
      name: "Instrument 195",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 196,
      name: "Instrument 196",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 197,
      name: "Instrument 197",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 198,
      name: "Instrument 198",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 199,
      name: "Instrument 199",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 200,
      name: "Instrument 200",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 201,
      name: "Instrument 201",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 202,
      name: "Instrument 202",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 203,
      name: "Instrument 203",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 204,
      name: "Instrument 204",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 205,
      name: "Instrument 205",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 206,
      name: "Instrument 206",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 207,
      name: "Instrument 207",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 208,
      name: "Instrument 208",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 209,
      name: "Instrument 209",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 210,
      name: "Instrument 210",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 211,
      name: "Instrument 211",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 212,
      name: "Instrument 212",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 213,
      name: "Instrument 213",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 214,
      name: "Instrument 214",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 215,
      name: "Instrument 215",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 216,
      name: "Instrument 216",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 217,
      name: "Instrument 217",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 218,
      name: "Instrument 218",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 219,
      name: "Instrument 219",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 220,
      name: "Instrument 220",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 221,
      name: "Instrument 221",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 222,
      name: "Instrument 222",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 223,
      name: "Instrument 223",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 224,
      name: "Instrument 224",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 225,
      name: "Instrument 225",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 226,
      name: "Instrument 226",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 227,
      name: "Instrument 227",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 228,
      name: "Instrument 228",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 229,
      name: "Instrument 229",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 230,
      name: "Instrument 230",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 231,
      name: "Instrument 231",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 232,
      name: "Instrument 232",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 233,
      name: "Instrument 233",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 234,
      name: "Instrument 234",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 235,
      name: "Instrument 235",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 236,
      name: "Instrument 236",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 237,
      name: "Instrument 237",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 238,
      name: "Instrument 238",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 239,
      name: "Instrument 239",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 240,
      name: "Instrument 240",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
    {
      id: 241,
      name: "Instrument 241",
      manu: 1,
      model: 1,
      type: 1,
      method: 1,
      lab: 1,
    },
  ],
};

export const data = [
  {
    all: 143,
    day: "Thursday",
    hypo: 6,
    unit: "pmol/L",
    hyper: 6,
    result: "11.0423999999999",
    date: "2015-01-01",
  },
  {
    all: 130,
    result: "10.6572",
    hyper: 6,
    hypo: 6,
    unit: "pmol/L",
    day: "Friday",
    date: "2015-01-02",
  },
  {
    hypo: 0,
    all: 160,
    hyper: 0,
    date: "2015-01-05",
    unit: "pmol/L",
    day: "Monday",
    result: "10.914",
  },
  {
    date: "2015-01-06",
    hyper: 3,
    all: 197,
    unit: "pmol/L",
    day: "Tuesday",
    result: "10.272",
    hypo: 6,
  },
  {
    day: "Wednesday",
    result: "10.914",
    unit: "pmol/L",
    hyper: 0,
    date: "2015-01-07",
    all: 130,
    hypo: 2,
  },
  {
    hypo: 6,
    all: 185,
    unit: "pmol/L",
    hyper: 6,
    result: "10.7855999999999",
    day: "Thursday",
    date: "2015-01-08",
  },
  {
    unit: "pmol/L",
    day: "Friday",
    all: 125,
    hyper: 6,
    date: "2015-01-09",
    result: "10.7855999999999",
    hypo: 6,
  },
  {
    date: "2015-01-12",
    hyper: 2,
    unit: "pmol/L",
    all: 28,
    day: "Monday",
    hypo: 16,
    result: "10.4004",
  },
  {
    hyper: 7,
    date: "2015-01-13",
    all: 66,
    hypo: 0,
    day: "Tuesday",
    result: "10.272",
    unit: "pmol/L",
  },
  {
    all: 155,
    date: "2015-01-14",
    day: "Wednesday",
    hypo: 2,
    unit: "pmol/L",
    result: "10.0152",
    hyper: 2,
  },
  {
    day: "Thursday",
    date: "2015-01-15",
    result: "10.914",
    all: 40,
    hyper: 0,
    hypo: 3,
    unit: "pmol/L",
  },
  {
    all: 18,
    date: "2015-01-16",
    unit: "pmol/L",
    day: "Friday",
    hypo: 14,
    result: "11.0423999999999",
    hyper: 11,
  },
  {
    hypo: 0,
    unit: "pmol/L",
    all: 90,
    hyper: 3,
    date: "2015-01-19",
    day: "Monday",
    result: "10.272",
  },
  {
    day: "Tuesday",
    unit: "pmol/L",
    result: "10.6572",
    all: 75,
    date: "2015-01-20",
    hypo: 7,
    hyper: 3,
  },
  {
    day: "Wednesday",
    all: 18,
    result: "10.914",
    hyper: 6,
    unit: "pmol/L",
    hypo: 0,
    date: "2015-01-21",
  },
  {
    hypo: 3,
    date: "2015-01-22",
    hyper: 0,
    result: "10.914",
    day: "Thursday",
    all: 143,
    unit: "pmol/L",
  },
  {
    day: "Friday",
    all: 71,
    date: "2015-01-23",
    hypo: 2,
    unit: "pmol/L",
    hyper: 16,
    result: "10.6572",
  },
  {
    all: 45,
    unit: "pmol/L",
    date: "2015-01-26",
    hypo: 2,
    day: "Monday",
    result: "11.1708",
    hyper: 7,
  },
  {
    day: "Tuesday",
    result: "11.1708",
    date: "2015-01-27",
    unit: "pmol/L",
    hyper: 5,
    hypo: 8,
    all: 63,
  },
  {
    hyper: 11,
    day: "Wednesday",
    result: "11.0423999999999",
    hypo: 3,
    all: 184,
    unit: "pmol/L",
    date: "2015-01-28",
  },
  {
    date: "2015-01-29",
    day: "Thursday",
    all: 152,
    hypo: 11,
    unit: "pmol/L",
    hyper: 0,
    result: "10.6572",
  },
  {
    hypo: 7,
    hyper: 7,
    result: "11.0423999999999",
    all: 164,
    unit: "pmol/L",
    date: "2015-01-30",
    day: "Friday",
  },
  {
    unit: "pmol/L",
    result: "10.4004",
    date: "2015-02-02",
    hyper: 0,
    all: 19,
    day: "Monday",
    hypo: 2,
  },
  {
    hypo: 0,
    day: "Tuesday",
    hyper: 0,
    all: 192,
    date: "2015-02-03",
    result: "11.2992",
    unit: "pmol/L",
  },
  {
    hypo: 0,
    date: "2015-02-04",
    unit: "pmol/L",
    all: 104,
    day: "Wednesday",
    hyper: 2,
    result: "10.914",
  },
  {
    day: "Thursday",
    unit: "pmol/L",
    hyper: 2,
    hypo: 2,
    all: 17,
    result: "10.7855999999999",
    date: "2015-02-05",
  },
  {
    date: "2015-02-06",
    day: "Friday",
    unit: "pmol/L",
    hypo: 5,
    hyper: 0,
    all: 159,
    result: "10.5287999999999",
  },
  {
    hypo: 6,
    day: "Monday",
    hyper: 0,
    date: "2015-02-09",
    unit: "pmol/L",
    result: "11.0423999999999",
    all: 124,
  },
  {
    result: "11.4276",
    hypo: 7,
    unit: "pmol/L",
    date: "2015-02-10",
    day: "Tuesday",
    all: 147,
    hyper: 3,
  },
  {
    all: 163,
    hypo: 5,
    hyper: 5,
    date: "2015-02-11",
    result: "11.1708",
    unit: "pmol/L",
    day: "Wednesday",
  },
  {
    date: "2015-02-12",
    unit: "pmol/L",
    day: "Thursday",
    all: 82,
    hypo: 5,
    hyper: 3,
    result: "11.1708",
  },
  {
    day: "Friday",
    unit: "pmol/L",
    all: 172,
    hyper: 5,
    result: "11.6844",
    hypo: 5,
    date: "2015-02-13",
  },
  {
    result: "11.4276",
    hyper: 10,
    day: "Monday",
    hypo: 6,
    date: "2015-02-16",
    all: 180,
    unit: "pmol/L",
  },
  {
    all: 3,
    hypo: 2,
    day: "Tuesday",
    result: "11.556",
    date: "2015-02-17",
    hyper: 7,
    unit: "pmol/L",
  },
  {
    result: "11.556",
    hyper: 10,
    hypo: 25,
    all: 193,
    unit: "pmol/L",
    date: "2015-02-18",
    day: "Wednesday",
  },
  {
    hyper: 19,
    day: "Thursday",
    all: 50,
    date: "2015-02-19",
    hypo: 7,
    result: "11.2992",
    unit: "pmol/L",
  },
  {
    result: "11.6844",
    day: "Friday",
    date: "2015-02-20",
    all: 136,
    hypo: 9,
    unit: "pmol/L",
    hyper: 0,
  },
  {
    unit: "pmol/L",
    day: "Monday",
    hypo: 3,
    date: "2015-02-23",
    hyper: 0,
    all: 61,
    result: "12.1979999999999",
  },
  {
    hyper: 13,
    all: 166,
    result: "11.556",
    date: "2015-02-24",
    hypo: 13,
    day: "Tuesday",
    unit: "pmol/L",
  },
  {
    hyper: 2,
    unit: "pmol/L",
    result: "10.914",
    all: 161,
    hypo: 5,
    date: "2015-02-25",
    day: "Wednesday",
  },
  {
    date: "2015-02-26",
    day: "Thursday",
    unit: "pmol/L",
    hyper: 2,
    result: "11.2992",
    hypo: 4,
    all: 115,
  },
  {
    hypo: 8,
    date: "2015-02-27",
    day: "Friday",
    result: "11.1708",
    hyper: 0,
    unit: "pmol/L",
    all: 40,
  },
  {
    hyper: 0,
    hypo: 0,
    unit: "pmol/L",
    date: "2015-03-02",
    day: "Monday",
    result: "11.9412",
    all: 56,
  },
  {
    hypo: 4,
    all: 1,
    unit: "pmol/L",
    result: "12.0696",
    hyper: 8,
    day: "Tuesday",
    date: "2015-03-03",
  },
  {
    unit: "pmol/L",
    day: "Wednesday",
    hypo: 3,
    date: "2015-03-04",
    result: "11.8128",
    hyper: 3,
    all: 30,
  },
  {
    day: "Thursday",
    all: 2,
    unit: "pmol/L",
    date: "2015-03-05",
    hyper: 9,
    result: "11.8128",
    hypo: 9,
  },
  {
    hyper: 16,
    hypo: 4,
    day: "Friday",
    unit: "pmol/L",
    all: 8,
    result: "11.556",
    date: "2015-03-06",
  },
  {
    unit: "pmol/L",
    hypo: 9,
    date: "2015-03-09",
    result: "11.1708",
    hyper: 0,
    all: 163,
    day: "Monday",
  },
  {
    day: "Tuesday",
    hypo: 0,
    date: "2015-03-10",
    result: "11.1708",
    all: 151,
    unit: "pmol/L",
    hyper: 0,
  },
  {
    date: "2015-03-11",
    day: "Wednesday",
    unit: "pmol/L",
    result: "11.6844",
    hyper: 6,
    all: 148,
    hypo: 0,
  },
  {
    day: "Thursday",
    unit: "pmol/L",
    all: 133,
    hyper: 6,
    result: "12.5832",
    date: "2015-03-12",
    hypo: 0,
  },
  {
    date: "2015-03-13",
    all: 66,
    hypo: 15,
    unit: "pmol/L",
    hyper: 3,
    day: "Friday",
    result: "12.0696",
  },
  {
    result: "12.0696",
    all: 20,
    date: "2015-03-16",
    hyper: 5,
    hypo: 11,
    day: "Monday",
    unit: "pmol/L",
  },
  {
    day: "Tuesday",
    result: "12.1979999999999",
    unit: "pmol/L",
    hypo: 0,
    hyper: 0,
    all: 165,
    date: "2015-03-17",
  },
  {
    result: "11.8128",
    date: "2015-03-18",
    unit: "pmol/L",
    all: 86,
    hyper: 0,
    hypo: 0,
    day: "Wednesday",
  },
  {
    day: "Thursday",
    hypo: 9,
    unit: "pmol/L",
    hyper: 0,
    all: 24,
    result: "11.6844",
    date: "2015-03-19",
  },
  {
    all: 159,
    hypo: 6,
    result: "11.6844",
    unit: "pmol/L",
    hyper: 0,
    date: "2015-03-20",
    day: "Friday",
  },
  {
    hypo: 8,
    date: "2015-03-23",
    day: "Monday",
    hyper: 11,
    unit: "pmol/L",
    result: "11.2992",
    all: 47,
  },
  {
    hyper: 2,
    result: "11.2992",
    day: "Tuesday",
    unit: "pmol/L",
    date: "2015-03-24",
    all: 2,
    hypo: 2,
  },
  {
    result: "12.0696",
    date: "2015-03-25",
    all: 19,
    unit: "pmol/L",
    hypo: 2,
    hyper: 5,
    day: "Wednesday",
  },
  {
    date: "2015-03-26",
    all: 34,
    day: "Thursday",
    unit: "pmol/L",
    hyper: 6,
    hypo: 3,
    result: "11.4276",
  },
  {
    hypo: 6,
    date: "2015-03-27",
    result: "11.8128",
    hyper: 0,
    unit: "pmol/L",
    all: 170,
    day: "Friday",
  },
  {
    hyper: 0,
    unit: "pmol/L",
    all: 136,
    result: "11.1708",
    hypo: 14,
    date: "2015-03-30",
    day: "Monday",
  },
  {
    hyper: 0,
    unit: "pmol/L",
    result: "11.2992",
    day: "Tuesday",
    hypo: 0,
    all: 159,
    date: "2015-03-31",
  },
  {
    date: "2015-04-01",
    day: "Wednesday",
    hypo: 5,
    unit: "pmol/L",
    result: "11.9412",
    all: 130,
    hyper: 5,
  },
  {
    day: "Thursday",
    date: "2015-04-02",
    result: "11.6844",
    hyper: 6,
    hypo: 3,
    all: 120,
    unit: "pmol/L",
  },
  {
    unit: "pmol/L",
    date: "2015-04-03",
    day: "Friday",
    hyper: 5,
    hypo: 5,
    all: 126,
    result: "12.1979999999999",
  },
  {
    unit: "pmol/L",
    hypo: 4,
    hyper: 4,
    date: "2015-04-06",
    result: "12.1979999999999",
    day: "Monday",
    all: 72,
  },
  {
    result: "11.6844",
    unit: "pmol/L",
    all: 90,
    day: "Tuesday",
    hyper: 0,
    date: "2015-04-07",
    hypo: 9,
  },
  {
    all: 52,
    date: "2015-04-08",
    unit: "pmol/L",
    hypo: 0,
    day: "Wednesday",
    result: "11.9412",
    hyper: 20,
  },
  {
    all: 123,
    date: "2015-04-09",
    hyper: 4,
    result: "11.6844",
    hypo: 0,
    day: "Thursday",
    unit: "pmol/L",
  },
  {
    day: "Friday",
    date: "2015-04-10",
    result: "11.6844",
    hypo: 5,
    all: 124,
    hyper: 1,
    unit: "pmol/L",
  },
  {
    day: "Monday",
    result: "11.9412",
    hyper: 8,
    all: 78,
    date: "2015-04-13",
    unit: "pmol/L",
    hypo: 2,
  },
  {
    unit: "pmol/L",
    hypo: 6,
    all: 191,
    hyper: 3,
    day: "Tuesday",
    result: "12.0696",
    date: "2015-04-14",
  },
  {
    unit: "pmol/L",
    all: 99,
    hypo: 0,
    date: "2015-04-15",
    result: "11.8128",
    day: "Wednesday",
    hyper: 4,
  },
  {
    result: "11.8128",
    day: "Thursday",
    hyper: 14,
    hypo: 2,
    date: "2015-04-16",
    all: 61,
    unit: "pmol/L",
  },
  {
    day: "Friday",
    hyper: 0,
    date: "2015-04-17",
    hypo: 14,
    all: 11,
    result: "11.4276",
    unit: "pmol/L",
  },
  {
    day: "Monday",
    result: "12.0696",
    unit: "pmol/L",
    hyper: 0,
    all: 35,
    hypo: 0,
    date: "2015-04-20",
  },
  {
    date: "2015-04-21",
    result: "11.6844",
    hyper: 0,
    day: "Tuesday",
    all: 46,
    unit: "pmol/L",
    hypo: 4,
  },
  {
    unit: "pmol/L",
    hyper: 6,
    hypo: 4,
    all: 182,
    day: "Wednesday",
    result: "12.1979999999999",
    date: "2015-04-22",
  },
  {
    day: "Thursday",
    hyper: 2,
    unit: "pmol/L",
    result: "11.556",
    hypo: 7,
    date: "2015-04-23",
    all: 144,
  },
  {
    hypo: 0,
    unit: "pmol/L",
    hyper: 0,
    day: "Friday",
    result: "12.1979999999999",
    date: "2015-04-24",
    all: 125,
  },
  {
    result: "12.7115999999999",
    hypo: 3,
    day: "Monday",
    unit: "pmol/L",
    date: "2015-04-27",
    hyper: 3,
    all: 98,
  },
  {
    hypo: 4,
    date: "2015-04-28",
    day: "Tuesday",
    hyper: 2,
    result: "12.0696",
    unit: "pmol/L",
    all: 69,
  },
  {
    hyper: 0,
    unit: "pmol/L",
    result: "11.6844",
    date: "2015-04-29",
    hypo: 3,
    all: 85,
    day: "Wednesday",
  },
  {
    hypo: 4,
    day: "Thursday",
    date: "2015-04-30",
    result: "12.5832",
    all: 111,
    unit: "pmol/L",
    hyper: 4,
  },
  {
    hypo: 9,
    hyper: 6,
    day: "Friday",
    result: "12.3264",
    date: "2015-05-01",
    all: 18,
    unit: "pmol/L",
  },
  {
    date: "2015-05-04",
    hyper: 2,
    all: 74,
    day: "Monday",
    result: "12.0696",
    hypo: 8,
    unit: "pmol/L",
  },
  {
    all: 50,
    date: "2015-05-05",
    result: "13.9956",
    hyper: 2,
    hypo: 10,
    day: "Tuesday",
    unit: "pmol/L",
  },
  {
    all: 146,
    hyper: 7,
    day: "Wednesday",
    date: "2015-05-06",
    unit: "pmol/L",
    hypo: 0,
    result: "12.0696",
  },
  {
    all: 43,
    date: "2015-05-07",
    hyper: 3,
    unit: "pmol/L",
    hypo: 0,
    result: "12.7115999999999",
    day: "Thursday",
  },
  {
    day: "Friday",
    hyper: 0,
    result: "11.556",
    unit: "pmol/L",
    hypo: 13,
    date: "2015-05-08",
    all: 138,
  },
  {
    hyper: 6,
    result: "12.83485211759",
    day: "Monday",
    unit: "pmol/L",
    date: "2015-05-11",
    hypo: 13,
    all: 177,
  },
  {
    hyper: 4,
    hypo: 4,
    day: "Tuesday",
    unit: "pmol/L",
    date: "2015-05-12",
    result: "11.2100770322019",
    all: 101,
  },
  {
    hyper: 6,
    all: 50,
    hypo: 9,
    date: "2015-05-13",
    result: "12.3917834186301",
    day: "Wednesday",
    unit: "pmol/L",
  },
  {
    hyper: 9,
    result: "12.1832610584016",
    unit: "pmol/L",
    all: 189,
    hypo: 6,
    day: "Thursday",
    date: "2015-05-14",
  },
  {
    date: "2015-05-15",
    all: 139,
    hyper: 5,
    result: "11.3534799732503",
    day: "Friday",
    hypo: 0,
    unit: "pmol/L",
  },
  {
    day: "Monday",
    hyper: 0,
    result: "12.0885492853591",
    unit: "pmol/L",
    date: "2015-05-18",
    all: 122,
    hypo: 6,
  },
  {
    hypo: 11,
    date: "2015-05-19",
    hyper: 5,
    day: "Tuesday",
    all: 14,
    unit: "pmol/L",
    result: "11.9962473093943",
  },
  {
    unit: "pmol/L",
    date: "2015-05-20",
    all: 172,
    day: "Wednesday",
    result: "11.4713816112891",
    hyper: 0,
    hypo: 15,
  },
  {
    day: "Thursday",
    unit: "pmol/L",
    hypo: 8,
    hyper: 4,
    all: 112,
    date: "2015-05-21",
    result: "11.1004021005607",
  },
  {
    hyper: 8,
    day: "Friday",
    date: "2015-05-22",
    hypo: 5,
    unit: "pmol/L",
    all: 61,
    result: "12.2748956795516",
  },
  {
    hypo: 6,
    unit: "pmol/L",
    day: "Monday",
    hyper: 9,
    all: 120,
    date: "2015-05-25",
    result: "11.7753219855166",
  },
  {
    hypo: 5,
    unit: "pmol/L",
    date: "2015-05-26",
    hyper: 2,
    all: 79,
    result: "11.0515039210355",
    day: "Tuesday",
  },
  {
    hyper: 6,
    day: "Wednesday",
    all: 101,
    unit: "pmol/L",
    date: "2015-05-27",
    result: "11.5670460948042",
    hypo: 12,
  },
  {
    day: "Thursday",
    result: "12.0619054160085",
    hypo: 10,
    unit: "pmol/L",
    all: 182,
    hyper: 0,
    date: "2015-05-28",
  },
  {
    day: "Friday",
    hyper: 3,
    all: 151,
    unit: "pmol/L",
    date: "2015-05-29",
    hypo: 7,
    result: "12.1904206833216",
  },
  {
    all: 27,
    hyper: 16,
    result: "12.3231036300609",
    hypo: 3,
    unit: "pmol/L",
    day: "Monday",
    date: "2015-06-01",
  },
  {
    date: "2015-06-02",
    result: "12.4117937696843",
    hyper: 4,
    day: "Tuesday",
    all: 144,
    hypo: 4,
    unit: "pmol/L",
  },
  {
    all: 108,
    unit: "pmol/L",
    date: "2015-06-03",
    hypo: 10,
    hyper: 0,
    result: "11.9246390512393",
    day: "Wednesday",
  },
  {
    hyper: 4,
    day: "Thursday",
    hypo: 12,
    unit: "pmol/L",
    all: 116,
    result: "11.9004673111641",
    date: "2015-06-04",
  },
  {
    day: "Friday",
    hypo: 7,
    hyper: 2,
    all: 192,
    unit: "pmol/L",
    date: "2015-06-05",
    result: "12.4616011987001",
  },
  {
    hyper: 6,
    day: "Monday",
    hypo: 6,
    all: 198,
    date: "2015-06-08",
    result: "12.5815729519314",
    unit: "pmol/L",
  },
  {
    hypo: 3,
    day: "Tuesday",
    result: "11.1820584233698",
    date: "2015-06-09",
    all: 16,
    unit: "pmol/L",
    hyper: 0,
  },
  {
    date: "2015-06-10",
    hypo: 8,
    day: "Wednesday",
    unit: "pmol/L",
    result: "12.1633822340033",
    all: 82,
    hyper: 1,
  },
  {
    hyper: 9,
    all: 35,
    unit: "pmol/L",
    date: "2015-06-11",
    result: "12.340312463345",
    day: "Thursday",
    hypo: 9,
  },
  {
    hypo: 0,
    unit: "pmol/L",
    hyper: 0,
    day: "Friday",
    result: "11.5721127303306",
    all: 32,
    date: "2015-06-12",
  },
  {
    hypo: 0,
    result: "11.8403184558662",
    day: "Monday",
    hyper: 0,
    all: 70,
    date: "2015-06-15",
    unit: "pmol/L",
  },
  {
    result: "11.2073435651797",
    date: "2015-06-16",
    hypo: 10,
    all: 62,
    unit: "pmol/L",
    hyper: 3,
    day: "Tuesday",
  },
  {
    all: 169,
    hypo: 5,
    date: "2015-06-17",
    result: "11.2226383996932",
    day: "Wednesday",
    unit: "pmol/L",
    hyper: 2,
  },
  {
    date: "2015-06-18",
    hyper: 4,
    unit: "pmol/L",
    hypo: 4,
    all: 171,
    day: "Thursday",
    result: "11.5093676532166",
  },
  {
    hypo: 0,
    result: "11.35369842187",
    unit: "pmol/L",
    day: "Friday",
    date: "2015-06-19",
    all: 104,
    hyper: 9,
  },
  {
    day: "Monday",
    result: "11.651562836451",
    all: 99,
    unit: "pmol/L",
    hyper: 11,
    hypo: 8,
    date: "2015-06-22",
  },
  {
    hyper: 4,
    day: "Tuesday",
    result: "12.3888166347575",
    all: 188,
    date: "2015-06-23",
    hypo: 7,
    unit: "pmol/L",
  },
  {
    result: "12.200053009372",
    all: 61,
    day: "Wednesday",
    unit: "pmol/L",
    hypo: 0,
    date: "2015-06-24",
    hyper: 4,
  },
  {
    result: "12.200053009372",
    hypo: 5,
    day: "Thursday",
    all: 79,
    hyper: 8,
    date: "2015-06-25",
    unit: "pmol/L",
  },
  {
    result: "10.9093018755408",
    unit: "pmol/L",
    hyper: 12,
    day: "Friday",
    date: "2015-06-26",
    all: 47,
    hypo: 0,
  },
  {
    all: 19,
    result: "11.5963193535623",
    date: "2015-06-29",
    unit: "pmol/L",
    hypo: 0,
    hyper: 0,
    day: "Monday",
  },
  {
    hypo: 11,
    unit: "pmol/L",
    hyper: 8,
    date: "2015-06-30",
    result: "11.1769094407197",
    all: 164,
    day: "Tuesday",
  },
  {
    unit: "pmol/L",
    result: "11.5959865339374",
    hyper: 0,
    all: 145,
    hypo: 4,
    day: "Wednesday",
    date: "2015-07-01",
  },
  {
    all: 27,
    date: "2015-07-02",
    result: "12.0787848607978",
    hyper: 2,
    unit: "pmol/L",
    hypo: 10,
    day: "Thursday",
  },
  {
    all: 132,
    unit: "pmol/L",
    day: "Friday",
    result: "12.4503150679158",
    hyper: 4,
    hypo: 0,
    date: "2015-07-03",
  },
  {
    day: "Monday",
    date: "2015-07-06",
    hypo: 3,
    result: "11.3026374866382",
    unit: "pmol/L",
    hyper: 0,
    all: 128,
  },
  {
    unit: "pmol/L",
    date: "2015-07-07",
    result: "12.4597586818078",
    hyper: 7,
    all: 50,
    day: "Tuesday",
    hypo: 7,
  },
  {
    hypo: 3,
    date: "2015-07-08",
    all: 125,
    unit: "pmol/L",
    day: "Wednesday",
    result: "12.6657545865334",
    hyper: 1,
  },
  {
    hyper: 7,
    date: "2015-07-09",
    day: "Thursday",
    all: 138,
    unit: "pmol/L",
    result: "12.4047942641715",
    hypo: 0,
  },
  {
    unit: "pmol/L",
    date: "2015-07-10",
    hypo: 12,
    hyper: 3,
    day: "Friday",
    result: "11.0835849879692",
    all: 92,
  },
  {
    result: "12.4304888541798",
    all: 48,
    hyper: 5,
    date: "2015-07-13",
    hypo: 0,
    unit: "pmol/L",
    day: "Monday",
  },
  {
    unit: "pmol/L",
    all: 135,
    day: "Tuesday",
    result: "11.3041140163142",
    hypo: 14,
    date: "2015-07-14",
    hyper: 0,
  },
  {
    unit: "pmol/L",
    hypo: 2,
    all: 134,
    hyper: 4,
    day: "Wednesday",
    date: "2015-07-15",
    result: "12.0944954339149",
  },
  {
    day: "Thursday",
    date: "2015-07-16",
    result: "11.7246310404863",
    unit: "pmol/L",
    all: 11,
    hypo: 0,
    hyper: 7,
  },
  {
    hyper: 8,
    day: "Friday",
    result: "11.2576462206477",
    date: "2015-07-17",
    all: 88,
    hypo: 2,
    unit: "pmol/L",
  },
  {
    unit: "pmol/L",
    hyper: 3,
    result: "12.0276490125576",
    day: "Monday",
    all: 9,
    hypo: 3,
    date: "2015-07-20",
  },
  {
    hypo: 4,
    result: "12.5405366352976",
    date: "2015-07-21",
    day: "Tuesday",
    unit: "pmol/L",
    all: 44,
    hyper: 4,
  },
  {
    hyper: 4,
    unit: "pmol/L",
    date: "2015-07-22",
    hypo: 8,
    result: "11.7240900656322",
    day: "Wednesday",
    all: 30,
  },
  {
    hypo: 0,
    result: "11.7790922257001",
    date: "2015-07-23",
    day: "Thursday",
    all: 170,
    unit: "pmol/L",
    hyper: 13,
  },
  {
    result: "12.5511628453825",
    hyper: 0,
    date: "2015-07-24",
    all: 34,
    hypo: 0,
    unit: "pmol/L",
    day: "Friday",
  },
  {
    unit: "pmol/L",
    result: "11.7894794003843",
    day: "Monday",
    hypo: 7,
    hyper: 7,
    date: "2015-07-27",
    all: 148,
  },
  {
    result: "12.2738137298433",
    unit: "pmol/L",
    hyper: 3,
    day: "Tuesday",
    all: 191,
    date: "2015-07-28",
    hypo: 0,
  },
  {
    hypo: 5,
    result: "11.5002488529791",
    hyper: 0,
    day: "Wednesday",
    all: 192,
    unit: "pmol/L",
    date: "2015-07-29",
  },
  {
    result: "12.4198912368462",
    all: 57,
    date: "2015-07-30",
    unit: "pmol/L",
    day: "Thursday",
    hyper: 12,
    hypo: 6,
  },
  {
    day: "Friday",
    unit: "pmol/L",
    all: 187,
    hyper: 2,
    hypo: 7,
    date: "2015-07-31",
    result: "12.4811597842842",
  },
  {
    hypo: 4,
    all: 190,
    hyper: 4,
    unit: "pmol/L",
    result: "11.5691659613839",
    day: "Monday",
    date: "2015-08-03",
  },
  {
    unit: "pmol/L",
    result: "12.2402841541299",
    date: "2015-08-04",
    hypo: 0,
    all: 194,
    hyper: 0,
    day: "Tuesday",
  },
  {
    day: "Wednesday",
    all: 160,
    hypo: 11,
    result: "11.6560873534132",
    hyper: 0,
    unit: "pmol/L",
    date: "2015-08-05",
  },
  {
    hypo: 4,
    day: "Thursday",
    result: "11.5151062183981",
    hyper: 0,
    unit: "pmol/L",
    all: 154,
    date: "2015-08-06",
  },
  {
    hyper: 4,
    result: "12.894196944722",
    unit: "pmol/L",
    all: 86,
    day: "Friday",
    date: "2015-08-07",
    hypo: 4,
  },
  {
    all: 113,
    unit: "pmol/L",
    day: "Monday",
    date: "2015-08-10",
    hypo: 0,
    hyper: 10,
    result: "11.2990908417697",
  },
  {
    result: "10.8045746335807",
    unit: "pmol/L",
    day: "Tuesday",
    all: 150,
    hyper: 6,
    hypo: 13,
    date: "2015-08-11",
  },
  {
    result: "12.6107558575956",
    day: "Wednesday",
    hyper: 4,
    hypo: 0,
    unit: "pmol/L",
    all: 72,
    date: "2015-08-12",
  },
  {
    result: "11.664853890955",
    all: 96,
    hypo: 8,
    unit: "pmol/L",
    hyper: 4,
    day: "Thursday",
    date: "2015-08-13",
  },
  {
    hyper: 3,
    hypo: 3,
    unit: "pmol/L",
    result: "11.9371472362133",
    all: 177,
    date: "2015-08-14",
    day: "Friday",
  },
  {
    unit: "pmol/L",
    day: "Monday",
    all: 88,
    hyper: 2,
    date: "2015-08-17",
    result: "12.199157484402",
    hypo: 10,
  },
  {
    all: 27,
    result: "11.3801581539025",
    date: "2015-08-18",
    hyper: 6,
    hypo: 9,
    day: "Tuesday",
    unit: "pmol/L",
  },
  {
    result: "11.3419593819055",
    hyper: 11,
    day: "Wednesday",
    unit: "pmol/L",
    hypo: 2,
    all: 153,
    date: "2015-08-19",
  },
  {
    hyper: 2,
    unit: "pmol/L",
    result: "11.5605006421817",
    day: "Thursday",
    date: "2015-08-20",
    hypo: 7,
    all: 84,
  },
  {
    hypo: 2,
    unit: "pmol/L",
    date: "2015-08-21",
    day: "Friday",
    result: "11.9450022368443",
    all: 15,
    hyper: 2,
  },
  {
    hyper: 6,
    result: "11.3109316719289",
    hypo: 0,
    unit: "pmol/L",
    day: "Monday",
    date: "2015-08-24",
    all: 173,
  },
  {
    day: "Tuesday",
    result: "12.5911446613492",
    all: 157,
    hyper: 5,
    hypo: 5,
    unit: "pmol/L",
    date: "2015-08-25",
  },
  {
    hypo: 0,
    result: "12.0101262308646",
    all: 93,
    date: "2015-08-26",
    unit: "pmol/L",
    hyper: 8,
    day: "Wednesday",
  },
  {
    date: "2015-08-27",
    hypo: 2,
    result: "11.337899211224",
    hyper: 0,
    day: "Thursday",
    all: 153,
    unit: "pmol/L",
  },
  {
    hypo: 7,
    result: "11.6539606245732",
    day: "Friday",
    unit: "pmol/L",
    all: 150,
    hyper: 0,
    date: "2015-08-28",
  },
  {
    result: "12.1885598670684",
    day: "Monday",
    all: 172,
    hyper: 6,
    date: "2015-08-31",
    hypo: 6,
    unit: "pmol/L",
  },
  {
    day: "Tuesday",
    all: 117,
    result: "11.7550005453285",
    unit: "pmol/L",
    hyper: 0,
    hypo: 0,
    date: "2015-09-01",
  },
  {
    all: 87,
    result: "12.2151065210648",
    hyper: 17,
    hypo: 3,
    unit: "pmol/L",
    date: "2015-09-02",
    day: "Wednesday",
  },
  {
    date: "2015-09-03",
    unit: "pmol/L",
    day: "Thursday",
    hypo: 0,
    hyper: 0,
    result: "12.524545281363",
    all: 117,
  },
  {
    date: "2015-09-04",
    unit: "pmol/L",
    result: "11.8156063127918",
    hyper: 3,
    all: 0,
    day: "Friday",
    hypo: 6,
  },
  {
    day: "Monday",
    result: "12.1675785061808",
    date: "2015-09-07",
    all: 94,
    unit: "pmol/L",
    hyper: 9,
    hypo: 9,
  },
  {
    date: "2015-09-08",
    day: "Tuesday",
    hypo: 2,
    hyper: 5,
    all: 52,
    result: "11.5839764346907",
    unit: "pmol/L",
  },
  {
    hyper: 13,
    date: "2015-09-09",
    unit: "pmol/L",
    hypo: 7,
    result: "11.9300648117218",
    all: 68,
    day: "Wednesday",
  },
  {
    all: 171,
    result: "12.6229409444801",
    unit: "pmol/L",
    date: "2015-09-10",
    hyper: 2,
    day: "Thursday",
    hypo: 2,
  },
  {
    all: 58,
    date: "2015-09-11",
    unit: "pmol/L",
    day: "Friday",
    hyper: 0,
    hypo: 7,
    result: "12.0432789541161",
  },
  {
    all: 132,
    day: "Monday",
    unit: "pmol/L",
    date: "2015-09-14",
    hyper: 3,
    result: "12.2157452831283",
    hypo: 6,
  },
  {
    all: 45,
    result: "12.2143774059072",
    hypo: 0,
    hyper: 0,
    unit: "pmol/L",
    day: "Tuesday",
    date: "2015-09-15",
  },
  {
    date: "2015-09-16",
    all: 93,
    hyper: 5,
    result: "11.574315515889",
    day: "Wednesday",
    unit: "pmol/L",
    hypo: 2,
  },
  {
    all: 105,
    day: "Thursday",
    hypo: 7,
    unit: "pmol/L",
    result: "11.5432975274479",
    hyper: 2,
    date: "2015-09-17",
  },
  {
    hyper: 8,
    day: "Friday",
    date: "2015-09-18",
    all: 17,
    hypo: 6,
    result: "12.2433927580488",
    unit: "pmol/L",
  },
  {
    day: "Monday",
    date: "2015-09-21",
    unit: "pmol/L",
    result: "12.6427797390267",
    hyper: 5,
    hypo: 2,
    all: 48,
  },
  {
    result: "12.0883462768251",
    hyper: 8,
    unit: "pmol/L",
    date: "2015-09-22",
    day: "Tuesday",
    hypo: 5,
    all: 133,
  },
  {
    unit: "pmol/L",
    date: "2015-09-23",
    all: 188,
    hypo: 3,
    day: "Wednesday",
    hyper: 0,
    result: "11.9960917648274",
  },
  {
    all: 125,
    date: "2015-09-24",
    day: "Thursday",
    hyper: 2,
    unit: "pmol/L",
    result: "12.3986708405579",
    hypo: 0,
  },
  {
    date: "2015-09-25",
    hypo: 2,
    hyper: 2,
    day: "Friday",
    all: 147,
    result: "12.2935959107423",
    unit: "pmol/L",
  },
  {
    date: "2015-09-28",
    unit: "pmol/L",
    day: "Monday",
    hyper: 0,
    result: "12.3052194359917",
    all: 22,
    hypo: 0,
  },
  {
    unit: "pmol/L",
    hypo: 7,
    all: 116,
    date: "2015-09-29",
    hyper: 7,
    day: "Tuesday",
    result: "12.1914866210892",
  },
  {
    day: "Wednesday",
    all: 56,
    result: "11.8427345433492",
    unit: "pmol/L",
    hypo: 6,
    date: "2015-09-30",
    hyper: 9,
  },
  {
    unit: "pmol/L",
    day: "Thursday",
    hyper: 3,
    all: 168,
    result: "11.7741971466813",
    date: "2015-10-01",
    hypo: 9,
  },
  {
    hypo: 0,
    all: 21,
    day: "Friday",
    result: "11.957683693891",
    date: "2015-10-02",
    unit: "pmol/L",
    hyper: 5,
  },
  {
    hypo: 8,
    date: "2015-10-05",
    hyper: 8,
    day: "Monday",
    unit: "pmol/L",
    all: 53,
    result: "11.0056937586484",
  },
  {
    unit: "pmol/L",
    hypo: 8,
    hyper: 4,
    day: "Tuesday",
    result: "12.6005814129809",
    date: "2015-10-06",
    all: 21,
  },
  {
    day: "Wednesday",
    all: 47,
    date: "2015-10-07",
    hypo: 6,
    result: "11.6700589153976",
    hyper: 9,
    unit: "pmol/L",
  },
  {
    hyper: 6,
    hypo: 12,
    result: "11.9170968553073",
    day: "Thursday",
    unit: "pmol/L",
    all: 153,
    date: "2015-10-08",
  },
  {
    all: 38,
    hypo: 0,
    result: "12.5962112968756",
    unit: "pmol/L",
    hyper: 11,
    day: "Friday",
    date: "2015-10-09",
  },
  {
    all: 134,
    hyper: 8,
    date: "2015-10-12",
    result: "12.103583353981",
    hypo: 0,
    unit: "pmol/L",
    day: "Monday",
  },
  {
    date: "2015-10-13",
    day: "Tuesday",
    hyper: 0,
    all: 26,
    result: "11.5397114245826",
    hypo: 0,
    unit: "pmol/L",
  },
  {
    date: "2015-10-14",
    unit: "pmol/L",
    result: "11.8064589198031",
    all: 199,
    hyper: 10,
    day: "Wednesday",
    hypo: 3,
  },
  {
    hyper: 17,
    unit: "pmol/L",
    day: "Thursday",
    hypo: 2,
    all: 52,
    date: "2015-10-15",
    result: "11.6253981816118",
  },
  {
    result: "11.5311804913114",
    hyper: 5,
    all: 139,
    day: "Friday",
    hypo: 2,
    unit: "pmol/L",
    date: "2015-10-16",
  },
  {
    day: "Monday",
    result: "11.8968463251423",
    hypo: 7,
    unit: "pmol/L",
    hyper: 0,
    all: 83,
    date: "2015-10-19",
  },
  {
    date: "2015-10-20",
    day: "Tuesday",
    hyper: 5,
    result: "11.8269136022121",
    unit: "pmol/L",
    hypo: 5,
    all: 163,
  },
  {
    hypo: 9,
    date: "2015-10-21",
    day: "Wednesday",
    hyper: 4,
    result: "12.3662054870471",
    unit: "pmol/L",
    all: 164,
  },
  {
    date: "2015-10-22",
    unit: "pmol/L",
    result: "11.155366518197",
    hypo: 8,
    day: "Thursday",
    all: 111,
    hyper: 13,
  },
  {
    result: "11.7215933465906",
    day: "Friday",
    hypo: 13,
    all: 76,
    unit: "pmol/L",
    date: "2015-10-23",
    hyper: 0,
  },
  {
    hypo: 3,
    all: 52,
    day: "Monday",
    unit: "pmol/L",
    hyper: 0,
    date: "2015-10-26",
    result: "12.2323330818546",
  },
  {
    result: "11.1813767721793",
    hypo: 13,
    day: "Tuesday",
    hyper: 2,
    date: "2015-10-27",
    unit: "pmol/L",
    all: 159,
  },
  {
    hypo: 15,
    unit: "pmol/L",
    all: 23,
    date: "2015-10-28",
    hyper: 0,
    result: "12.0179126088925",
    day: "Wednesday",
  },
  {
    all: 119,
    day: "Thursday",
    date: "2015-10-29",
    hypo: 0,
    hyper: 5,
    unit: "pmol/L",
    result: "11.8877875696825",
  },
  {
    unit: "pmol/L",
    day: "Friday",
    all: 86,
    hyper: 12,
    hypo: 12,
    date: "2015-10-30",
    result: "11.2870647305823",
  },
  {
    hypo: 2,
    day: "Monday",
    unit: "pmol/L",
    hyper: 8,
    all: 161,
    result: "12.3322647475703",
    date: "2015-11-02",
  },
  {
    hypo: 5,
    result: "13.016308579459",
    all: 43,
    hyper: 2,
    date: "2015-11-03",
    day: "Tuesday",
    unit: "pmol/L",
  },
  {
    hypo: 0,
    date: "2015-11-04",
    day: "Wednesday",
    unit: "pmol/L",
    result: "11.2317308945995",
    all: 25,
    hyper: 0,
  },
  {
    result: "11.0138644232535",
    date: "2015-11-05",
    all: 148,
    hypo: 0,
    hyper: 0,
    unit: "pmol/L",
    day: "Thursday",
  },
  {
    hyper: 18,
    unit: "pmol/L",
    hypo: 0,
    all: 190,
    day: "Friday",
    result: "11.2348749535301",
    date: "2015-11-06",
  },
  {
    result: "11.0242721847186",
    date: "2015-11-09",
    hyper: 4,
    unit: "pmol/L",
    day: "Monday",
    all: 152,
    hypo: 8,
  },
  {
    hypo: 7,
    day: "Tuesday",
    result: "11.770295951697",
    unit: "pmol/L",
    date: "2015-11-10",
    hyper: 11,
    all: 169,
  },
  {
    all: 86,
    day: "Wednesday",
    date: "2015-11-11",
    hyper: 10,
    result: "12.1177018827069",
    hypo: 10,
    unit: "pmol/L",
  },
  {
    unit: "pmol/L",
    day: "Thursday",
    hypo: 0,
    all: 128,
    result: "11.3702216009784",
    date: "2015-11-12",
    hyper: 3,
  },
  {
    date: "2015-11-13",
    day: "Friday",
    all: 38,
    unit: "pmol/L",
    result: "12.1707911877144",
    hyper: 10,
    hypo: 10,
  },
  {
    day: "Monday",
    unit: "pmol/L",
    all: 68,
    date: "2015-11-16",
    hyper: 10,
    result: "12.6464510482907",
    hypo: 3,
  },
  {
    result: "12.886588985462",
    hyper: 5,
    date: "2015-11-17",
    day: "Tuesday",
    unit: "pmol/L",
    hypo: 0,
    all: 134,
  },
  {
    all: 173,
    date: "2015-11-18",
    day: "Wednesday",
    hyper: 8,
    hypo: 0,
    result: "11.8674278152076",
    unit: "pmol/L",
  },
  {
    all: 157,
    date: "2015-11-19",
    hypo: 5,
    result: "11.8692857721857",
    hyper: 5,
    unit: "pmol/L",
    day: "Thursday",
  },
  {
    date: "2015-11-20",
    day: "Friday",
    all: 181,
    result: "12.0361216166163",
    unit: "pmol/L",
    hypo: 5,
    hyper: 15,
  },
  {
    result: "11.8367678080125",
    hyper: 3,
    unit: "pmol/L",
    day: "Monday",
    hypo: 3,
    date: "2015-11-23",
    all: 145,
  },
  {
    hyper: 9,
    day: "Tuesday",
    hypo: 3,
    date: "2015-11-24",
    all: 62,
    unit: "pmol/L",
    result: "12.1700958120033",
  },
  {
    day: "Wednesday",
    date: "2015-11-25",
    unit: "pmol/L",
    result: "11.9358376882048",
    all: 75,
    hyper: 5,
    hypo: 12,
  },
  {
    all: 85,
    day: "Thursday",
    result: "11.8161964671782",
    date: "2015-11-26",
    hypo: 5,
    hyper: 2,
    unit: "pmol/L",
  },
  {
    hypo: 6,
    result: "12.1200276170959",
    all: 165,
    date: "2015-11-27",
    hyper: 6,
    day: "Friday",
    unit: "pmol/L",
  },
  {
    hyper: 5,
    unit: "pmol/L",
    day: "Monday",
    all: 98,
    date: "2015-11-30",
    hypo: 0,
    result: "12.5193734245119",
  },
  {
    all: 68,
    hypo: 0,
    date: "2015-12-01",
    result: "13.006664816308",
    hyper: 9,
    day: "Tuesday",
    unit: "pmol/L",
  },
  {
    hyper: 6,
    hypo: 0,
    date: "2015-12-02",
    unit: "pmol/L",
    result: "12.9733416802589",
    all: 175,
    day: "Wednesday",
  },
  {
    hypo: 5,
    result: "11.9961695371109",
    all: 48,
    date: "2015-12-03",
    unit: "pmol/L",
    hyper: 8,
    day: "Thursday",
  },
  {
    hyper: 7,
    all: 62,
    day: "Friday",
    unit: "pmol/L",
    date: "2015-12-04",
    hypo: 0,
    result: "11.4769308924571",
  },
  {
    hyper: 5,
    date: "2015-12-07",
    hypo: 2,
    unit: "pmol/L",
    result: "12.049587658758",
    all: 73,
    day: "Monday",
  },
  {
    unit: "pmol/L",
    hypo: 6,
    result: "12.157870695267",
    hyper: 16,
    date: "2015-12-08",
    all: 155,
    day: "Tuesday",
  },
  {
    day: "Wednesday",
    unit: "pmol/L",
    hypo: 5,
    result: "11.6990130790515",
    date: "2015-12-09",
    hyper: 5,
    all: 190,
  },
  {
    all: 166,
    unit: "pmol/L",
    result: "12.2936965572268",
    date: "2015-12-10",
    hypo: 5,
    hyper: 7,
    day: "Thursday",
  },
  {
    hypo: 5,
    unit: "pmol/L",
    hyper: 2,
    day: "Friday",
    date: "2015-12-11",
    all: 157,
    result: "12.0821410779428",
  },
  {
    day: "Monday",
    date: "2015-12-14",
    all: 103,
    unit: "pmol/L",
    result: "12.3877518406999",
    hypo: 8,
    hyper: 0,
  },
  {
    hypo: 4,
    hyper: 8,
    all: 115,
    day: "Tuesday",
    result: "11.5558514608239",
    unit: "pmol/L",
    date: "2015-12-15",
  },
  {
    date: "2015-12-16",
    hyper: 3,
    unit: "pmol/L",
    all: 46,
    hypo: 6,
    day: "Wednesday",
    result: "11.4098728847404",
  },
  {
    all: 129,
    unit: "pmol/L",
    result: "12.5653757301876",
    hypo: 4,
    day: "Thursday",
    date: "2015-12-17",
    hyper: 4,
  },
  {
    hyper: 6,
    all: 66,
    result: "12.2458974830607",
    day: "Friday",
    date: "2015-12-18",
    unit: "pmol/L",
    hypo: 0,
  },
  {
    result: "12.0525698827163",
    day: "Monday",
    all: 91,
    unit: "pmol/L",
    date: "2015-12-21",
    hypo: 3,
    hyper: 14,
  },
  {
    hyper: 2,
    hypo: 4,
    day: "Tuesday",
    date: "2015-12-22",
    all: 154,
    unit: "pmol/L",
    result: "11.6399736225031",
  },
  {
    day: "Wednesday",
    result: "12.5219513469671",
    unit: "pmol/L",
    hyper: 5,
    all: 153,
    hypo: 8,
    date: "2015-12-23",
  },
  {
    result: "11.693138984229",
    date: "2015-12-24",
    hypo: 6,
    all: 99,
    day: "Thursday",
    hyper: 6,
    unit: "pmol/L",
  },
  {
    day: "Friday",
    date: "2015-12-25",
    hyper: 10,
    hypo: 0,
    all: 5,
    result: "11.605800709886",
    unit: "pmol/L",
  },
  {
    date: "2015-12-28",
    day: "Monday",
    all: 131,
    hyper: 5,
    unit: "pmol/L",
    result: "11.8450328286969",
    hypo: 8,
  },
  {
    result: "10.5342930743024",
    day: "Tuesday",
    hypo: 5,
    all: 16,
    date: "2015-12-29",
    unit: "pmol/L",
    hyper: 8,
  },
  {
    unit: "pmol/L",
    hyper: 0,
    day: "Wednesday",
    all: 183,
    result: "12.2126481163099",
    hypo: 0,
    date: "2015-12-30",
  },
  {
    hypo: 16,
    result: "11.7955656534213",
    hyper: 4,
    all: 90,
    unit: "pmol/L",
    day: "Thursday",
    date: "2015-12-31",
  },
];
