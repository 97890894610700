import React from "react";
import { connect } from "react-redux";
import { useFirestoreConnect } from "react-redux-firebase";
import { Redirect } from "react-router-dom";
import {
  addModel,
  deleteModel,
  updateModel,
} from "../../../store/actions/modelActions";
import Container from "@material-ui/core/Container";
import useStyles from "../dashboard/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../store/actions/actionTypes";
import FSPTable from "../../common/FSPTable/FSPTable";
import convertArrayByField from "../../common/utils";

const Models = ({
  models,
  manufacturers,
  authStatus,
  addModel,
  updateModel,
  deleteModel,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { isEmpty, isLoaded } = authStatus;
  let manufacturersArray = convertArrayByField(manufacturers, "id");
  console.log("manu", manufacturers);
  console.log("manu", manufacturersArray);
  const form = {
    titles: {
      index: "Instrument models",
      create: "Add model",
      update: "Update model",
    },
    keyField: 0,
    indexFields: [0, 1, 2, 4],
    fields: [
      {
        id: 0,
        name: "db_id",
        required: true,
        helper: "ID",
        q2: "ID",
        label: "ID",
        type: 2,
      },
      {
        id: 1,
        required: true,
        name: "name",
        helper: "Model name",
        q2: "Model name",
        label: "Model name",
        type: 2,
      },
      {
        id: 2,
        required: true,
        name: "manufacturer",
        helper: "Manufacturer",
        q2: "Manufacturer",
        label: "Manufacturer",
        type: 3,
        values: manufacturers,
        valuesArray: manufacturersArray,
        values_label: "name",
        values_key: "id",
        // model:'manufacturers',
        // modelId: 'db_id',
        // modelLabel:'name'
      },
      {
        id: 3,
        required: true,
        name: "separate",
        helper: "Separate instrument model group",
        q2: "Separate instrument model group",
        label: "Separate instrument model group",
        type: 3,
        values: [
          { id: "false", name: "No" },
          { id: "true", name: "Yes" },
        ],
        valuesArray: {
          true: [{ id: "true", name: "Yes" }],
          false: [{ id: "false", name: "No" }],
        },
        values_label: "name",
        values_key: "id",
        // model:'manufacturers',
        // modelId: 'db_id',
        // modelLabel:'name'
      },
      {
        id: 4,
        required: true,
        name: "active",
        helper: "Active",
        q2: "Active",
        label: "Active",
        type: 3,
        values: [
            { id: "false", name: "Inactive" },
            { id: "true", name: "Active" },
          ],
          valuesArray: {
            false: [{ id: "false", name: "Inactive" }],
            true: [{ id: "true", name: "Active" }]
          },
        values_label: "name",
        values_key: "id",
        // model:'manufacturers',
        // modelId: 'db_id',
        // modelLabel:'name'
      },
    ],
  };
  useFirestoreConnect([
    {
      collection: "models",
      storeAs: "models",
      orderBy: ['db_id', 'asc']
    },
    {
      collection: "manufacturers",
      storeAs: "manufacturers",
      orderBy: ['name', 'asc']
    },
  ]);
  const createElement = (newMeasure) => {
    if (typeof newMeasure.id === "undefined") {
      addModel(newMeasure);
    } else {
      updateModel(newMeasure, newMeasure.id);
    }
  };

  const deleteElement = (newMeasure) => {
    deleteModel(newMeasure);
  };

  dispatch({ type: actions.SET_MENU, payload: "Instrument models" });

  return (
    <Container className={classes.content}>
      <div>
        {isLoaded &&
          (isEmpty ? (
            <Redirect to="/signin" />
          ) : models ? (
            <FSPTable
              model={models}
              classes={classes}
              createElement={(measure) => createElement(measure)}
              deleteElement={(measure) => deleteElement(measure)}
              form={form}
              meta={{ manufacturers: manufacturers }}
              loading={manufacturers ? manufacturers.length < 2 : true}
            />
          ) : (
            <CircularProgress />
          ))}
      </div>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    models: state.firestore.ordered.models,
    manufacturers: state.firestore.ordered.manufacturers,
    authStatus: state.firebase.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addModel: (model) => dispatch(addModel(model)),
    deleteModel: (id) => dispatch(deleteModel(id)),
    updateModel: (model, id) => dispatch(updateModel(model, id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Models);
