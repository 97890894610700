import firebase from 'firebase/app';
import * as actions from './actionTypes';

export const addAnalyte = (analyte) => async (dispatch, getState) => {
    console.log('bbbb', analyte);
  try {
    dispatch({ type: actions.ADD_TODO_START });
    const uid = getState().firebase.auth.uid;
    const firestore = firebase.firestore();
    await firestore.collection('analytes').add({
      ...analyte,
      authorId: uid,
      createdAt: new Date(),
      updatedAt: new Date(),
    });
    dispatch({ type: actions.ADD_TODO_SUCESS });
  } catch (err) {
    dispatch({ type: actions.ADD_TODO_FAIL, err });
  }
};

export const updateAnalyte = (analyte, id) => async (dispatch) => {
    console.log('bbbb', analyte);
  try {
    dispatch({ type: actions.UPDATE_TODO_START });
    const firestore = firebase.firestore();
    await firestore.collection('analytes').doc(id).update({
      db_id: analyte.db_id,
      name: analyte.name,
      abbreviation: analyte.abbreviation,
      unit: analyte.unit,
      decimals: analyte.decimals,
      alternative_unit: analyte.alternative_unit || null,
      decimals_alternative_unit: analyte.decimals_alternative_unit || null,
      updatedAt: new Date(),
    });
    dispatch({ type: actions.UPDATE_TODO_SUCESS });
  } catch (err) {
    dispatch({ type: actions.UPDATE_TODO_FAIL, err });
  }
};

export const deleteAnalyte = (id) => async (dispatch) => {
    console.log('bbbb', id);
  try {
    dispatch({ type: actions.DELETE_TODO_START });
    const firestore = firebase.firestore();
    await firestore.collection('analytes').doc(id).delete();
    dispatch({ type: actions.DELETE_TODO_SUCESS });
  } catch (err) {
    dispatch({ type: actions.DELETE_TODO_FAIL, err });
  }
};
