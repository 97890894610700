import React, { Component } from 'react';

class IQCRowFloatElements extends Component {
    render() {
        return (
            <div class="jss1031 jss1012 jss1019">
              <ul class="jss1088 jss1032 jss1036 jss1037">
              {this.props.children}
              </ul>
            </div>
        );
    }
}

export default IQCRowFloatElements;