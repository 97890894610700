import firebase from "firebase/app";
import * as actions from "./actionTypes";

export const addInstrumenttype =
  (instrumenttype) => async (dispatch, getState) => {
    console.log("bbbb", instrumenttype);
    try {
      dispatch({ type: actions.ADD_TODO_START });
      const uid = getState().firebase.auth.uid;
      const firestore = firebase.firestore();
      await firestore.collection("instrumenttypes").add({
        ...instrumenttype,
        authorId: uid,
        createdAt: new Date(),
        updatedAt: new Date(),
      });
      dispatch({ type: actions.ADD_TODO_SUCESS });
    } catch (err) {
      dispatch({ type: actions.ADD_TODO_FAIL, err });
    }
  };

export const updateInstrumenttype =
  (instrumenttype, id) => async (dispatch) => {
    console.log("bbbb", instrumenttype);
    try {
      dispatch({ type: actions.UPDATE_TODO_START });
      const firestore = firebase.firestore();
      await firestore.collection("instrumenttypes").doc(id).update({
        db_id: instrumenttype.db_id,
        separate: instrumenttype.separate,
        active: instrumenttype.active,
        name: instrumenttype.name,
        model: instrumenttype.model,
        updatedAt: new Date(),
      });
      dispatch({ type: actions.UPDATE_TODO_SUCESS });
    } catch (err) {
      dispatch({ type: actions.UPDATE_TODO_FAIL, err });
    }
  };

export const deleteInstrumenttype = (id) => async (dispatch) => {
  console.log("bbbb", id);
  try {
    dispatch({ type: actions.DELETE_TODO_START });
    const firestore = firebase.firestore();
    await firestore.collection("instrumenttypes").doc(id).delete();
    dispatch({ type: actions.DELETE_TODO_SUCESS });
  } catch (err) {
    dispatch({ type: actions.DELETE_TODO_FAIL, err });
  }
};
