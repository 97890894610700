import { Delete, Edit } from "@material-ui/icons";
import React, { Component } from "react";
import IQCAnalyteRowButton from "./IQCAnalyteRowButton";

class IQCAnalyteRowButtons extends Component {
  render() {
    const { classes } = this.props;
    return (
      <div class="jss726a">
        {this.props.children}
      </div>
    );
  }
}

export default IQCAnalyteRowButtons;
