import firebase from 'firebase/app';
import * as actions from './actionTypes';

export const addUnit = (unit) => async (dispatch, getState) => {
    console.log('bbbb', unit);
  try {
    dispatch({ type: actions.ADD_TODO_START });
    const uid = getState().firebase.auth.uid;
    const firestore = firebase.firestore();
    await firestore.collection('units').add({
      ...unit,
      authorId: uid,
      createdAt: new Date(),
      updatedAt: new Date(),
    });
    dispatch({ type: actions.ADD_TODO_SUCESS });
  } catch (err) {
    dispatch({ type: actions.ADD_TODO_FAIL, err });
  }
};

export const updateUnit = (unit, id) => async (dispatch) => {
    console.log('bbbb', unit);
  try {
    dispatch({ type: actions.UPDATE_TODO_START });
    const firestore = firebase.firestore();
    await firestore.collection('units').doc(id).update({
      db_id: unit.db_id,
      name: unit.name,
      updatedAt: new Date(),
    });
    dispatch({ type: actions.UPDATE_TODO_SUCESS });
  } catch (err) {
    dispatch({ type: actions.UPDATE_TODO_FAIL, err });
  }
};

export const deleteUnit = (id) => async (dispatch) => {
    console.log('bbbb', id);
  try {
    dispatch({ type: actions.DELETE_TODO_START });
    const firestore = firebase.firestore();
    await firestore.collection('units').doc(id).delete();
    dispatch({ type: actions.DELETE_TODO_SUCESS });
  } catch (err) {
    dispatch({ type: actions.DELETE_TODO_FAIL, err });
  }
};
