import React, { Component } from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { Delete, Add } from "@material-ui/icons";
import {
  Checkbox,
  FormControlLabel,
  Switch,
  Typography,
} from "@material-ui/core";

class IQCSubRowFields extends Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={"jss522"}>
        <Box display="flex" p={1}>
          <Box className={"jss398a "} p={1} flexGrow={1}>
            <div className={"jss398a "}>{this.props.title}</div>
          </Box>
          <Button
            type="submit"
            // variant="contained"
            color="primary"
            className={classes.button}
            onClick={() => {
              this.handleCreate();
            }}
          >
            <div className={"jss508 jss398 jss406 jss536"}>
              Has missing results in current surveys
            </div>
            {/* <Add /> */}
            <FormControlLabel
              value="start"
              control={<Switch color="primary" />}
              // label="Has missing results in current surveys"
              labelPlacement="start"
            />
          </Button>
        </Box>
      </div>
    );
  }
}

export default IQCSubRowFields;
