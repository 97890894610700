import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Navbar from "./components/UI/navigation/Navbar";
import AddQuestionnaire from "./components/UI/projects/AddQuestionnaire";
import UpdateQuestionnaire from "./components/UI/projects/UpdateQuestionnaire";
import Dashboard from "./components/UI/dashboard/Dashboard";
import SignIn from "./components/UI/auth/SignIn";
import SignUp from "./components/UI/auth/SignUp";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { green, blueGrey } from "@material-ui/core/colors";
import Evaluation from "./components/UI/projects/Evaluation";
import Statistics from "./components/UI/projects/Statistics";
import FilledView from "./components/UI/projects/FilledView";
import PermissionsEditor from "./components/UI/dashboard/PermissionsEditor";
import Labs from "./components/UI/labs/labs";
import SamplingComponent from "./components/UI/sampling/Sampling";
import Admins from "./components/UI/admins/Admins";
import Filledquestionnaires from "./components/UI/Filledquestionnaires";
import UsersComponent from "./components/UI/UsersComponent";
import Countries from "./components/UI/basedata/countries";
import Manufacturers from './components/UI/basedata/manufacturers';
import Models from './components/UI/basedata/models';
import Instrumenttypes from './components/UI/basedata/instrumenttypes';
import Modelgroups from './components/UI/basedata/modelgroups';
import Typegroups from './components/UI/basedata/typegroups';
import Analytes from './components/UI/basedata/analytes';
import Samplematerials from './components/UI/basedata/samplematerials';
import Units from './components/UI/basedata/units';
import Methods from './components/UI/basedata/methods';
import Instruments from './components/UI/basedata/instruments';
import Analyteconversions from './components/UI/basedata/analyteconversions';
import Demodata from './components/UI/labs/demodata';
import Demodata2 from './components/UI/labs/demodata2';
import Demodata3 from './components/UI/labs/demodata3';
import Demodata4 from './components/UI/labs/demodata4';
import Histogram from './components/UI/labs/histogram';
import Postgresql from './components/UI/labs/postgresql';
import LabSettings from './components/UI/basedata/labsettings';
import Analytemappings from './components/UI/basedata/analytemappings';
import Unitmappings from './components/UI/basedata/unitmappings';
import Instrumentmappings from './components/UI/basedata/instrumentmappings';
import Autofills from './components/UI/basedata/autofills';
import Comments from './components/UI/basedata/comments';
import Statalerts from './components/UI/basedata/statalerts';
import Analyteadditionalinformations from './components/UI/basedata/analyteadditionalinformations';

const outerTheme = createMuiTheme({
  palette: {
    primary: {
      main: blueGrey[500],
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={outerTheme}>
      <Router basename={"#"}>
        <Switch>
          <Route exact path="/signin" component={SignIn} />
          <Route exact path="/signup" component={SignUp} />
          <Route
            path={[
              "/",
              "/users",
              "/admins",
              "/sampling",
              "/edit/:id",
              "/evaluation/:id",
              "/filled/:id",
              "/statistics/:id",
              "/permissions",
            ]}
          >
            <div style={{ height: "100vh" ,backgroundColor:"#ffffff",flexGrow: 1, alignItems: 'center' }}>
              <Navbar />
              <Switch>
                <Route exact path="/" component={Dashboard} />
                <Route exact path="/labs" component={Labs} />
                <Route exact path="/units" component={Units} />
                <Route exact path="/samplematerials" component={Samplematerials} />
                <Route exact path="/analytes" component={Analytes} />
                <Route exact path="/analyteconversions" component={Analyteconversions} />
                <Route exact path="/analytemapping" component={Analytemappings} />
                <Route exact path="/unitmapping" component={Unitmappings} />
                <Route exact path="/instrumentmapping" component={Instrumentmappings} />
                <Route exact path="/countries" component={Countries} />
                <Route exact path="/manufacturers" component={Manufacturers} />
                <Route exact path="/models" component={Models} />
                <Route exact path="/instrumenttypes" component={Instrumenttypes} />
                <Route exact path="/modelgroups" component={Modelgroups} />
                <Route exact path="/typegroups" component={Typegroups} />
                <Route exact path="/methods" component={Methods} />
                <Route exact path="/instruments" component={Instruments} />
                <Route exact path="/demodata" component={Demodata} />
                <Route exact path="/demodata2" component={Demodata2} />
                <Route exact path="/demodata3" component={Postgresql} />
                <Route exact path="/demodata4" component={Histogram} />
                <Route exact path="/labsettings" component={LabSettings} />
                <Route exact path="/autofill" component={Autofills} />
                <Route exact path="/Comments" component={Comments} />
                <Route exact path="/statalerts" component={Statalerts} />
                <Route exact path="/analyteadditionalinformations" component={Analyteadditionalinformations} />
                
                <Route
                  exact
                  path="/permissions"
                  component={PermissionsEditor}
                />

                <Route exact path="/sampling" component={SamplingComponent} />
                <Route exact path="/admins" component={Admins} />
                <Route exact path="/users" component={UsersComponent} />
                <Route exact path="/edit/:id" component={UpdateQuestionnaire} />
                <Route exact path="/evaluation/:id" component={Evaluation} />
                <Route exact path="/filled" component={Filledquestionnaires} />
                <Route exact path="/filledview/:id" component={FilledView} />
                <Route exact path="/statistics/:id" component={Statistics} />
              </Switch>
            </div>
          </Route>
        </Switch>
      </Router>
    </ThemeProvider>
  );
}

export default App;
