import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle'
import { MenuItem, TextField } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import FSPTableFormField from './FSPTableFormField';

class FSPForm extends Component {
    render() {
        const { classes, form, errors, meta } = this.props;
        return (
            <Dialog
                fullWidth={true}
                classes={{ paper: classes.csvdialog }}
                open={this.props.open}
                // onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{this.props.val['id'] ? form.titles.update : form.titles.create}</DialogTitle>
                <DialogContent style={{ minHeight: '250px' }}>
                    <form>
                        <div>
                            {form && form.fields &&
                                form.fields.map((field, index) =>
                                    <div key={index}>
                                        <FSPTableFormField
                                            field={field}
                                            classes={classes}
                                            handleChange={this.props.handleChange}
                                            handleChangeJSON={this.props.handleChangeJSON}
                                            handleChangeArray={this.props.handleChangeArray}
                                            val={this.props.val}
                                            meta={meta}
                                            errors={errors[field.name] ? errors[field.name] : ''}
                                        />
                                    </div>
                                )}
                        </div>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            this.props.handleStop();
                        }}
                        color="primary"
                        autoFocus
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={() => {
                            this.props.handleFormUpdate(this.props.val)
                        }}
                        color="primary"
                        variant="contained"
                        autoFocus
                    >
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default FSPForm;