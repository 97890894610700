import firebase from 'firebase/app';
import * as actions from './actionTypes';

export const addModelgroup = (modelgroup) => async (dispatch, getState) => {
    console.log('bbbb', modelgroup);
  try {
    dispatch({ type: actions.ADD_TODO_START });
    const uid = getState().firebase.auth.uid;
    const firestore = firebase.firestore();
    await firestore.collection('modelgroups').add({
      ...modelgroup,
      authorId: uid,
      createdAt: new Date(),
      updatedAt: new Date(),
    });
    dispatch({ type: actions.ADD_TODO_SUCESS });
  } catch (err) {
    dispatch({ type: actions.ADD_TODO_FAIL, err });
  }
};

export const updateModelgroup = (modelgroup, id) => async (dispatch) => {
    console.log('bbbb', modelgroup);
  try {
    dispatch({ type: actions.UPDATE_TODO_START });
    const firestore = firebase.firestore();
    await firestore.collection('modelgroups').doc(id).update({
      om: modelgroup.om,
      name: modelgroup.name,
      models: modelgroup.models,
      active: modelgroup.active,
      updatedAt: new Date(),
    });
    dispatch({ type: actions.UPDATE_TODO_SUCESS });
  } catch (err) {
    dispatch({ type: actions.UPDATE_TODO_FAIL, err });
  }
};

export const deleteModelgroup = (id) => async (dispatch) => {
    console.log('bbbb', id);
  try {
    dispatch({ type: actions.DELETE_TODO_START });
    const firestore = firebase.firestore();
    await firestore.collection('modelgroups').doc(id).delete();
    dispatch({ type: actions.DELETE_TODO_SUCESS });
  } catch (err) {
    dispatch({ type: actions.DELETE_TODO_FAIL, err });
  }
};
