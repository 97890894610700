import React from "react";
import { connect } from "react-redux";
import { useFirestoreConnect } from "react-redux-firebase";
import { Redirect } from "react-router-dom";
import {
  addLaboratorysetting,
  deleteLaboratorysetting,
  updateLaboratorysetting,
} from "../../../store/actions/laboratorysettingActions";
import Container from "@material-ui/core/Container";
import useStyles from "../dashboard/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../store/actions/actionTypes";
import FSPTable from "../../common/FSPTable/FSPTable";
import convertArrayByField from "../../common/utils";

const LabSettings = ({
  laboratorysettings,
  manufacturers,
  models,
  labs,
  authStatus,
  addLaboratorysetting,
  updateLaboratorysetting,
  deleteLaboratorysetting,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { isEmpty, isLoaded } = authStatus;
  let manufacturersArray = convertArrayByField(manufacturers, "id");
  let modelsArray = convertArrayByField(models, "id");
  let labsArray = convertArrayByField(labs, "id");
  const form = {
    titles: {
      index: "Laboratory identification setting",
      create: "Add laboratory setting",
      update: "Update laboratory setting",
    },
    keyField: 1,
    indexFields: [0, 1, 3, 6, 8, 9, 10],
    fields: [
      //   {
      //     id: 0,
      //     name: "om",
      //     required: true,
      //     helper: "ID",
      //     q2: "ID",
      //     label: "ID",
      //     type: 2,
      //     suggestion: 'add'//'#ffdddd'
      //   },
      {
        id: 1,
        required: true,
        name: "lab",
        helper: "Lab",
        q2: "Lab",
        label: "Lab",
        type: 3,
        values: labs,
        valuesArray: labsArray,
        values_label: "name",
        values_key: "id",
      },
      {
        id: 2,
        required: true,
        name: "email",
        helper: "email",
        q2: "email",
        label: "email",
        type: 2,
      },
      {
        id: 3,
        required: true,
        name: "conditions",
        helper: "Addition conditions",
        q2: "Addition conditions",
        label: "Addition conditions",
        type: 2,
      },
      {
        id: 4,
        required: true,
        name: "format",
        helper: "Format",
        q2: "Format",
        label: "Format",
        type: 3,
        values: [
          { id: "table", name: "embedded table" },
          { id: "excel", name: "attached EXCEL-file" },
          { id: "csv", name: "attached text-file" },
        ],
        valuesArray: {
          table: [{ id: "table", name: "embedded table" }],
          excel: [{ id: "excel", name: "attached EXCEL-file" }],
          csv: [{ id: "csv", name: "attached text-file" }],
        },
        values_label: "name",
        values_key: "id",
      },
      {
        id: 5,
        required: true,
        name: "from",
        helper: "Validation start date",
        q2: "Validation start date",
        // label: 'Validation start date',
        type: 6,
      },
      {
        id: 6,
        required: true,
        name: "to",
        helper: "Validation end date",
        q2: "Validation end date",
        // label: 'Validation end date',
        type: 6,
      },
      {
        id: 7,
        required: true,
        name: "active",
        helper: "Active",
        q2: "Active",
        label: "Active",
        type: 3,
        values: [
          { id: "false", name: "Inactive" },
          { id: "true", name: "Active" },
        ],
        valuesArray: {
          false: [{ id: "false", name: "Inactive" }],
          true: [{ id: "true", name: "Active" }],
        },
        values_label: "name",
        values_key: "id",
      },
      {
        id: 8,
        required: true,
        name: "heading",
        helper: "Do the import file include a heading?",
        q2: "Do the import file include a heading?",
        label: "Do the import file include a heading?",
        type: 3,
        values: [
          { id: "false", name: "No" },
          { id: "true", name: "Yes" },
        ],
        valuesArray: {
          true: [{ id: "true", name: "Yes" }],
          false: [{ id: "false", name: "No" }],
        },
        values_label: "name",
        values_key: "id",
        // model:'manufacturers',
        // modelId: 'db_id',
        // modelLabel:'name'
      },
      {
        id: 9,
        required: true,
        name: "population",
        helper: "Patient population ",
        q2: "Patient population ",
        label: "Patient population ",
        type: 3,
        values: [
          { id: "Outpatients", name: "Outpatients" },
          { id: "all", name: "All patients" },
          { id: "Unknown", name: "Unknown" },
        ],
        valuesArray: {
          Outpatients: [{ id: "Outpatients", name: "Outpatients" }],
          all: [{ id: "all", name: "All patients" }],
          Unknown: [{ id: "Unknown", name: "Unknown" }],
        },
        values_label: "name",
        values_key: "id",
      },
      {
        id: 10,
        required: true,
        name: "single",
        helper: "Single instruments/group of instruments?",
        q2: "Single instruments/group of instruments",
        label: "Single instruments/group of instruments?",
        type: 3,
        values: [
          { id: "single", name: "Single instrument" },
          { id: "group", name: "A group of instruments" },
        ],
        valuesArray: {
          single: [{ id: "single", name: "Single instrument" }],
          group: [{ id: "group", name: "A group of instruments" }],
        },
        values_label: "name",
        values_key: "id",
      },
      {
        id: 11,
        required: true,
        name: "dateformat",
        helper: "Measurement date format",
        q2: "Measurement date format",
        label: "Measurement date format",
        type: 2,
      },
      {
        id: 12,
        required: true,
        name: "decimal",
        helper: "Decimal separator",
        q2: "Decimal separator",
        label: "Decimal separator",
        type: 2,
      },
      {
        id: 13,
        required: true,
        name: "daysbeforereporting",
        helper: "Number of days without data before reporting",
        q2: "Number of days without data before reporting",
        label: "Number of days without data before reporting",
        type: 2,
      },
      {
        id: 14,
        required: true,
        name: "encoding",
        helper: "Character encoding",
        q2: "Character encoding",
        label: "Character encoding",
        type: 2,
      },
      {
        id: 15,
        required: true,
        name: "csvdelimiter",
        helper: "CSV delimiter",
        q2: "CSV delimiter",
        label: "CSV delimiter",
        type: 2,
      },
      // {
      //     id: 16,
      //     required: true,
      //     name: 'positioning',
      //     helper: 'Data positioning',
      //     q2: 'Data positioning',
      //     // label: 'Validation start date',
      //     type: 'list',
      // },
      {
        id: 17,
        required: true,
        name: "positions",
        helper: "Data positioning",
        q2: "Data positioning",
        // label: 'Validation start date',
        type: "JSONTable",
        form: {
          titles: {
            index: "Data positions",
            create: "New data position",
            update: "Update data position",
          },
          keyField: 0,
          indexFields: [0, 1],
          fields: [
            {
              id: 0,
              required: true,
              name: "id",
              helper: "Position",
              q2: "Position",
              label: "Position",
              type: 2,
            },
            {
              id: 1,
              required: true,
              name: "name",
              helper: "Analyte conversion name",
              q2: "Analyte conversion name",
              label: "Analyte conversion name",
              type: 3,
              values: [
                { id: "Identifier", name: "Identifier" },
                { id: "Measurementdate", name: "Measurement date" },
                { id: "Instrument", name: "Instrument" },
                { id: "Patientgroup", name: "Patient group" },
                { id: "Analyte", name: "Analyte" },
                { id: "Unit", name: "Unit" },
                { id: "Value", name: "Value" },
                { id: "low", name: "% low" },
                { id: "high", name: "% high" },
                { id: "Numberofresults", name: "Number of results" },
                { id: "Lotnumber", name: "Lot number" },
                { id: "Comment", name: "Comment" },
              ],
              valuesArray: {
                // false: [{ id: "false", name: "Inactive" }],
                // true: [],
                Identifier:[{ id: "Identifier", name: "Identifier" }],
                Measurementdate:[{ id: "Measurementdate", name: "Measurement date" }],
                Instrument:[{ id: "Instrument", name: "Instrument" }],
                Patientgroup:[{ id: "Patientgroup", name: "Patient group" }],
                Analyte:[{ id: "Analyte", name: "Analyte" }],
                Unit:[{ id: "Unit", name: "Unit" }],
                Value:[{ id: "Value", name: "Value" }],
                low:[{ id: "low", name: "% low" }],
                high:[{ id: "high", name: "% high" }],
                Numberofresults:[{ id: "Numberofresults", name: "Number of results" }],
                Lotnumber:[{ id: "Lotnumber", name: "Lot number" }],
                Comment:[{ id: "Comment", name: "Comment" }],
              },
              values_label: "name",
              values_key: "id",
            },
          ],
        },
      },
    ],
  };
  useFirestoreConnect([
    {
      collection: "laboratorysettings",
      storeAs: "laboratorysettings",
      orderBy: ["createdAt", "asc"],
    },
    {
      collection: "manufacturers",
      storeAs: "manufacturers",
      orderBy: ["name", "asc"],
    },
    {
      collection: "models",
      storeAs: "models",
      orderBy: ["name", "asc"],
    },
    {
      collection: "labs",
      storeAs: "labs",
      orderBy: ["om", "asc"],
    },
  ]);
  const createElement = (newMeasure) => {
    if (typeof newMeasure.id === "undefined") {
      addLaboratorysetting(newMeasure);
    } else {
      updateLaboratorysetting(newMeasure, newMeasure.id);
    }
  };

  const deleteElement = (newMeasure) => {
    deleteLaboratorysetting(newMeasure);
  };

  dispatch({
    type: actions.SET_MENU,
    payload: "Laboratory identification setting",
  });

  return (
    <Container className={classes.content}>
      <div>
        {isLoaded &&
          (isEmpty ? (
            <Redirect to="/signin" />
          ) : laboratorysettings ? (
            <FSPTable
              model={laboratorysettings}
              classes={classes}
              createElement={(measure) => createElement(measure)}
              deleteElement={(measure) => deleteElement(measure)}
              form={form}
              meta={{
                labs: labs,
                models: models,
                manufacturers: manufacturers,
              }}
            />
          ) : (
            <CircularProgress />
          ))}
      </div>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    laboratorysettings: state.firestore.ordered.laboratorysettings,
    manufacturers: state.firestore.ordered.manufacturers,
    models: state.firestore.ordered.models,
    labs: state.firestore.ordered.labs,
    authStatus: state.firebase.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addLaboratorysetting: (laboratorysetting) =>
      dispatch(addLaboratorysetting(laboratorysetting)),
    deleteLaboratorysetting: (id) => dispatch(deleteLaboratorysetting(id)),
    updateLaboratorysetting: (laboratorysetting, id) =>
      dispatch(updateLaboratorysetting(laboratorysetting, id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LabSettings);
