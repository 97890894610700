import firebase from 'firebase/app';
import * as actions from './actionTypes';
import Evaluation from '../../components/UI/projects/Evaluation';

export const addFilledquestionnaire = (filledquestionnaire) => async (dispatch, getState) => {

    try {

        dispatch({ type: actions.ADD_TODO_START });

        const uid = getState().firebase.auth.uid;
        const firestore = firebase.firestore();
        await firestore.collection('filledquestionnaires').add({
            ...filledquestionnaire,
            authorId: uid,
            createdAt: new Date(),
            updatedAt: new Date(),
        });
        console.log(filledquestionnaire);
        dispatch({ type: actions.ADD_TODO_SUCESS });
    } catch (err) {
        dispatch({ type: actions.ADD_TODO_FAIL, err });
    }
};

export const addFilledquestionnaire2 = (filledquestionnaire) => {
    return (dispatch, getState, { getFirebase }) => {
        const firestore = getFirebase().firestore();
        const authorId = getState().firebase.auth.uid
        console.log(filledquestionnaire);
        firestore
            .collection("xxx")
            .add({
                ...filledquestionnaire,
                authorId: authorId,
                date: new Date(),
            })
            .then(() => {
                //   dispatch({
                //     type: "ADD_TASK",
                //     'task',
                //   });
            })
            .catch((err) => {
                dispatch({
                    type: "ADD_TASK_ERR",
                    err,
                });
            });
    };
};

export const updateFilledquestionnaire = (filledquestionnaire, id) => async (dispatch) => {
    try {
        dispatch({ type: actions.UPDATE_TODO_START });
        const firestore = firebase.firestore();
        await firestore.collection('filledquestionnaires').doc(id).update({
            // title: filledquestionnaire.title,
            institutions:filledquestionnaire.institutions,
            // description: filledquestionnaire.description,
            // gdpr: filledquestionnaire.gdpr,
            // personal: filledquestionnaire.personal,
            // answer: filledquestionnaire.answer,
            // version: filledquestionnaire.version,
            // filledquestionnaire: filledquestionnaire.filledquestionnaire,
            // state: filledquestionnaire.state,
            updatedAt: new Date(),
        });
        dispatch({ type: actions.UPDATE_TODO_SUCESS });
    } catch (err) {
        dispatch({ type: actions.UPDATE_TODO_FAIL, err });
    }
};

export const updateGdpr = (state, id) => async (dispatch) => {
    try {
        console.log(id);
        console.log(state);
        dispatch({ type: actions.UPDATE_TODO_START });
        const firestore = firebase.firestore();
        await firestore.collection('filledquestionnaires').doc(id).update({
            gdpr: state,
            updatedAt: new Date(),
        });
        dispatch({ type: actions.UPDATE_TODO_SUCESS });
    } catch (err) {
        dispatch({ type: actions.UPDATE_TODO_FAIL, err });
    }
};

export const updatePersonal = (personal, id) => async (dispatch) => {
    try {
        console.log(id);
        console.log(JSON.stringify(personal));
        dispatch({ type: actions.UPDATE_TODO_START });
        const firestore = firebase.firestore();
        await firestore.collection('filledquestionnaires').doc(id).update({
            personal: personal,
            updatedAt: new Date(),
        });
        dispatch({ type: actions.UPDATE_TODO_SUCESS });
    } catch (err) {
        dispatch({ type: actions.UPDATE_TODO_FAIL, err });
    }
};

export const updateAnswers = (answers, id) => async (dispatch) => {
    try {
        console.log(id);
        console.log(answers);
        dispatch({ type: actions.UPDATE_TODO_START });
        const firestore = firebase.firestore();
        await firestore.collection('filledquestionnaires').doc(id).update({
            answers: answers,
            updatedAt: new Date(),
        });
        dispatch({ type: actions.UPDATE_TODO_SUCESS });
    } catch (err) {
        dispatch({ type: actions.UPDATE_TODO_FAIL, err });
    }
};

export const updateEvaluations = (evaluations, id) => async (dispatch) => {
    try {
        console.log(id);
        console.log(evaluations);
        dispatch({ type: actions.UPDATE_TODO_START });
        const firestore = firebase.firestore();
        await firestore.collection('filledquestionnaires').doc(id).update({
            evaluations: evaluations,
            status: 'evaluated',
            updatedAt: new Date(),
        });
        dispatch({ type: actions.UPDATE_TODO_SUCESS });
    } catch (err) {
        console.log(err);
        dispatch({ type: actions.UPDATE_TODO_FAIL, err });
    }
};

export const updateStatus = (status, id) => async (dispatch) => {
    try {
        dispatch({ type: actions.UPDATE_TODO_START });
        const firestore = firebase.firestore();
        await firestore.collection('filledquestionnaires').doc(id).update({
            status: status,
            updatedAt: new Date(),
        });
        dispatch({ type: actions.UPDATE_TODO_SUCESS });
    } catch (err) {
        dispatch({ type: actions.UPDATE_TODO_FAIL, err });
    }
};

export const deleteFilledquestionnaire = (id) => async (dispatch) => {
    try {
        dispatch({ type: actions.DELETE_TODO_START });
        const firestore = firebase.firestore();
        await firestore.collection('filledquestionnaires').doc(id).update({
            status: 'deleted',
            updatedAt: new Date(),
        });
        dispatch({ type: actions.DELETE_TODO_SUCESS });
    } catch (err) {
        dispatch({ type: actions.DELETE_TODO_FAIL, err });
    }
};
