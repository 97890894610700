import React, { Component } from "react";

class IQCRowFloatMiniElement extends Component {
  render() {
    return (
        <li
        class="jss324 jss661 jss664 jss669 jss670 jss637"
        tabindex="0"
        role="button"
        id="7504"
        group="2020"
        ordinal="11"
      >
        <div class="jss674 jss645">
          <span class="jss518 jss536 jss677 jss638">
            %
          </span>
        </div>

      </li>
    );
  }
}

export default IQCRowFloatMiniElement;
