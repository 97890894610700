import React, { Component } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { MenuItem, TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import JSONTableFormField from "./JSONTableFormField";

class JSONTableDeleteDialog extends Component {
  render() {
    const { classes, form, errors, id } = this.props;
    return (
      <Dialog
        fullWidth={true}
        classes={{ paper: classes.csvdialog }}
        open={this.props.open}
        // onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>Are you sure you want to delete this item?</DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              this.props.handleDeleteCancel();
            }}
            color="primary"
            autoFocus
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              this.props.handleDelete(id);
            }}
            color="primary"
            variant="contained"
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default JSONTableDeleteDialog;
