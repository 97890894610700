import React from "react";
import Container from "@material-ui/core/Container";
import useStyles from "../dashboard/styles";
import Demodata4 from "./demodata4";

const Histogram = () => {
  const classes = useStyles();

  return (
    <Container className={classes.content}>
      <div>
        <Demodata4 classes={classes} />
      </div>
    </Container>
  );
};

export default Histogram;
