import firebase from 'firebase/app';
import * as actions from './actionTypes';

export const addAnalytemapping = (analytemapping) => async (dispatch, getState) => {
    console.log('bbbb', analytemapping);
  try {
    dispatch({ type: actions.ADD_TODO_START });
    const uid = getState().firebase.auth.uid;
    const firestore = firebase.firestore();
    await firestore.collection('analytemappings').add({
      ...analytemapping,
      authorId: uid,
      createdAt: new Date(),
      updatedAt: new Date(),
    });
    dispatch({ type: actions.ADD_TODO_SUCESS });
  } catch (err) {
    dispatch({ type: actions.ADD_TODO_FAIL, err });
  }
};

export const updateAnalytemapping = (analytemapping, id) => async (dispatch) => {
    console.log('bbbb', analytemapping);
  try {
    dispatch({ type: actions.UPDATE_TODO_START });
    const firestore = firebase.firestore();
    await firestore.collection('analytemappings').doc(id).update({
      // db_id: analytemapping.db_id,
      source: analytemapping.source,
      target: analytemapping.target,
      lab: analytemapping.lab,
      method: analytemapping.method,
      method: analytemapping.method,
      a: analytemapping.a,
      b: analytemapping.b,
      samplematerials: analytemapping.samplematerials,
      options: analytemapping.options,
      updatedAt: new Date(),
    });
    dispatch({ type: actions.UPDATE_TODO_SUCESS });
  } catch (err) {
    dispatch({ type: actions.UPDATE_TODO_FAIL, err });
  }
};

export const deleteAnalytemapping = (id) => async (dispatch) => {
    console.log('bbbb', id);
  try {
    dispatch({ type: actions.DELETE_TODO_START });
    const firestore = firebase.firestore();
    await firestore.collection('analytemappings').doc(id).delete();
    dispatch({ type: actions.DELETE_TODO_SUCESS });
  } catch (err) {
    dispatch({ type: actions.DELETE_TODO_FAIL, err });
  }
};
