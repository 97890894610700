import React from "react";
import { connect } from "react-redux";
import { useFirestoreConnect } from "react-redux-firebase";
import { Redirect } from "react-router-dom";
import {
  addAnalyteadditionalinformation,
  deleteAnalyteadditionalinformation,
  updateAnalyteadditionalinformation,
} from "../../../store/actions/analyteadditionalinformationActions";
import Container from "@material-ui/core/Container";
import useStyles from "../dashboard/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../store/actions/actionTypes";
import FSPTable from "../../common/FSPTable/FSPTable";
import convertArrayByField from "../../common/utils";

const Analyteadditionalinformations = ({
  analyteadditionalinformations,
  analytes,
  units,
  authStatus,
  addAnalyteadditionalinformation,
  updateAnalyteadditionalinformation,
  deleteAnalyteadditionalinformation,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { isEmpty, isLoaded } = authStatus;
  let unitsArray = convertArrayByField(units, "db_id");
  let analytesArray = convertArrayByField(analytes, "id");
  console.log(units);
  console.log(unitsArray);
  const form = {
    titles: {
      index: "Analyte additional information",
      create: "Add analyte additional information",
      update: "Update analyte additional information",
    },
    keyField: 0,
    indexFields: [0, 1, 2, 3, 4],
    fields: [
      {
        id: 0,
        required: true,
        name: "analyte",
        helper: "Analyte",
        q2: "Analyte",
        label: "Analyte",
        type: 3,
        values: analytes,
        valuesArray: analytesArray,
        values_label: "name",
        values_key: "id",
      },
      {
        id: 1,
        required: true,
        name: "name",
        helper: "Question (as free text)",
        q2: "Question (as free text)",
        label: "Question (as free text)",
        type: 2,
      },
      {
        id: 2,
        required: true,
        name: "explanation",
        helper: "Explanation",
        q2: "Explanation",
        label: "Explanation",
        type: 2,
      },
      {
        id: 3,
        required: true,
        name: "mandatory",
        helper: "Mandatory",
        q2: "Mandatory",
        label: "Mandatory",
        type: 3,
        values: [
          { id: "false", name: "No" },
          { id: "true", name: "Yes" },
        ],
        valuesArray: {
          true: [{ id: "true", name: "Yes" }],
          false: [{ id: "false", name: "No" }],
        },
        values_label: "name",
        values_key: "id",
        // model:'manufacturers',
        // modelId: 'db_id',
        // modelLabel:'name'
      },
      {
        id: 4,
        required: true,
        name: "comment",
        helper: "Comment",
        q2: "Comment",
        label: "Comment",
        type: 2,
      },
      {
        id: 5,
        required: true,
        name: "options",
        helper: "Options",
        q2: "Options",
        // label: 'Validation start date',
        type: "JSONTable",
        form: {
          titles: {
            index: "Options",
            create: "Add option",
            update: "Update option",
          },
          keyField: 0,
          indexFields: [0, 1],
          fields: [
            {
              id: 0,
              required: true,
              name: "id",
              helper: "Position",
              q2: "Position",
              label: "Position",
              type: 2,
            },
            {
              id: 1,
              required: true,
              name: "name",
              helper: "Option",
              q2: "Option",
              label: "Option",
              type: 2,
            },
          ],
        },
      },
      // Unit
      // Decimals
      // Alternative unit
      // Decimals alternative unit
    ],
  };
  useFirestoreConnect([
    {
      collection: "analyteadditionalinformations",
      storeAs: "analyteadditionalinformations",
      orderBy: ["createdAt", "asc"],
    },
    {
      collection: "analytes",
      storeAs: "analytes",
      orderBy: ["db_id", "asc"],
    },
    {
      collection: "units",
      storeAs: "units",
      orderBy: ["db_id", "asc"],
    },
  ]);
  const createElement = (newMeasure) => {
    if (typeof newMeasure.id === "undefined") {
      addAnalyteadditionalinformation(newMeasure);
    } else {
      updateAnalyteadditionalinformation(newMeasure, newMeasure.id);
    }
  };

  const deleteElement = (newMeasure) => {
    deleteAnalyteadditionalinformation(newMeasure);
  };

  dispatch({
    type: actions.SET_MENU,
    payload: "Analyte additional information",
  });

  return (
    <Container className={classes.content}>
      <div>
        {isLoaded &&
          (isEmpty ? (
            <Redirect to="/signin" />
          ) : analyteadditionalinformations ? (
            <FSPTable
              model={analyteadditionalinformations}
              classes={classes}
              createElement={(measure) => createElement(measure)}
              deleteElement={(measure) => deleteElement(measure)}
              form={form}
              meta={{ units: units }}
            />
          ) : (
            <CircularProgress />
          ))}
      </div>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    analyteadditionalinformations:
      state.firestore.ordered.analyteadditionalinformations,
    analytes: state.firestore.ordered.analytes,
    units: state.firestore.ordered.units,
    authStatus: state.firebase.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addAnalyteadditionalinformation: (analyteadditionalinformation) =>
      dispatch(addAnalyteadditionalinformation(analyteadditionalinformation)),
    deleteAnalyteadditionalinformation: (id) =>
      dispatch(deleteAnalyteadditionalinformation(id)),
    updateAnalyteadditionalinformation: (analyteadditionalinformation, id) =>
      dispatch(
        updateAnalyteadditionalinformation(analyteadditionalinformation, id)
      ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Analyteadditionalinformations);
