import React, { Component } from "react";

class IQCAnalyteRowKey extends Component {
  render() {
    return (
      <div class="jss726 jss765 jss823 jss1052 jss835">
        <div class="jss1055">
          <div class="jss1056">{this.props.children}</div>
        </div>
      </div>
    );
  }
}

export default IQCAnalyteRowKey;
