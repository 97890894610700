import React from "react";
import SignedInLinks from "./SignedInLinks";
import SignedOutLinks from "./SignedOutLinks";
import { signOut } from "../../../store/actions/authActions";
import { setMenu } from "../../../store/actions/menuActions";
import { connect } from "react-redux";
import IconButton from "@material-ui/core/IconButton";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { Redirect, useHistory } from "react-router-dom";
import clsx from "clsx";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import {
  AccountCircleOutlined,
  ListAltOutlined,
  Security,
  DescriptionOutlined,
  AccountBalance,
  DateRange,
  BarChart,
  ImportExport,
  Add,
  Group,
  ViewListOutlined,
  DnsOutlined,
  Public,
  DynamicFeed,
  DragIndicator,
  GroupWork,
  ExpandLess,
  ExpandMore,
  StarBorder,
  AcUnit,
  LocalDining,
  Category,
  Colorize,
  Speed,
  Scanner,
  MergeType,
  FormatColorFill,
  AccountTreeOutlined,
  AccountTreeRounded,
  SettingsApplications,
  BarChartTwoTone,
  LabelImportantTwoTone,
  SettingsInputComponent,
  SettingsApplicationsOutlined,
  SignalCellularConnectedNoInternet0Bar,
  Equalizer,
  EqualizerOutlined,
  FingerprintOutlined,
  InvertColors,
  PieChartOutlined,
  ScannerOutlined,
  TimelapseOutlined,
  TimelineOutlined,
  Tune,
  TuneOutlined,
  ShowChartOutlined,
  DomainOutlined,
  ColorizeOutlined,
  TonalityOutlined,
  CompareArrowsOutlined,
  Comment,
  CallMergeOutlined,
  WarningOutlined,
  AddToPhotos,
} from "@material-ui/icons";
import MailIcon from "@material-ui/icons/Mail";
import {
  withStyles,
  makeStyles,
  createMuiTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
import { green, purple } from "@material-ui/core/colors";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../store/actions/actionTypes";
import { Collapse, SvgIcon, Tooltip } from "@material-ui/core";
import { usePromiseTracker } from "react-promise-tracker";
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(1),
  },
  title: {
    flexGrow: 1,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: "#00205B",
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(7) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  active: {
    boxShadow: "rgba(0, 0, 0, 0.87) 4px 0px 0px inset",
    backgroundColor: "#a3b1ff",
  },
  inactive: {},
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  logo: {
    height: "30px",
    marginRight: "20px",
  },
}));

const Navbar = (params) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { authStatus, profile, signOut, menu } = params;
  const { isEmpty, isLoaded } = authStatus;
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const { promiseInProgress } = usePromiseTracker();

  const handleClick = () => {
    setOpen(!open);
  };
  const handleClick2 = (a, b) => {
    console.log(a);
    console.log(b);
    setOpen2(open2 === a ? 0 : a);
  };
  const history = useHistory();

  const classes = useStyles();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleSubmit = () => {
    dispatch({ type: actions.SET_MENU, payload: "Kérdőívek" });
    history.push(`/`);
    // dispatch({ type: actions.MEASURE_FORM, payload: { lab: 1, unit: 2 } })
  };
  const handleSubmitLabs = () => {
    dispatch({ type: actions.SET_MENU, payload: "Labs" });
    history.push(`/labs`);
  };
  const handleSubmitMenu = (menu) => {
    console.log("MENU", menu);
    dispatch({ type: actions.SET_MENU, payload: menu });
    history.push(`/` + menu);
  };

  const handleSubmitForm = () => {
    dispatch({ type: actions.SET_MENU, payload: "Mintavételek" });
    history.push(`/sampling`);
  };

  const handleSubmitAdmin = () => {
    dispatch({ type: actions.SET_MENU, payload: "Adminisztrátorok" });
    history.push(`/admins`);
  };

  const handleSubmitImport = () => {
    dispatch({ type: actions.SET_MENU, payload: "Tesztfelvételek" });
    history.push(`/filled`);
  };

  const handleSubmitUsers = () => {
    dispatch({ type: actions.SET_MENU, payload: "Felhasználók" });
    history.push(`/users`);
  };

  const handleSubmitStat = () => {
    dispatch({ type: actions.SET_MENU, payload: "Statisztikák" });
  };

  const handleSubmitPermissions = () => {
    dispatch({ type: actions.SET_MENU, payload: "Résztvevők" });
    history.push(`/permissions`);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const theme = createMuiTheme({
    palette: {
      primary: green,
      backgroundColor: purple[700],
    },
  });

  const signout2 = () => {
    signOut();
  };
  console.log(",,,,,,,,,,,,,,,,,,,,", params);

  return (
    <>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        {/* <Toolbar>
          <Typography variant='h6' color='inherit' className={classes.title}>
            Kérdőív Admin
          </Typography>
          {isLoaded &&
            (isEmpty ? (
              <SignedOutLinks></SignedOutLinks>
            ) : (
              <SignedInLinks
                signOut={signOut}
                profile={profile}
              ></SignedInLinks>
            ))}
        </Toolbar> */}
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" color="inherit" className={classes.title}>
            {params.menu}
          </Typography>
          {promiseInProgress && (
            <img className={classes.logo} src={"ajax-loader5.gif"} />
          )}
          {isLoaded &&
            (isEmpty ? (
              <SignedOutLinks></SignedOutLinks>
            ) : (
              <SignedInLinks
                signOut={signout2}
                profile={profile}
              ></SignedInLinks>
            ))}
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <List>
          <Tooltip
            placement="left"
            title="Instruments, models, types"
            disableHoverListener={open}
          >
            <ListItem onClick={(e) => handleClick2(2, e)}>
              <ListItemIcon>
              <ScannerOutlined  color={open2 === 2  ? "secondary" : "primary" }/>
                {open2 === 2 ? (
                  <ExpandLess fontSize="small" />
                ) : (
                  <ExpandMore fontSize="small" />
                )}
              </ListItemIcon>
              <ListItemText primary="Instruments, models, types" />
            </ListItem>
          </Tooltip>

          <Collapse in={open2 === 2} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <Tooltip
                placement="left"
                title="Instruments"
                disableHoverListener={open}
              >
                <ListItem
                  onClick={() => handleSubmitMenu("Instruments")}
                  disabled={menu == "Instruments"}
                  className={
                    menu == "Instruments" ? classes.active : classes.inactive
                  }
                  button
                  key={"text"}
                >
                  <ListItemIcon>
                    <DnsOutlined color="primary" />
                  </ListItemIcon>
                  <ListItemText primary={"Instruments"} />
                </ListItem>
              </Tooltip>

              <Tooltip
                placement="left"
                title="Manufacturers"
                disableHoverListener={open}
              >
                <ListItem
                  onClick={() => handleSubmitMenu("manufacturers")}
                  disabled={menu == "Manufacturers"}
                  className={
                    menu == "Manufacturers" ? classes.active : classes.inactive
                  }
                  button
                  key={"text"}
                >
                  <ListItemIcon>
                    <DomainOutlined color="primary" />
                  </ListItemIcon>
                  <ListItemText primary={"Manufacturers"} />
                </ListItem>
              </Tooltip>

              <Tooltip
                placement="left"
                title="Instrument models"
                disableHoverListener={open}
              >
                <ListItem
                  onClick={() => handleSubmitMenu("models")}
                  disabled={menu == "Instrument models"}
                  className={
                    menu == "Instrument models"
                      ? classes.active
                      : classes.inactive
                  }
                  button
                  key={"text"}
                >
                  <ListItemIcon>
                    <ViewListOutlined color="primary" />
                  </ListItemIcon>
                  <ListItemText primary={"Models"} />
                </ListItem>
              </Tooltip>

              <Tooltip
                placement="left"
                title="Instrument types"
                disableHoverListener={open}
              >
                <ListItem
                  onClick={() => handleSubmitMenu("instrumenttypes")}
                  disabled={menu == "Instrument types"}
                  className={
                    menu == "Instrument types"
                      ? classes.active
                      : classes.inactive
                  }
                  button
                  key={"text"}
                >
                  <ListItemIcon>
                    <DynamicFeed color="primary" />
                  </ListItemIcon>
                  <ListItemText primary={"Instrument types"} />
                </ListItem>
              </Tooltip>

              <Tooltip
                placement="left"
                title="Instrument model groups"
                disableHoverListener={open}
              >
                <ListItem
                  onClick={() => handleSubmitMenu("modelgroups")}
                  disabled={menu == "Instrument model groups"}
                  className={
                    menu == "Instrument model groups"
                      ? classes.active
                      : classes.inactive
                  }
                  button
                  key={"text"}
                >
                  <ListItemIcon>
                    <GroupWork color="primary" />
                  </ListItemIcon>
                  <ListItemText primary={"Instrument model groups"} />
                </ListItem>
              </Tooltip>

              <Tooltip
                placement="left"
                title="Instrument type groups"
                disableHoverListener={open}
              >
                <ListItem
                  onClick={() => handleSubmitMenu("typegroups")}
                  disabled={menu == "Instrument type groups"}
                  className={
                    menu == "Instrument type groups"
                      ? classes.active
                      : classes.inactive
                  }
                  button
                  key={"text"}
                >
                  <ListItemIcon>
                    <DragIndicator color="primary" />
                  </ListItemIcon>
                  <ListItemText primary={"Instrument type groups"} />
                </ListItem>
              </Tooltip>
            </List>
          </Collapse>

          <Tooltip
            placement="left"
            title="EQA config"
            disableHoverListener={open}
          >
            <ListItem onClick={(e) => handleClick2(1, e)}>
              <ListItemIcon>
                <TuneOutlined  color={open2 === 1  ? "secondary" : "primary" }/>
                {open2 === 1 ? (
                  <ExpandLess fontSize="small" />
                ) : (
                  <ExpandMore fontSize="small" />
                )}
              </ListItemIcon>
              <ListItemText primary="EQA config" />
            </ListItem>
          </Tooltip>

          <Collapse in={open2 === 1} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <Tooltip
                placement="left"
                title="Labs"
                disableHoverListener={open}
              >
                <ListItem
                  onClick={() => handleSubmitMenu("labs")}
                  disabled={menu == "Labs"}
                  className={menu == "Labs" ? classes.active : classes.inactive}
                  button
                  key={"text"}
                >
                  <ListItemIcon>
                    <AccountBalance />
                  </ListItemIcon>
                  <ListItemText primary={"Labs"} />
                </ListItem>
              </Tooltip>
              <Tooltip
                placement="left"
                title="Units"
                disableHoverListener={open}
              >
                <ListItem
                  onClick={() => handleSubmitMenu("units")}
                  disabled={menu == "Units"}
                  className={
                    menu == "Units" ? classes.active : classes.inactive
                  }
                  button
                  key={"text"}
                >
                  <ListItemIcon>
                    <Speed />
                  </ListItemIcon>
                  <ListItemText primary={"Units"} />
                </ListItem>
              </Tooltip>

              <Tooltip
                placement="left"
                title="Analytes"
                disableHoverListener={open}
              >
                <ListItem
                  onClick={() => handleSubmitMenu("analytes")}
                  disabled={menu == "Analytes"}
                  className={
                    menu == "Analytes" ? classes.active : classes.inactive
                  }
                  button
                  key={"text"}
                >
                  <ListItemIcon>
                    <InvertColors />
                  </ListItemIcon>
                  <ListItemText primary={"Analytes"} />
                </ListItem>
              </Tooltip>

              <Tooltip
                placement="left"
                title="Analyte additional information"
                disableHoverListener={open}
              >
                <ListItem
                  onClick={() => handleSubmitMenu("analyteadditionalinformations")}
                  disabled={menu == "Analyte additional information"}
                  className={
                    menu == "Analyte additional information" ? classes.active : classes.inactive
                  }
                  button
                  key={"text"}
                >
                  <ListItemIcon>
                    <AddToPhotos />
                  </ListItemIcon>
                  <ListItemText primary={"Analyte additional information"} />
                </ListItem>
              </Tooltip>

              <Tooltip
                placement="left"
                title="Methods"
                disableHoverListener={open}
              >
                <ListItem
                  onClick={() => handleSubmitMenu("Methods")}
                  disabled={menu == "Methods"}
                  className={
                    menu == "Methods" ? classes.active : classes.inactive
                  }
                  button
                  key={"text"}
                >
                  <ListItemIcon>
                    <TonalityOutlined />
                  </ListItemIcon>
                  <ListItemText primary={"Methods"} />
                </ListItem>
              </Tooltip>

              <Tooltip
                placement="left"
                title="Analyte conversions"
                disableHoverListener={open}
              >
                <ListItem
                  onClick={() => handleSubmitMenu("analyteconversions")}
                  disabled={menu == "Analyte conversions"}
                  className={
                    menu == "Analyte conversions"
                      ? classes.active
                      : classes.inactive
                  }
                  button
                  key={"text"}
                >
                  <ListItemIcon>
                    <InvertColors />
                  </ListItemIcon>
                  <ListItemText primary={"Analyte conversions"} />
                </ListItem>
              </Tooltip>

              <Tooltip
                placement="left"
                title="Sample materials"
                disableHoverListener={open}
              >
                <ListItem
                  onClick={() => handleSubmitMenu("samplematerials")}
                  disabled={menu == "Sample materials"}
                  className={
                    menu == "Sample materials"
                      ? classes.active
                      : classes.inactive
                  }
                  button
                  key={"text"}
                >
                  <ListItemIcon>
                    <Colorize />
                  </ListItemIcon>
                  <ListItemText primary={"Sample materials"} />
                </ListItem>
              </Tooltip>

              <Tooltip
                placement="left"
                title="Countries"
                disableHoverListener={open}
              >
                <ListItem
                  onClick={() => handleSubmitMenu("countries")}
                  disabled={menu == "Countries"}
                  className={
                    menu == "Countries" ? classes.active : classes.inactive
                  }
                  button
                  key={"text"}
                >
                  <ListItemIcon>
                    <Public />
                  </ListItemIcon>
                  <ListItemText primary={"Countries"} />
                </ListItem>
              </Tooltip>
            </List>
          </Collapse>

          <Tooltip
            placement="left"
            title="Import config"
            disableHoverListener={open}
          >
            <ListItem onClick={(e) => handleClick2(3, e)}>
              <ListItemIcon>
              <CompareArrowsOutlined  color={open2 === 3  ? "secondary" : "primary" }/>
                {open2 === 3 ? (
                  <ExpandLess fontSize="small" />
                ) : (
                  <ExpandMore fontSize="small" />
                )}
              </ListItemIcon>
              <ListItemText primary="Import config" />
            </ListItem>
          </Tooltip>

          <Collapse in={open2 === 3} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <Tooltip
                placement="left"
                
                title="Laboratory identification setting"
                disableHoverListener={open}
              >
                <ListItem
                  onClick={() => handleSubmitMenu("labsettings")}
                  disabled={menu == "Laboratory identification setting"}
                  className={
                    menu == "Laboratory identification setting"
                      ? classes.active
                      : classes.inactive
                  }
                  button
                  key={"text"}
                >
                  <ListItemIcon>
                    <SettingsInputComponent />
                  </ListItemIcon>
                  <ListItemText primary={"Laboratory identification setting"} />
                </ListItem>
              </Tooltip>

              <Tooltip
                placement="left"
                title="Analyte mapping"
                disableHoverListener={open}
              >
                <ListItem
                  onClick={() => handleSubmitMenu("analytemapping")}
                  disabled={menu == "Analyte mapping"}
                  className={
                    menu == "Analyte mapping"
                      ? classes.active
                      : classes.inactive
                  }
                  button
                  key={"text"}
                >
                  <ListItemIcon>
                    <InvertColors />
                  </ListItemIcon>
                  <ListItemText primary={"Analyte mapping"} />
                </ListItem>
              </Tooltip>

              <Tooltip
                placement="left"
                title="Instrument mapping"
                disableHoverListener={open}
              >
                <ListItem
                  onClick={() => handleSubmitMenu("instrumentmapping")}
                  disabled={menu == "Instrument mapping"}
                  className={
                    menu == "Instrument mapping"
                      ? classes.active
                      : classes.inactive
                  }
                  button
                  key={"text"}
                >
                  <ListItemIcon>
                    <DnsOutlined />
                  </ListItemIcon>
                  <ListItemText primary={"Instrument mapping"} />
                </ListItem>
              </Tooltip>

              <Tooltip
                placement="left"
                title="Unit mapping"
                disableHoverListener={open}
              >
                <ListItem
                  onClick={() => handleSubmitMenu("unitmapping")}
                  disabled={menu == "Unit mapping"}
                  className={
                    menu == "Unit mapping" ? classes.active : classes.inactive
                  }
                  button
                  key={"text"}
                >
                  <ListItemIcon>
                    <Speed />
                  </ListItemIcon>
                  <ListItemText primary={"Unit mapping"} />
                </ListItem>
              </Tooltip>

              <Tooltip
                placement="left"
                title="Auto-fill"
                disableHoverListener={open}
              >
                <ListItem
                  onClick={() => handleSubmitMenu("autofill")}
                  disabled={menu == "Auto-fill"}
                  className={
                    menu == "Auto-fill" ? classes.active : classes.inactive
                  }
                  button
                  key={"text"}
                >
                  <ListItemIcon>
                    <CallMergeOutlined />
                  </ListItemIcon>
                  <ListItemText primary={"Auto-fill"} />
                </ListItem>
              </Tooltip>
              <Tooltip
                placement="left"
                title="Comments"
                disableHoverListener={open}
              >
                <ListItem
                  onClick={() => handleSubmitMenu("Comments")}
                  disabled={menu == "Comments"}
                  className={
                    menu == "Comments" ? classes.active : classes.inactive
                  }
                  button
                  key={"text"}
                >
                  <ListItemIcon>
                    <Comment />
                  </ListItemIcon>
                  <ListItemText primary={"Comments"} />
                </ListItem>
              </Tooltip>
              <Tooltip
                placement="left"
                title="Statistics and alerts"
                disableHoverListener={open}
              >
                <ListItem
                  onClick={() => handleSubmitMenu("statalerts")}
                  disabled={menu == "Statistics and alerts"}
                  className={
                    menu == "Statistics and alerts" ? classes.active : classes.inactive
                  }
                  button
                  key={"text"}
                >
                  <ListItemIcon>
                    <WarningOutlined />
                  </ListItemIcon>
                  <ListItemText primary={"Statistics and alerts"} />
                </ListItem>
              </Tooltip>
              {/* <Tooltip
                placement="left"
                title="Auto-fill"
                disableHoverListener={open}
              >
                <ListItem
                  onClick={() => handleSubmitMenu("autofill")}
                  disabled={menu == "Auto-fill"}
                  className={
                    menu == "Auto-fill" ? classes.active : classes.inactive
                  }
                  button
                  key={"text"}
                >
                  <ListItemIcon>
                    <Speed />
                  </ListItemIcon>
                  <ListItemText primary={"Auto-fill"} />
                </ListItem>
              </Tooltip>
              <Tooltip
                placement="left"
                title="Auto-fill"
                disableHoverListener={open}
              >
                <ListItem
                  onClick={() => handleSubmitMenu("autofill")}
                  disabled={menu == "Auto-fill"}
                  className={
                    menu == "Auto-fill" ? classes.active : classes.inactive
                  }
                  button
                  key={"text"}
                >
                  <ListItemIcon>
                    <Speed />
                  </ListItemIcon>
                  <ListItemText primary={"Auto-fill"} />
                </ListItem>
              </Tooltip> */}

            </List>
          </Collapse>

          <Tooltip placement="left" title="Charts" disableHoverListener={open}>
            <ListItem onClick={(e) => handleClick2(4, e)}>
              <ListItemIcon>
                <PieChartOutlined color={open2 === 4  ? "secondary" : "primary" } fontSize="small" />
                {open2 === 4 ? (
                  <ExpandLess fontSize="small" />
                ) : (
                  <ExpandMore fontSize="small" />
                )}
              </ListItemIcon>
              <ListItemText primary="Charts" />
            </ListItem>
          </Tooltip>

          <Collapse in={open2 === 4} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <Tooltip
                placement="left"
                title="Demo data"
                disableHoverListener={open}
              >
                <ListItem
                  onClick={() => handleSubmitMenu("demodata")}
                  disabled={menu == "Demo data"}
                  className={
                    menu == "Demo data" ? classes.active : classes.inactive
                  }
                  button
                  key={"text"}
                >
                  <ListItemIcon>
                    <ShowChartOutlined />
                  </ListItemIcon>
                  <ListItemText primary={"Demo data"} />
                </ListItem>
              </Tooltip>

              <Tooltip
                placement="left"
                title="Demo data 2"
                disableHoverListener={open}
              >
                <ListItem
                  onClick={() => handleSubmitMenu("demodata2")}
                  disabled={menu == "Demo data 2"}
                  className={
                    menu == "Demo data 2" ? classes.active : classes.inactive
                  }
                  button
                  key={"text"}
                >
                  <ListItemIcon>
                    <BarChartTwoTone />
                  </ListItemIcon>
                  <ListItemText primary={"Demo data 2"} />
                </ListItem>
              </Tooltip>

              <Tooltip
                placement="left"
                title="Monthly/weekly median values"
                disableHoverListener={open}
              >
                <ListItem
                  onClick={() => handleSubmitMenu("demodata3")}
                  disabled={menu == "Demo data 3"}
                  className={
                    menu == "Demo data 3" ? classes.active : classes.inactive
                  }
                  button
                  key={"text"}
                >
                  <ListItemIcon>
                    <TimelineOutlined />
                  </ListItemIcon>
                  <ListItemText primary={"Demo data 3"} />
                </ListItem>
              </Tooltip>

              <Tooltip
                placement="left"
                title="Distribution"
                disableHoverListener={open}
              >
                <ListItem
                  onClick={() => handleSubmitMenu("demodata4")}
                  disabled={menu == "Demo data 4"}
                  className={
                    menu == "Demo data 4" ? classes.active : classes.inactive
                  }
                  button
                  key={"text"}
                >
                  <ListItemIcon>
                    <TimelapseOutlined />
                  </ListItemIcon>
                  <ListItemText primary={"Demo data 4"} />
                </ListItem>
              </Tooltip>
            </List>
          </Collapse>
          {/* <ListItem onClick={(e) => handleClick2(1, e)}>
            <ListItemIcon>
              <DragIndicator fontSize="small" />
              {open2 === 1 ? (
                <ExpandLess fontSize="small" />
              ) : (
                <ExpandMore fontSize="small" />
              )}
            </ListItemIcon>
            <ListItemText primary="Inbox" />
          </ListItem>
          <Collapse in={open2 === 1} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem sx={{ pl: 4 }}>
                <ListItemIcon>
                  <StarBorder />
                </ListItemIcon>
                <ListItemText primary="Starred" />
              </ListItem>
            </List>
          </Collapse>

          <ListItem onClick={(e) => handleClick2(2, e)}>
            <ListItemIcon>
              <Public fontSize="small" />
              {open2 === 2 ? (
                <ExpandLess fontSize="small" />
              ) : (
                <ExpandMore fontSize="small" />
              )}
            </ListItemIcon>
            <ListItemText primary="Inbox" />
          </ListItem>
          <Collapse in={open2 === 2} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem sx={{ pl: 4 }}>
                <ListItemIcon>
                  <StarBorder />
                </ListItemIcon>
                <ListItemText primary="Starred" />
              </ListItem>
            </List>
          </Collapse> */}
        </List>
        <Divider />
      </Drawer>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    authStatus: state.firebase.auth,
    profile: state.firebase.profile,
    menu: state.questionnaire.menu,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    signOut: () => dispatch(signOut()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
