import React from "react";
import { connect } from "react-redux";
import { useFirestoreConnect } from "react-redux-firebase";
import { Redirect } from "react-router-dom";
import {
  addAnalytemapping,
  deleteAnalytemapping,
  updateAnalytemapping,
} from "../../../store/actions/analytemappingActions";
import Container from "@material-ui/core/Container";
import useStyles from "../dashboard/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../store/actions/actionTypes";
import FSPTable from "../../common/FSPTable/FSPTable";
import convertArrayByField from "../../common/utils";

const Analytemappings = ({
  analytemappings,
  analytes,
  methods,
  samplematerials,
  labs,
  authStatus,
  addAnalytemapping,
  updateAnalytemapping,
  deleteAnalytemapping,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { isEmpty, isLoaded } = authStatus;
  console.log('LAB', labs);
  let analytesArray = convertArrayByField(analytes, "id");
  let labsArray = convertArrayByField(labs, "id");
  let methodsArray = convertArrayByField(methods, "id");
  let samplematerialsArray = convertArrayByField(samplematerials, "id");
  const form = {
    titles: {
      index: "Analyte mapping",
      create: "Add analyte mapping",
      update: "Update analyte mapping",
    },
    keyField: 0,
    indexFields: [ 0, 1, 2],
    fields: [
      // {
      //   id: 0,
      //   name: "id",
      //   required: false,
      //   // hidden: true,
      //   helper: "ID",
      //   q2: "ID",
      //   label: "ID",
      //   type: 2,
      // },
      {
        id: 0,
        required: true,
        name: "lab",
        helper: "Lab",
        q2: "Lab",
        label: "Lab",
        type: 3,
        values: labs,
        valuesArray: labsArray,
        values_label: "name",
        values_key: "id",
      },
      {
        id: 1,
        required: true,
        name: "source",
        helper: "Analyte code (as reported by the lab.) ",
        q2: "Analyte code (as reported by the lab.) ",
        label: "Analyte code (as reported by the lab.) ",
        type: 2,
      },
      {
        id: 2,
        required: true,
        name: "target",
        helper: "Analyte code (as defined in the program)",
        q2: "Analyte code (as defined in the program)",
        label: "Analyte code (as defined in the program)",
        type: 3,
        values: analytes,
        valuesArray: analytesArray,
        values_label: "name",
        values_key: "id",
      },
      {
        id: 3,
        required: false,
        name: "method",
        helper: "Method",
        q2: "Method",
        label: "Method",
        type: 3,
        values: methods,
        valuesArray: methodsArray,
        values_label: "name",
        values_key: "id",
      },
      {
        id: 4,
        required: false,
        name: "samplematerials",
        helper: "Sample material",
        q2: "Sample material",
        label: "Sample material",
        type: 3,
        values: samplematerials,
        valuesArray: samplematerialsArray,
        values_label: "name",
        values_key: "id",
      },
      {
        id: 5,
        required: true,
        name: "a",
        helper: "A factor (ax+b)",
        q2: "A factor (ax+b)",
        label: "A factor (ax+b)",
        type: 2,
      },
      {
        id: 6,
        required: true,
        name: "b",
        helper: "B factor (ax+b)",
        q2: "B factor (ax+b)",
        label: "B factor (ax+b)",
        type: 2,
      },
      {
        id:7,
        required: true,
        name: "options",
        helper: "Options",
        q2: "Options",
        // label: 'Validation start date',
        type: "JSONTable",
        form: {
          titles: {
            index: "Additional information",
            create: "Add information",
            update: "Update information",
          },
          keyField: 0,
          indexFields: [0, 1],
          fields: [
            {
              id: 0,
              required: true,
              name: "id",
              helper: "Position",
              q2: "Position",
              label: "Position",
              type: 2,
            },
            {
              id: 1,
              required: true,
              name: "name",
              helper: "Option",
              q2: "Option",
              label: "Option",
              type: 2,
            },
          ],
        },
      },
    
    ],
  };
  useFirestoreConnect([
    {
      collection: "analytemappings",
      storeAs: "analytemappings",
      orderBy: ["createdAt", "asc"],
    },
    {
      collection: "analytes",
      storeAs: "analytes",
      orderBy: ["db_id", "asc"],
    },
    {
      collection: "labs",
      storeAs: "labs",
      orderBy: ["om", "asc"],
    },
    {
      collection: "methods",
      storeAs: "methods",
      orderBy: ["om", "asc"],
    },
    {
      collection: "samplematerials",
      storeAs: 'samplematerials',
      orderBy: ['db_id', 'asc']
  }
  ]);
  const createElement = (newMeasure) => {
    if (typeof newMeasure.id === "undefined") {
      addAnalytemapping(newMeasure);
    } else {
      updateAnalytemapping(newMeasure, newMeasure.id);
    }
  };

  const deleteElement = (newMeasure) => {
    deleteAnalytemapping(newMeasure);
  };

  dispatch({ type: actions.SET_MENU, payload: "Analyte mapping" });

  return (
    <Container className={classes.content}>
      <div>
        {isLoaded &&
          (isEmpty ? (
            <Redirect to="/signin" />
          ) : analytemappings ? (
            <FSPTable
              model={analytemappings}
              classes={classes}
              createElement={(measure) => createElement(measure)}
              deleteElement={(measure) => deleteElement(measure)}
              form={form}
              meta={{ analytes: analytes }}
            />
          ) : (
            <CircularProgress />
          ))}
      </div>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    analytemappings: state.firestore.ordered.analytemappings,
    methods: state.firestore.ordered.methods,
    samplematerials: state.firestore.ordered.samplematerials,
    analytes: state.firestore.ordered.analytes,
    labs: state.firestore.ordered.labs,
    authStatus: state.firebase.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addAnalytemapping: (analytemapping) =>
      dispatch(addAnalytemapping(analytemapping)),
    deleteAnalytemapping: (id) => dispatch(deleteAnalytemapping(id)),
    updateAnalytemapping: (analytemapping, id) =>
      dispatch(updateAnalytemapping(analytemapping, id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Analytemappings);
