

import { tsvParse, csvParse } from  "d3-dsv";
import { timeParse } from "d3-time-format";

function parseData(parse) {
	return function(d) {
        console.log(d);
		// d.date = parse(d.date);
		// d.open = +d.open;
		// d.high = +d.high;
		// d.low = +d.low;
		// d.close = +d.close;
		// d.volume = +d.volume;

		return d;
	};
}

const parseDate = timeParse("%Y-%m-%d");

export function getData() {
    console.log('sss');
	const promiseMSFT = fetch("http://localhost/iqapi_psql/index-test.php?r=site/histogram")
		.then(response => response.text())
		.then(data => data.json())
	return promiseMSFT;
}
