import React from 'react';
import { connect } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import { Redirect } from 'react-router-dom';
import { addCountry, deleteCountry, updateCountry } from '../../../store/actions/countryActions';
import Container from '@material-ui/core/Container';
import useStyles from "../dashboard/styles";
import CircularProgress from '@material-ui/core/CircularProgress';
import { useDispatch, useSelector } from 'react-redux'
import * as actions from '../../../store/actions/actionTypes';
import FSPTable from '../../common/FSPTable/FSPTable';

const Countries = ({ countries, authStatus, addCountry, updateCountry, deleteCountry }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { isEmpty, isLoaded } = authStatus;
    const form = {
        titles: {
            index: 'Countries',
            create: 'New country',
            update: 'Update country',
        },
        keyField: 0,
        indexFields: [0, 1],
        fields: [
            {
                id: 0,
                name: 'om',
                required: true,
                helper: 'ID',
                q2: 'ID',
                label: 'ID',
                type: 2,
            },
            {
                id: 1,
                required: true,
                name: 'name',
                helper: 'Country',
                q2: 'Country',
                label: 'Country',
                type: 2,
            }
        ]
    };
    useFirestoreConnect([
        {
            collection: "countries",
            storeAs: 'countries',
            orderBy: ['om', 'asc']
        }
    ]);
    const createElement = (newMeasure) => {
        if (typeof newMeasure.id === 'undefined') {
            addCountry(newMeasure);
        } else {
            updateCountry(newMeasure, newMeasure.id)
        }
    }

    const deleteElement = (newMeasure) => {
        deleteCountry(newMeasure);
    }

    dispatch({ type: actions.SET_MENU, payload: 'Countries' })

    return (
        <Container className={classes.content}>
            <div>
                {isLoaded &&
                    (isEmpty ? (
                        <Redirect to='/signin' />
                    ) : (
                        countries ?
                            <FSPTable
                                model={countries}
                                classes={classes}
                                createElement={(measure) => createElement(measure)}
                                deleteElement={(measure) => deleteElement(measure)}
                                form={form}
                            /> :
                            <CircularProgress />
                    ))}
            </div>
        </Container>
    );
};

const mapStateToProps = (state) => {
    return {
        countries: state.firestore.ordered.countries,
        authStatus: state.firebase.auth,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addCountry: (country) => dispatch(addCountry(country)),
        deleteCountry: (id) => dispatch(deleteCountry(id)),
        updateCountry: (country, id) => dispatch(updateCountry(country, id)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Countries);
