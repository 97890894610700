import React, { Component } from "react";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle'
import { MenuItem, TextField } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import IQCHeader from "./IQCHeader";
class IQCForm extends Component {
  render() {
    const { classes } = this.props;
    return (
        <Dialog
        fullWidth={true}
        classes={{ paper: classes.csvdialog }}
        open={true}
        // onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        
        <IQCHeader
            classes={classes}
            title="Laboratory 1"
            subtitle="Department 1"
            switchtitle="Subscribe to all notification"
          />

       
        <DialogContent style={{ minHeight: '250px' }}>
            <form>
                <div>
                    sdsd
                </div>
            </form>
        </DialogContent>
        <DialogActions>
            <Button
                onClick={() => {
                    this.props.handleStop();
                }}
                color="primary"
                autoFocus
            >
                Cancel
            </Button>
            <Button
                onClick={() => {
                    this.props.handleFormUpdate(this.props.val)
                }}
                color="primary"
                variant="contained"
                autoFocus
            >
                Save
            </Button>
        </DialogActions>
    </Dialog>
    );
  }
}

export default IQCForm;
