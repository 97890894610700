import React from "react";
import Container from "@material-ui/core/Container";
import useStyles from "../dashboard/styles";
import Demodata3 from "./demodata3";

const Postgresql = () => {
  const classes = useStyles();

  return (
    <Container className={classes.content}>
      <div>
        <Demodata3 classes={classes} />
      </div>
    </Container>
  );
};

export default Postgresql;
