import firebase from 'firebase/app';
import * as actions from './actionTypes';

export const addAutofill = (autofill) => async (dispatch, getState) => {
    console.log('bbbb', autofill);
  try {
    dispatch({ type: actions.ADD_TODO_START });
    const uid = getState().firebase.auth.uid;
    const firestore = firebase.firestore();
    await firestore.collection('autofills').add({
      ...autofill,
      authorId: uid,
      createdAt: new Date(),
      updatedAt: new Date(),
    });
    dispatch({ type: actions.ADD_TODO_SUCESS });
  } catch (err) {
    dispatch({ type: actions.ADD_TODO_FAIL, err });
  }
};

export const updateAutofill = (autofill, id) => async (dispatch) => {
    console.log('bbbb', autofill);
  try {
    dispatch({ type: actions.UPDATE_TODO_START });
    const firestore = firebase.firestore();
    await firestore.collection('autofills').doc(id).update({
      // db_id: autofill.db_id,
      source: autofill.source,
      target: autofill.target,
      lab: autofill.lab,
      updatedAt: new Date(),
    });
    dispatch({ type: actions.UPDATE_TODO_SUCESS });
  } catch (err) {
    dispatch({ type: actions.UPDATE_TODO_FAIL, err });
  }
};

export const deleteAutofill = (id) => async (dispatch) => {
    console.log('bbbb', id);
  try {
    dispatch({ type: actions.DELETE_TODO_START });
    const firestore = firebase.firestore();
    await firestore.collection('autofills').doc(id).delete();
    dispatch({ type: actions.DELETE_TODO_SUCESS });
  } catch (err) {
    dispatch({ type: actions.DELETE_TODO_FAIL, err });
  }
};
