import React, { Component } from "react";
import { getData } from "./utils4";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { MenuItem, TextField } from "@material-ui/core";
import { trackPromise } from 'react-promise-tracker';

class Demodata4 extends Component {
  constructor(props) {
    super();
    this.handleChangeShow = this.handleChangeShow.bind(this);
    this.handleChangeShow2 = this.handleChangeShow2.bind(this);
    this.state = {
      data: [],
      device: "Demo 1",
      parameter: "Chloride",
    };
  }

  handleChangeShow(event, newAlignment) {
    console.log(event.target);
    console.log(newAlignment);
    this.setState({
      device: event.target.value,
    });
    this.refresh(event.target.value);
  }

  handleChangeShow2(event, newAlignment) {
    console.log(event.target);
    console.log(newAlignment);
    this.setState({
      parameter: event.target.value,
    });
    this.refresh(this.state.device,event.target.value);
  }

  refresh(device = this.state.device, parameter = this.state.parameter) {
    // var url = new URL("http://localhost/iqapi_psql/index-test.php");
    var url = new URL("https://triple-acre-333913.ew.r.appspot.com/distribution");
    var url = new URL("http://localhost:8080/distribution");
    var params = [
      // ["r", "site/histogram"],
      ["parameter", parameter],
      ["device", device],
    ];

    url.search = new URLSearchParams(params).toString();
    trackPromise(
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        this.setState({ data: data });
      })
    );
  }
  componentDidMount() {
    this.refresh();
  }
  render() {
    console.log(this.state);
    return (
      <div>
        <h1>Distribution</h1>
        Device:
        <TextField
          required
          id={"distribution2"}
          value={this.state["device"]}
          name={"distribution2"}
          select
          className={this.props.classes.selectfilter}
          // label={"Laboratory"}
          onChange={this.handleChangeShow}
          InputProps={{ disableUnderline: true }}
          InputProps={{
            classes: {
              input: this.props.classes.statSelect,
            },
          }}

          //  helperText={"Select lab"}
        >
          <MenuItem key={1} value={"Demo 1"}>
            Demo 1
          </MenuItem>
          <MenuItem key={2} value={"Demo 2"}>
            Demo 2
          </MenuItem>
          <MenuItem key={3} value={"dev 1"}>
            Device 1
          </MenuItem>
        </TextField>
        Analyte:
        <TextField
          required
          id={"distribution2"}
          value={this.state["parameter"]}
          name={"distribution2"}
          select
          className={this.props.classes.selectfilter}
          // label={"Laboratory"}
          onChange={this.handleChangeShow2}
          InputProps={{ disableUnderline: true }}
          InputProps={{
            classes: {
              input: this.props.classes.statSelect,
            },
          }}

          //  helperText={"Select lab"}
        >
          <MenuItem key={1} value={"Chloride"}>
            Chloride
          </MenuItem>
          <MenuItem key={2} value={"FT4"}>
            FT4
          </MenuItem>
          <MenuItem key={3} value={"Calcium"}>
            Calcium
          </MenuItem>
        </TextField>
        <BarChart
          width={800}
          height={300}
          data={this.state.data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="range" />
          <YAxis type="number" />
          <Tooltip />
          <Legend />
          <Bar dataKey="freq" fill="#82ca9d" />
        </BarChart>
      </div>
    );
  }
}

export default Demodata4;
