import React from "react";
import { MenuItem, TextField } from "@material-ui/core";
import Multiselect from "multiselect-react-dropdown";
import zIndex from "@material-ui/core/styles/zIndex";
import Button from "@material-ui/core/Button";
import JSONTable from "../JSONTable/JSONTable";

const FSPTableFormField = (params) => {
  const { field, handleChange, meta, handleChangeJSON, handleChangeArray, val, errors, classes } =
    params;
  console.log("field....", field);
  console.log("field....", val);
  const onSelect = (event) => {
    let a = event.map((i) => i.id);
    handleChangeArray(field.name, a);
  };
  const onRemove = (event) => {
    let a = event.map((i) => i.id);
    handleChangeArray(field.name, a);
  };

  return (
    <div>
      {/* {field.type == 1 &&
                <div>
                    {field.type}
                    {field.q2}
                    <TextField id="standard-basic" label="Standard" />
                </div>
            } */}
      {field.type == 3 && (
        <div>
          <div>{field.q2}</div>
          <TextField
            required={field.required ? true : false}
            fullWidth
            id={field.name}
            name={field.name}
            label={field.label}
            select
            value={val[field.name]}
            onChange={handleChange}
            // helperText={field.helper}
          >
            {field.values.map((option) => (
              <MenuItem
                key={option[field.values_key]}
                value={option[field.values_key]}
              >
                {option[field.values_label]}
              </MenuItem>
            ))}
          </TextField>
        </div>
      )}
      {field.type == "boolean" && (
        <div>
          <div>{field.q2}</div>
          <TextField
            required
            fullWidth
            id={field.name}
            name={field.name}
            label={field.label}
            select
            value={val[field.name] ? "Yes" : "No"}
            onChange={handleChange}
            // helperText={field.helper}
          >
            {field.values.map((option) => (
              <MenuItem
                key={option[field.values_key]}
                value={option[field.values_key]}
              >
                {option[field.values_label]}
              </MenuItem>
            ))}
          </TextField>
        </div>
      )}
      {field.type == 2 && (
        <div>
          <div>{field.q2}</div>

          <TextField
            required={field.required ? true : false}
            id={field.name}
            name={field.name}
            label={field.label}
            onChange={handleChange}
            style={{
              backgroundColor: field.suggestion
                ? field.suggestion === "delete"
                  ? "#ffdddd"
                  : field.suggestion === "add"
                  ? "#ddffdd"
                  : "#ffffff"
                : "#ffffff",
            }}
            fullWidth
            value={val[field.name]}
          />
        </div>
      )}
      {field.type == 4 && (
        <div>
          <div>{field.q2}</div>

          <TextField
            required={field.required ? true : false}
            id={field.name}
            name={field.name}
            type="number"
            inputProps={{ min: field.min, max: field.max, step: "1" }}
            label={field.label}
            onChange={handleChange}
            value={val[field.name]}
            // ref={ref.inputRef}
          />
        </div>
      )}
      {field.type == 5 && (
        <div>
          <div>{field.q2}</div>
          <Multiselect
            id={"persid" + field.id}
            name={field.id}
            placeholder={field.q2}
            style={{ zIndex: "999999" }}
            options={meta[field.model]} // Options to display in the dropdown
            // selectedValues={val[field.name]}
            selectedValues={
              val[field.name]
                ? meta[field.model].filter(function (item, index) {
                    return val[field.name].indexOf(item.id) > -1;
                  })
                : []
            }
            // {val[field.id]} // Preselected value to persist in dropdown
            onSelect={onSelect} // Function will trigger on select event
            onRemove={onRemove} // Function will trigger on remove event
            displayValue="name" // Property name to display in the dropdown options
            // isObject={false}
          />
        </div>
      )}
      {field.type == 6 && (
        <div>
          <div>{field.q2}</div>

          <TextField
            required={field.required ? true : false}
            id={field.name}
            name={field.name}
            type="date"
            format="MM/dd/yyyy"
            inputProps={{ min: field.min, max: field.max, step: "1" }}
            label={field.label}
            onChange={handleChange}
            value={val[field.name]}
            // ref={ref.inputRef}
          />
        </div>
      )}
      {field.type == 'list' && (
        <div>
          <Button
            onClick={() => {
              // this.props.handleDeleteCancel();
            }}
            color="primary"
            // autoFocus
          >
            {field.q2}
          </Button>
        </div>
      )}
      {errors.length > 0 && field.required && (
        <span className={classes.error}>{errors}</span>
      )}
      {field.type == 'JSONTable' && (
        <div>
          <JSONTable 
           model={[]}
           classes={classes}
          //  createElement={(measure) => createElement(measure)}
          //  deleteElement={(measure) => deleteElement(measure)}
           value={val[field.name]}
           onChange={handleChangeJSON}
           form={field.form}
           field={field}
           meta={meta}
          />
        </div>
      )}
      {errors.length > 0 && field.required && (
        <span className={classes.error}>{errors}</span>
      )}
    </div>
  );
};

export default FSPTableFormField;
