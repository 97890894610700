import { FormControlLabel, Switch } from '@material-ui/core';
import React, { Component } from 'react';

class IQCRowFloatSwitch extends Component {
    render() {
        let a =this.props.dedefaultCheckedfault ? "defaultChecked" :"";
        return (
            <li
              class="jss324 jss661 jss664 jss669 jss670 jss637"
              tabindex="0"
              role="button"
              id="7504"
              group="2020"
              ordinal="11"
            >
                <FormControlLabel
                  value="start"
                  control={<Switch defaultChecked={this.props.isChecked} color="primary" />}
                  // label="Has missing results in current surveys"
                  labelPlacement="start"
                />
              <div class="jss674 jss645">
                <span class="jss518 jss536 jss677 jss638">
                {this.props.title}
                </span>
                <p class="jss518 jss526 jss551 jss678">{this.props.subtitle}.</p>
              </div>

            </li>
        );
    }
}

export default IQCRowFloatSwitch;