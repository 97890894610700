import firebase from "firebase/app";
import * as actions from "./actionTypes";

export const addLab = (lab) => async (dispatch, getState) => {
  console.log("bbbb", lab);
  try {
    dispatch({ type: actions.ADD_TODO_START });
    const uid = getState().firebase.auth.uid;
    const firestore = firebase.firestore();
    await firestore.collection("labs").add({
      ...lab,
      authorId: uid,
      createdAt: new Date(),
      updatedAt: new Date(),
    });
    dispatch({ type: actions.ADD_TODO_SUCESS });
  } catch (err) {
    dispatch({ type: actions.ADD_TODO_FAIL, err });
  }
};

export const updateLab = (lab, id) => async (dispatch) => {
  console.log("bbbb", lab);
  try {
    dispatch({ type: actions.UPDATE_TODO_START });
    const firestore = firebase.firestore();
    await firestore.collection("labs").doc(id).update({
      om: lab.om,
      name: lab.name,
      email: lab.email,
      code: lab.code,
      department: lab.department,
      section: lab.section,
      contactperson: lab.contactperson,
      contactpersonextra: lab.contactpersonextra,
      emailcontactperson: lab.emailcontactperson,
      country: lab.country,
      active: lab.active,
      emailcontactpersonextra: lab.emailcontactpersonextra,
      updatedAt: new Date(),
      population:lab.population,
      is_single:lab.is_single,
      days_before_reporting:lab.days_before_reporting,
    });
    dispatch({ type: actions.UPDATE_TODO_SUCESS });
  } catch (err) {
    dispatch({ type: actions.UPDATE_TODO_FAIL, err });
  }
};

export const deleteLab = (id) => async (dispatch) => {
  console.log("bbbb", id);
  try {
    dispatch({ type: actions.DELETE_TODO_START });
    const firestore = firebase.firestore();
    await firestore.collection("labs").doc(id).delete();
    dispatch({ type: actions.DELETE_TODO_SUCESS });
  } catch (err) {
    dispatch({ type: actions.DELETE_TODO_FAIL, err });
  }
};
