import firebase from "firebase/app";
import * as actions from "./actionTypes";

export const addModel = (manufacturer) => async (dispatch, getState) => {
  console.log("bbbb", manufacturer);
  try {
    dispatch({ type: actions.ADD_TODO_START });
    const uid = getState().firebase.auth.uid;
    const firestore = firebase.firestore();
    await firestore.collection("models").add({
      ...manufacturer,
      authorId: uid,
      createdAt: new Date(),
      updatedAt: new Date(),
    });
    dispatch({ type: actions.ADD_TODO_SUCESS });
  } catch (err) {
    dispatch({ type: actions.ADD_TODO_FAIL, err });
  }
};

export const updateModel = (manufacturer, id) => async (dispatch) => {
  console.log("bbbb", manufacturer);
  try {
    dispatch({ type: actions.UPDATE_TODO_START });
    const firestore = firebase.firestore();
    await firestore
      .collection("models")
      .doc(id)
      .update({
        db_id: manufacturer.db_id,
        name: manufacturer.name,
        separate: manufacturer.separate,
        active: manufacturer.active,
        manufacturer: manufacturer.manufacturer,
        updatedAt: new Date(),
      });
    dispatch({ type: actions.UPDATE_TODO_SUCESS });
  } catch (err) {
    dispatch({ type: actions.UPDATE_TODO_FAIL, err });
  }
};

export const deleteModel = (id) => async (dispatch) => {
  console.log("bbbb", id);
  try {
    dispatch({ type: actions.DELETE_TODO_START });
    const firestore = firebase.firestore();
    await firestore.collection("models").doc(id).delete();
    dispatch({ type: actions.DELETE_TODO_SUCESS });
  } catch (err) {
    dispatch({ type: actions.DELETE_TODO_FAIL, err });
  }
};
