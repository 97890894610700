import React from "react";
import { connect } from "react-redux";
import { useFirestoreConnect } from "react-redux-firebase";
import { Redirect } from "react-router-dom";
import {
  addInstrumenttype,
  deleteInstrumenttype,
  updateInstrumenttype,
} from "../../../store/actions/instrumenttypeActions";
import Container from "@material-ui/core/Container";
import useStyles from "../dashboard/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../store/actions/actionTypes";
import FSPTable from "../../common/FSPTable/FSPTable";
import convertArrayByField from "../../common/utils";

const Instrumenttypes = ({
  instrumenttypes,
  models,
  authStatus,
  addInstrumenttype,
  updateInstrumenttype,
  deleteInstrumenttype,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { isEmpty, isLoaded } = authStatus;
  let modelsArray = convertArrayByField(models, "id");
  console.log("models", models);
  console.log("models2", modelsArray);
  const form = {
    titles: {
      index: "Instrument types",
      create: "Add instrument type",
      update: "Update instrument type",
    },
    keyField: 0,
    indexFields: [0, 1, 2, 3, 4],
    fields: [
      {
        id: 0,
        name: "db_id",
        required: true,
        helper: "ID",
        q2: "ID",
        label: "ID",
        type: 2,
      },
      {
        id: 1,
        required: true,
        name: "name",
        helper: "Instrument type name",
        q2: "Instrument type name",
        label: "Instrument type name",
        type: 2,
      },
      {
        id: 2,
        required: true,
        name: "model",
        helper: "Model",
        q2: "Model",
        label: "Model",
        type: 3,
        values: models,
        valuesArray: modelsArray,
        values_label: "name",
        values_key: "id",
      },
      {
        id: 3,
        required: true,
        name: "separate",
        helper: "Separate instrument model group",
        q2: "Separate instrument model group",
        label: "Separate instrument model group",
        type: 3,
        values: [
          { id: "false", name: "No" },
          { id: "true", name: "Yes" },
        ],
        valuesArray: {
          true: [{ id: "true", name: "Yes" }],
          false: [{ id: "false", name: "No" }],
        },
        values_label: "name",
        values_key: "id",
        // model:'manufacturers',
        // modelId: 'db_id',
        // modelLabel:'name'
      },
      {
        id: 4,
        required: true,
        name: "active",
        helper: "Active",
        q2: "Active",
        label: "Active",
        type: 3,
        values: [
          { id: "false", name: "Inactive" },
          { id: "true", name: "Active" },
        ],
        valuesArray: {
          false: [{ id: "false", name: "Inactive" }],
          true: [{ id: "true", name: "Active" }],
        },
        values_label: "name",
        values_key: "id",
        // model:'manufacturers',
        // modelId: 'db_id',
        // modelLabel:'name'
      },
    ],
  };
  useFirestoreConnect([
    {
      collection: "instrumenttypes",
      storeAs: "instrumenttypes",
      orderBy: ['db_id', 'asc']
    },
    {
      collection: "models",
      storeAs: "models",
      orderBy: ['name', 'asc']
    },
  ]);
  const createElement = (newMeasure) => {
    if (typeof newMeasure.id === "undefined") {
      addInstrumenttype(newMeasure);
    } else {
      updateInstrumenttype(newMeasure, newMeasure.id);
    }
  };

  const deleteElement = (newMeasure) => {
    deleteInstrumenttype(newMeasure);
  };

  dispatch({ type: actions.SET_MENU, payload: "Instrument types" });

  return (
    <Container className={classes.content}>
      <div>
        {isLoaded &&
          (isEmpty ? (
            <Redirect to="/signin" />
          ) : instrumenttypes ? (
            <FSPTable
              model={instrumenttypes}
              classes={classes}
              createElement={(measure) => createElement(measure)}
              deleteElement={(measure) => deleteElement(measure)}
              form={form}
              meta={{ models: models }}
              loading={models ? models.length < 2 : true}
            />
          ) : (
            <CircularProgress />
          ))}
      </div>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    instrumenttypes: state.firestore.ordered.instrumenttypes,
    models: state.firestore.ordered.models,
    authStatus: state.firebase.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addInstrumenttype: (instrumenttype) =>
      dispatch(addInstrumenttype(instrumenttype)),
    deleteInstrumenttype: (id) => dispatch(deleteInstrumenttype(id)),
    updateInstrumenttype: (instrumenttype, id) =>
      dispatch(updateInstrumenttype(instrumenttype, id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Instrumenttypes);
