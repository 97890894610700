import React, { Component } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

class FSPTableHeader extends Component {
    render() {
        const { form } = this.props;
        return (
            <TableHead>
                <TableRow>
                    {form && form.indexFields && form.indexFields.map((element, index) => (
                        <TableCell component="th" scope="row">
                            {form.fields[element] ? form.fields[element].label: '...'}
                        </TableCell>
                    ))
                    }
                    {/* <TableCell style={{ maxWidth: '200px', padding: '4px' }} component="th" scope="row">
                        OM
                    </TableCell>
                    <TableCell component="th" scope="row">
                        Név
                    </TableCell> */}
                    <TableCell component="th" scope="row">
                        Operations
                    </TableCell>
                </TableRow>
            </TableHead>
        );
    }
}

export default FSPTableHeader;