import React from "react";
import { connect } from "react-redux";
import { useFirestoreConnect } from "react-redux-firebase";
import { Redirect } from "react-router-dom";
import {
  addDemodata,
  deleteDemodata,
  updateDemodata,
} from "../../../store/actions/demodataActions";
import Container from "@material-ui/core/Container";
import useStyles from "../dashboard/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../store/actions/actionTypes";
import FSPTable from "../../common/FSPTable/FSPTable";
import convertArrayByField from "../../common/utils";
import { data, meta } from "./data";
import StatisticsChartStock from "./StatisticsChartStock";

function Welcome(props) {
  return <h1>Firebase- {props.name}</h1>;
}
const ChildComponent = (props) => {
  return <h1>{props.title}</h1>;
};

const Demodata = ({
  demodata,
  manufacturers,
  authStatus,
  addDemodata,
  updateDemodata,
  deleteDemodata,
}) => {
  console.log("data", data);
  const classes = useStyles();
  const dispatch = useDispatch();
  const { isEmpty, isLoaded } = authStatus;
  let manufacturersArray = convertArrayByField(manufacturers, "id");
  console.log("manu", manufacturers);
  console.log("manu", manufacturersArray);
  const form = {
    titles: {
      index: "Demo data",
      create: "Add demo data",
      update: "Update demo data",
    },
    keyField: 0,
    indexFields: [0, 1],
    fields: [
      {
        id: 0,
        name: "db_id",
        required: true,
        helper: "ID",
        q2: "ID",
        label: "ID",
        type: 2,
      },
      {
        id: 1,
        required: true,
        name: "name",
        helper: "Demo data name",
        q2: "Demo data name",
        label: "Demo data name",
        type: 2,
      },
      //   {
      //     id: 2,
      //     required: true,
      //     name: "manufacturer",
      //     helper: "Manufacturer",
      //     q2: "Manufacturer",
      //     label: "Manufacturer",
      //     type: 3,
      //     values: manufacturers,
      //     valuesArray: manufacturersArray,
      //     values_label: "name",
      //     values_key: "id",
      //     // demodata:'manufacturers',
      //     // demodataId: 'db_id',
      //     // demodataLabel:'name'
      //   },
      //   {
      //     id: 3,
      //     required: true,
      //     name: "separate",
      //     helper: "Demo data",
      //     q2: "Demo data",
      //     label: "Demo data",
      //     type: 3,
      //     values: [
      //       { id: "false", name: "No" },
      //       { id: "true", name: "Yes" },
      //     ],
      //     valuesArray: {
      //       true: [{ id: "true", name: "Yes" }],
      //       false: [{ id: "false", name: "No" }],
      //     },
      //     values_label: "name",
      //     values_key: "id",
      //     // demodata:'manufacturers',
      //     // demodataId: 'db_id',
      //     // demodataLabel:'name'
      //   },
      //   {
      //     id: 4,
      //     required: true,
      //     name: "active",
      //     helper: "Active",
      //     q2: "Active",
      //     label: "Active",
      //     type: 3,
      //     values: [
      //         { id: "false", name: "Inactive" },
      //         { id: "true", name: "Active" },
      //       ],
      //       valuesArray: {
      //         false: [{ id: "false", name: "Inactive" }],
      //         true: [{ id: "true", name: "Active" }]
      //       },
      //     values_label: "name",
      //     values_key: "id",
      //     // demodata:'manufacturers',
      //     // demodataId: 'db_id',
      //     // demodataLabel:'name'
      //   },
    ],
  };
  useFirestoreConnect([
    {
      collection: "demodata",
      storeAs: "demodata",
      orderBy: ["db_id", "asc"],
    },
    {
      collection: "manufacturers",
      storeAs: "manufacturers",
      orderBy: ["name", "asc"],
    },
  ]);
  const createElement = (newMeasure) => {
    if (typeof newMeasure.id === "undefined") {
      addDemodata({ ...newMeasure, values: AnalyteMonth });
    } else {
      updateDemodata({ ...newMeasure, values: AnalyteMonth }, newMeasure.id);
    }
  };

  const deleteElement = (newMeasure) => {
    deleteDemodata(newMeasure);
  };

  dispatch({ type: actions.SET_MENU, payload: "Instrument demodata" });
  let AnalyteMonth = [];
  let date1 = new Date(2009, 12, 1);
  let dateTo = new Date(2010, 1, 1);
  let date2 = new Date(date1);
  for (let i = 0; i < 3650; i++) {
    let month = date2.getMonth() + 1;
    let year = date2.getYear() + 1900;
    let month2 =('0'+ month).substr(-2,2);
    for (let inst = 0; inst < 200; inst++) {
      if (typeof AnalyteMonth[year + "-" + month2] === "undefined") {
        AnalyteMonth[year + "-" + month2] = [];
        // does not exist
      }
      let v = Math.floor(Math.random() * 261);
      let m = Math.floor(Math.random() * 10);
      // console.log(data[v]['result']+m+inst );
      AnalyteMonth[year + "-" + month2].push({
        date: date2,
        id0: 1,
        inst: inst,

        result: data[v]["result"] + m + inst,
        hyper: data[v]["hyper"] + m + inst,
        hypo: data[v]["hypo"] + m + inst,
        all: data[v]["all"] + 2 * m + 2 * inst,
      });
    }
    date2 = new Date(date1.setDate(date1.getDate() + 1));
  }
  console.log("TESZTDATA.................", AnalyteMonth);
  console.log("demodata.................", demodata);

  const a = {
    id0: "4I3TxTOVUzWPuEsiig0o",
    date: new Date(1995, 11, 17),
    all: 125,
    hyper: 6,
    result: 10.7855999999999,
    hypo: 6,
  };
  return (
    <Container className={classes.content}>
      <div>
        {isLoaded &&
          (isEmpty ? (
            <Redirect to="/signin" />
          ) : demodata ? (
            <>
              <FSPTable
                view={Welcome}
                myComponent={ChildComponent}
                model={demodata}
                classes={classes}
                createElement={(measure) => createElement(measure)}
                deleteElement={(measure) => deleteElement(measure)}
                form={form}
                meta={{ manufacturers: manufacturers }}
                loading={manufacturers ? manufacturers.length < 2 : true}
              />

              <StatisticsChartStock
                classes={classes}
                form={{
                  param: "FT4",
                  date: new Date("2015-01-01"),
                  toDate: new Date("2015-12-31"),
                  lab: {id:1, name: "Lab 1"},
                  device: {id:1, name: "Device 1"},
                  ema12: false,
                  ema26: false,
                  macd: false,
                  distribution: "100",
                  range: true,
                  ypan: true,
                }}
                id={data ? data.id : null}
                // results={demodata[0].values}
                results={demodata}
                parameters={meta.parameters}
                // importAdd={  (measure, id) => this.props.importAdd(measure, id)}
              />
            </>
          ) : (
            <CircularProgress />
          ))}
      </div>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    demodata: state.firestore.ordered.demodata,
    manufacturers: state.firestore.ordered.manufacturers,
    authStatus: state.firebase.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addDemodata: (demodata) => dispatch(addDemodata(demodata)),
    deleteDemodata: (id) => dispatch(deleteDemodata(id)),
    updateDemodata: (demodata, id) => dispatch(updateDemodata(demodata, id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Demodata);
