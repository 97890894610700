import React from "react";
import { connect } from "react-redux";
import { useFirestoreConnect } from "react-redux-firebase";
import { Redirect } from "react-router-dom";
import {
  addStatalert,
  deleteStatalert,
  updateStatalert,
} from "../../../store/actions/statalertActions";
import Container from "@material-ui/core/Container";
import useStyles from "../dashboard/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../store/actions/actionTypes";
import FSPTable from "../../common/FSPTable/FSPTable";
import convertArrayByField from "../../common/utils";

const Statalerts = ({
  statalerts,
  instruments,
  labs,
  authStatus,
  addStatalert,
  updateStatalert,
  deleteStatalert,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { isEmpty, isLoaded } = authStatus;
  console.log('LAB', labs);
  let instrumentsArray = convertArrayByField(instruments, "id");
  let labsArray = convertArrayByField(labs, "id");
  const form = {
    titles: {
      index: "Alert",
      create: "Add alert",
      update: "Update alert",
    },
    keyField: 0,
    indexFields: [ 0, 1, 2,3],
    fields: [
      // {
      //   id: 0,
      //   name: "id",
      //   required: false,
      //   // hidden: true,
      //   helper: "ID",
      //   q2: "ID",
      //   label: "ID",
      //   type: 2,
      // },
      {
        id: 0,
        required: true,
        name: "lab",
        helper: "Lab",
        q2: "Lab",
        label: "Lab",
        type: 3,
        values: labs,
        valuesArray: labsArray,
        values_label: "name",
        values_key: "id",
      },
      {
        id: 1,
        required: true,
        name: "source",
        helper: "Instrument code (as reported by the lab.) ",
        q2: "Instrument code (as reported by the lab.) ",
        label: "Instrument code (as reported by the lab.) ",
        type: 2,
      },
      {
        id: 2,
        required: true,
        name: "target",
        helper: "Instrument code (as defined in the program)",
        q2: "Instrument code (as defined in the program)",
        label: "Instrument code (as defined in the program)",
        type: 3,
        values: instruments,
        valuesArray: instrumentsArray,
        values_label: "name",
        values_key: "id",
      },
      {
        id: 3,
        required: true,
        name: "notimported",
        helper: "Results not imported",
        q2: "Results not imported",
        label: "Results not imported",
        type: 3,
        values: [
          { id: "false", name: "No" },
          { id: "true", name: "Yes" },
        ],
        valuesArray: {
          true: [{ id: "true", name: "Yes" }],
          false: [{ id: "false", name: "No" }],
        },
        values_label: "name",
        values_key: "id",
        // model:'manufacturers',
        // modelId: 'db_id',
        // modelLabel:'name'
      },
      
    
    ],
  };
  useFirestoreConnect([
    {
      collection: "statalerts",
      storeAs: "statalerts",
      orderBy: ["createdAt", "asc"],
    },
    {
      collection: "instruments",
      storeAs: "instruments",
      orderBy: ["createdAt", "asc"],
    },
    {
      collection: "labs",
      storeAs: "labs",
      orderBy: ["om", "asc"],
    },
  ]);
  const createElement = (newMeasure) => {
    if (typeof newMeasure.id === "undefined") {
      addStatalert(newMeasure);
    } else {
      updateStatalert(newMeasure, newMeasure.id);
    }
  };

  const deleteElement = (newMeasure) => {
    deleteStatalert(newMeasure);
  };

  dispatch({ type: actions.SET_MENU, payload: "Statalert" });

  return (
    <Container className={classes.content}>
      <div>
        {isLoaded &&
          (isEmpty ? (
            <Redirect to="/signin" />
          ) : statalerts ? (
            <FSPTable
              model={statalerts}
              classes={classes}
              createElement={(measure) => createElement(measure)}
              deleteElement={(measure) => deleteElement(measure)}
              form={form}
              meta={{ instruments: instruments }}
            />
          ) : (
            <CircularProgress />
          ))}
      </div>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    statalerts: state.firestore.ordered.statalerts,
    instruments: state.firestore.ordered.instruments,
    labs: state.firestore.ordered.labs,
    authStatus: state.firebase.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addStatalert: (statalert) =>
      dispatch(addStatalert(statalert)),
    deleteStatalert: (id) => dispatch(deleteStatalert(id)),
    updateStatalert: (statalert, id) =>
      dispatch(updateStatalert(statalert, id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Statalerts);
