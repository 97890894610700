import React, { Component } from "react";

class IQCRowTitle extends Component {
  render() {
    return (
      <div class="jss392 jss363">
        <span class="jss398 jss416 jss395 jss356">{this.props.title}</span>
        <p class="jss398 jss406 jss431 jss396">{this.props.subtitle}</p>
      </div>
    );
  }
}

export default IQCRowTitle;
