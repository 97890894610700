import React, { Component } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import { Delete, Add } from "@material-ui/icons";
import Box from "@material-ui/core/Box";
import JSONTableRow from "./JSONTableRow";
import JSONForm from "./JSONTableForm";
import JSONTableFooter from "./JSONTableFooter";
import JSONTableHeader from "./JSONTableHeader";
import CircularProgress from "@material-ui/core/CircularProgress";
import MyLoader from "./JSONTableLoader";
import JSONTableDeleteDialog from "./JSONTableDeleteDialog";

class JSONTable extends Component {
  constructor(props) {
    super();
    console.log('!!!!!!', props)
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.handleCreateJSON = this.handleCreateJSON.bind(this);
    this.handleStop = this.handleStop.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeArray = this.handleChangeArray.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleFormUpdate = this.handleFormUpdate.bind(this);
    this.deleteElement = this.deleteElement.bind(this);
    // this.createElement = this.createElement.bind(this);
  

    this.state = {
      data: props.value ? props.value : [],
      page: 0,
      rowsPerPage: 5,
      index:-1,
      val: {
        uid: props.uid ? props.uid : null,
        id: null,
        name: null,
      },
      form: {
        date: new Date("2015-01-01"),
      },
      errors: {},
    };
  }
  createElement(name, a) {
    console.log(name);
    console.log(a);
    console.log(this.state.data);
    let n = [...this.state.data] ;
    n.push(a);
    console.log('N',n);
    this.setState({
      data: n ,
    });
    this.props.onChange(name, n);
  }
  deleteElement(name, id){
    console.log(name);
    console.log('DELETE', id)
    console.log('PROPS', this.state);
         let a = this.state.data.map((i) => i.id);
         console.log('A', a);
         console.log(a.indexOf(id));
         let n = [...this.state.data] ;
         n.splice( a.indexOf(id),1);
         console.log('N', n);
         this.setState({
          data: n ,
        });
        this.props.onChange(name, n);
        //  console.log(element.id);
  }
  updateElement(name,  value, id){
    console.log(name);
    console.log('UPDATE ID', id);
    console.log('VALUE', value)
    console.log('PROPS', this.state);
         let a = this.state.data.map((i) => i.id);
         console.log('A', a);
         console.log(a.indexOf(id));
         let n = [...this.state.data] ;
         n[id] =value;
         console.log('N', n);
         this.setState({
          data: n ,
        });
        this.props.onChange(name, n);
        //  console.log(element.id);
  }
  handleChange(e) {
    console.log(this.state.val);
    let n = { ...this.state.val };
    n[e.target.name] = e.target.value;
    this.setState({
      val: { ...n },
    });
    let ee = this.state.errors;
    ee[e.target.name] = e.target.value ? "" : "Nem lehet üres";
    this.setState({
      errors: ee,
    });
  }

  handleChangeArray(name, value) {
    console.log(name);
    console.log(value);
    console.log(this.state.val);
    let n = { ...this.state.val };
    let old = this.state.val[name] ?? [];
    old = value;
    n[name] = old;
    this.setState({
      val: { ...n },
    });
    let ee = this.state.errors;
    ee[name] = value.length > 0 ? "" : "Nem lehet üres";
    this.setState({
      errors: ee,
    });
  }

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value) });
    this.setState({ page: 0 });
  };

  handleCreateJSON = (e) => {
    console.log(e);
    this.setState({
      index:-1,
      val: [],
    });
    this.setState({ open: true });
  };

  handleEdit = (e, index) => {
    console.log(e);
    console.log('index', index);
    this.setState({
      index:index
    });
    this.setState({
      val: e,
    });
    this.setState({ open: true });
  };

  handleFormUpdate = (e, index1) => {
    console.log(e);
    console.log(index1);
    console.log(this.state.val);
    let er = [];
    let err = 0;
    let x = this.props.form.fields.map((field, index) => {
      console.log(index, field);
      console.log(this.state.val[field.name]);
      // er[field.name] = this.state.val[field.name] ? '' : 'Nem lehet üres';
      // err = this.state.val[field.name] || !field.required ? err : err + 1;
      // return this.state.val[field.name] ? true : false
      // let form = form0[0];
      if (field.type === 5) {
        console.log(this.state.val[field.name]);
        console.log(this.state.val[field.name].length);
        er[field.name] =
          this.state.val[field.name] && this.state.val[field.name].length > 0
            ? ""
            : "Nem lehet üres";
        err =
          (this.state.val[field.name] &&
            this.state.val[field.name].length > 0) ||
          !field.required
            ? err
            : err + 1;
        console.log(err);
        return this.state.val[field.name] ? true : false;
      } else {
        er[field.name] = this.state.val[field.name] ? "" : "Nem lehet üres";
        err = this.state.val[field.name] || !field.required ? err : err + 1;
        return this.state.val[field.name] ? true : false;
      }
    });
    this.setState({
      errors: er,
    });
    console.log(er);
    if (err === 0) {
      console.log("MENTÜNK", this.props);
      console.log("INDEX1", index1);
      if (index1 >-1) {
        this.updateElement(this.props.field.name, e, index1);
      } else {
        this.createElement(this.props.field.name, e);
      }
       
      this.setState({ open: false });
    } else {
      console.log("NEM", err);
    }
  };

  handleStop = (e) => {
    this.setState({ open: false });
  };
  render() {
    console.log('PROPS',this.props);
    const { classes, value, model, form, loading, meta } = this.props;
    const View = this.props.view;
    const MyComponent = this.props.myComponent;
    return (
      <div>
        {this.props.view && <View name="Demo data" />}
        {this.props.myComponent && <MyComponent title={"Firebase collection: All (200) instruments - Analyte+Month"} />}

        <Box display="flex" p={1}>
          <Box p={1} flexGrow={1}>
            <h1>{form.titles.index}</h1>
          </Box>
          <Box p={1}>
            <Button
              // type="submit"
              // variant="contained"
              color="primary"
              className={classes.button}
              onClick={() => {
                this.handleCreateJSON();
              }}
            >
              <Add />
              {form.titles.create}
            </Button>
          </Box>
        </Box>
        <TableContainer
          // style={{ border: "1px solid rgba(0,0,0,0.2)", padding: 4 }}
          className={classes.table}
          component={Paper}
        >
          <Table
            size="small"
            classes={{ root: classes.customTable }}
            aria-label="a dense table"
          >
            <JSONTableHeader form={form} />
            <TableBody>
              {loading ? (
                <MyLoader />
              ) : (
                value &&
                value
                  .slice(
                    this.state.page * this.state.rowsPerPage,
                    this.state.page * this.state.rowsPerPage +
                      this.state.rowsPerPage
                  )
                  .map((element, index) => (
                    <JSONTableRow
                      key={index}
                      index={index}
                      element={element}
                      classes={classes}
                      uid={element.index}
                      handleEdit={this.handleEdit}
                      deleteElement={this.deleteElement}
                      form={form}
                      field={this.props.field}
                      meta={meta}
                    />
                  ))
              )}
            </TableBody>
            <JSONTableFooter
              count={this.props.model.length}
              rowsPerPage={this.state.rowsPerPage}
              model={this.props.model}
              page={this.state.page}
              handleChangePage={(e, a) => this.handleChangePage(e, a)}
              handleChangeRowsPerPage={(e) => this.handleChangeRowsPerPage(e)}
            />
          </Table>
        </TableContainer>
        <JSONForm
          classes={classes}
          open={this.state.open}
          handleChange={this.handleChange}
          handleChangeArray={this.handleChangeArray}
          handleStop={this.handleStop}
          handleFormUpdate={this.handleFormUpdate}
          val={this.state.val}
          form={form}
          errors={this.state.errors}
          meta={meta}
          index={this.state.index}
        />
      </div>
    );
  }
}

export default JSONTable;
