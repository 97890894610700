import React from "react";
import { connect } from "react-redux";
import { useFirestoreConnect } from "react-redux-firebase";
import { Redirect } from "react-router-dom";
import {
  addInstrument,
  deleteInstrument,
  updateInstrument,
} from "../../../store/actions/instrumentActions";
import Container from "@material-ui/core/Container";
import useStyles from "../dashboard/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../store/actions/actionTypes";
import FSPTable from "../../common/FSPTable/FSPTable";
import convertArrayByField from "../../common/utils";

const Instruments = ({
  instruments,
  manufacturers,
  models,
  instrumenttypes,
  authStatus,
  addInstrument,
  updateInstrument,
  deleteInstrument,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { isEmpty, isLoaded } = authStatus;
  let manufacturersArray = convertArrayByField(manufacturers, "id");
  let modelsArray = convertArrayByField(models, "id");
  let instrumenttypesArray = convertArrayByField(instrumenttypes, "id");
  const form = {
    titles: {
      index: "Instruments",
      create: "Add instrument",
      update: "Update instrument",
    },
    keyField: 0,
    indexFields: [0, 1, 2, 3, 4, 5],
    fields: [
      {
        id: 0,
        name: "om",
        required: true,
        helper: "ID",
        q2: "ID",
        label: "ID",
        type: 2,
        suggestion: 'add'//'#ffdddd'
      },
      {
        id: 1,
        required: true,
        name: "name",
        helper: "Instrument name",
        q2: "Instrument name",
        label: "Instrument name",
        type: 2,
        suggestion: 'delete'//'#ffdddd'
      },
      {
        id: 2,
        required: true,
        name: "manufacturer",
        helper: "Manufacturer",
        q2: "Manufacturer",
        label: "Manufacturer",
        type: 3,
        values: manufacturers,
        valuesArray: manufacturersArray,
        values_label: "name",
        values_key: "id",
        // model:'manufacturers',
        // modelId: 'db_id',
        // modelLabel:'name'
      },
      {
        id: 3,
        required: true,
        name: "model",
        helper: "Model",
        q2: "Model",
        label: "Model",
        type: 3,
        values: models,
        valuesArray: modelsArray,
        values_label: "name",
        values_key: "id",
      },
      {
        id: 4,
        required: true,
        name: "instrumenttype",
        helper: "Instrument type",
        q2: "Instrument type",
        label: "Instrument type",
        type: 3,
        values: instrumenttypes,
        valuesArray: instrumenttypesArray,
        values_label: "name",
        values_key: "id",
        model: "instrumenttypes",
        modelId: "id",
        modelLabel: "name",
      },
      {
        id: 5,
        required: true,
        name: "active",
        helper: "Active",
        q2: "Active",
        label: "Active",
        type: 3,
        values: [
          { id: "false", name: "Inactive" },
          { id: "true", name: "Active" },
        ],
        valuesArray: {
          false: [{ id: "false", name: "Inactive" }],
          true: [{ id: "true", name: "Active" }],
        },
        values_label: "name",
        values_key: "id",
        // model:'manufacturers',
        // modelId: 'db_id',
        // modelLabel:'name'
      },
    ],
  };
  useFirestoreConnect([
    {
      collection: "instruments",
      storeAs: "instruments",
      orderBy: ["om", "asc"],
    },
    {
      collection: "manufacturers",
      storeAs: "manufacturers",
      orderBy: ["name", "asc"],
    },
    {
      collection: "models",
      storeAs: "models",
      orderBy: ["name", "asc"],
    },
    {
      collection: "instrumenttypes",
      storeAs: "instrumenttypes",
      orderBy: ["name", "asc"],
    },
  ]);
  const createElement = (newMeasure) => {
    if (typeof newMeasure.id === "undefined") {
      addInstrument(newMeasure);
    } else {
      updateInstrument(newMeasure, newMeasure.id);
    }
  };

  const deleteElement = (newMeasure) => {
    deleteInstrument(newMeasure);
  };

  dispatch({ type: actions.SET_MENU, payload: "Instruments" });

  return (
    <Container className={classes.content}>
      <div>
        {isLoaded &&
          (isEmpty ? (
            <Redirect to="/signin" />
          ) : instruments ? (
            <FSPTable
              model={instruments}
              classes={classes}
              createElement={(measure) => createElement(measure)}
              deleteElement={(measure) => deleteElement(measure)}
              form={form}
              meta={{
                instrumenttypes: instrumenttypes,
                models: models,
                manufacturers: manufacturers,
              }}
            />
          ) : (
            <CircularProgress />
          ))}
      </div>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    instruments: state.firestore.ordered.instruments,
    manufacturers: state.firestore.ordered.manufacturers,
    models: state.firestore.ordered.models,
    instrumenttypes: state.firestore.ordered.instrumenttypes,
    authStatus: state.firebase.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addInstrument: (instrument) => dispatch(addInstrument(instrument)),
    deleteInstrument: (id) => dispatch(deleteInstrument(id)),
    updateInstrument: (instrument, id) =>
      dispatch(updateInstrument(instrument, id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Instruments);
