import firebase from 'firebase/app';
import * as actions from './actionTypes';

export const addInstrumentmapping = (instrumentmapping) => async (dispatch, getState) => {
    console.log('bbbb', instrumentmapping);
  try {
    dispatch({ type: actions.ADD_TODO_START });
    const uid = getState().firebase.auth.uid;
    const firestore = firebase.firestore();
    await firestore.collection('instrumentmappings').add({
      ...instrumentmapping,
      authorId: uid,
      createdAt: new Date(),
      updatedAt: new Date(),
    });
    dispatch({ type: actions.ADD_TODO_SUCESS });
  } catch (err) {
    dispatch({ type: actions.ADD_TODO_FAIL, err });
  }
};

export const updateInstrumentmapping = (instrumentmapping, id) => async (dispatch) => {
    console.log('bbbb', instrumentmapping);
  try {
    dispatch({ type: actions.UPDATE_TODO_START });
    const firestore = firebase.firestore();
    await firestore.collection('instrumentmappings').doc(id).update({
      // db_id: instrumentmapping.db_id,
      source: instrumentmapping.source,
      target: instrumentmapping.target,
      lab: instrumentmapping.lab,
      notimported:instrumentmapping.notimported,
      updatedAt: new Date(),
    });
    dispatch({ type: actions.UPDATE_TODO_SUCESS });
  } catch (err) {
    dispatch({ type: actions.UPDATE_TODO_FAIL, err });
  }
};

export const deleteInstrumentmapping = (id) => async (dispatch) => {
    console.log('bbbb', id);
  try {
    dispatch({ type: actions.DELETE_TODO_START });
    const firestore = firebase.firestore();
    await firestore.collection('instrumentmappings').doc(id).delete();
    dispatch({ type: actions.DELETE_TODO_SUCESS });
  } catch (err) {
    dispatch({ type: actions.DELETE_TODO_FAIL, err });
  }
};
