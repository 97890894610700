import React from 'react';
import { connect } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import { Redirect } from 'react-router-dom';
import { addManufacturer, deleteManufacturer, updateManufacturer } from '../../../store/actions/manufacturerActions';
import Container from '@material-ui/core/Container';
import useStyles from "../dashboard/styles";
import CircularProgress from '@material-ui/core/CircularProgress';
import { useDispatch, useSelector } from 'react-redux'
import * as actions from '../../../store/actions/actionTypes';
import FSPTable from '../../common/FSPTable/FSPTable';

const Manufacturers = ({ manufacturers, authStatus, addManufacturer, updateManufacturer, deleteManufacturer }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { isEmpty, isLoaded } = authStatus;
    const form = {
        titles: {
            index: 'Manufacturers',
            create: 'Add manufacturer',
            update: 'Update manufacturer',
        },
        keyField: 0,
        indexFields: [0, 1],
        fields: [
            {
                id: 0,
                name: 'db_id',
                required: true,
                helper: 'ID',
                q2: 'ID',
                label: 'ID',
                type: 2,
            },
            {
                id: 1,
                required: true,
                name: 'name',
                helper: 'Manufacturer name',
                q2: 'Manufacturer name',
                label: 'Manufacturer name',
                type: 2,
            }
        ]
    };
    useFirestoreConnect([
        {
            collection: "manufacturers",
            storeAs: 'manufacturers',
            orderBy: ['db_id', 'asc']
        }
    ]);
    const createElement = (newMeasure) => {
        if (typeof newMeasure.id === 'undefined') {
            addManufacturer(newMeasure);
        } else {
            updateManufacturer(newMeasure, newMeasure.id)
        }
    }

    const deleteElement = (newMeasure) => {
        deleteManufacturer(newMeasure);
    }

    dispatch({ type: actions.SET_MENU, payload: 'Manufacturers' })

    return (
        <Container className={classes.content}>
            <div>
                {isLoaded &&
                    (isEmpty ? (
                        <Redirect to='/signin' />
                    ) : (
                        manufacturers ?
                            <FSPTable
                                model={manufacturers}
                                classes={classes}
                                createElement={(measure) => createElement(measure)}
                                deleteElement={(measure) => deleteElement(measure)}
                                form={form}
                            /> :
                            <CircularProgress />
                    ))}
            </div>
        </Container>
    );
};

const mapStateToProps = (state) => {
    return {
        manufacturers: state.firestore.ordered.manufacturers,
        authStatus: state.firebase.auth,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addManufacturer: (manufacturer) => dispatch(addManufacturer(manufacturer)),
        deleteManufacturer: (id) => dispatch(deleteManufacturer(id)),
        updateManufacturer: (manufacturer, id) => dispatch(updateManufacturer(manufacturer, id)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Manufacturers);
