import React from "react";
import { connect } from "react-redux";
import { useFirestoreConnect } from "react-redux-firebase";
import { Redirect } from "react-router-dom";
import {
  addAnalyteconversion,
  deleteAnalyteconversion,
  updateAnalyteconversion,
} from "../../../store/actions/analyteconversionActions";
import Container from "@material-ui/core/Container";
import useStyles from "../dashboard/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../store/actions/actionTypes";
import FSPTable from "../../common/FSPTable/FSPTable";
import convertArrayByField from "../../common/utils";

const Analyteconversions = ({
  analyteconversions,
  analytes,
  labs,
  authStatus,
  addAnalyteconversion,
  updateAnalyteconversion,
  deleteAnalyteconversion,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { isEmpty, isLoaded } = authStatus;
  console.log('LAB', labs);
  let analytesArray = convertArrayByField(analytes, "id");
  let labsArray = convertArrayByField(labs, "id");
  const form = {
    titles: {
      index: "Analyte conversion calculation",
      create: "Add analyte conversion",
      update: "Update analyte conversion",
    },
    keyField: 1,
    indexFields: [ 1, 2, 3, 4, 5],
    fields: [
      // {
      //   id: 0,
      //   name: "id",
      //   required: false,
      //   // hidden: true,
      //   helper: "ID",
      //   q2: "ID",
      //   label: "ID",
      //   type: 2,
      // },
      {
        id: 1,
        required: true,
        name: "name",
        helper: "Analyte conversion name",
        q2: "Analyte conversion name",
        label: "Analyte conversion name",
        type: 2,
      },
      {
        id: 2,
        required: true,
        name: "lab",
        helper: "Lab",
        q2: "Lab",
        label: "Lab",
        type: 3,
        values: labs,
        valuesArray: labsArray,
        values_label: "name",
        values_key: "id",
      },
      {
        id: 3,
        required: true,
        name: "source",
        helper: "Source",
        q2: "Source",
        label: "Source",
        type: 3,
        values: analytes,
        valuesArray: analytesArray,
        values_label: "name",
        values_key: "id",
      },
      {
        id: 4,
        required: true,
        name: "target",
        helper: "Target",
        q2: "Target",
        label: "Target",
        type: 3,
        values: analytes,
        valuesArray: analytesArray,
        values_label: "name",
        values_key: "id",
      },
      {
        id: 5,
        required: true,
        name: "a",
        helper: "A factor (ax+b)",
        q2: "A factor (ax+b)",
        label: "A factor (ax+b)",
        type: 2,
      },
      {
        id: 6,
        required: true,
        name: "b",
        helper: "B factor (ax+b)",
        q2: "B factor (ax+b)",
        label: "B factor (ax+b)",
        type: 2,
      },
    ],
  };
  useFirestoreConnect([
    {
      collection: "analyteconversions",
      storeAs: "analyteconversions",
      orderBy: ["createdAt", "asc"],
    },
    {
      collection: "analytes",
      storeAs: "analytes",
      orderBy: ["db_id", "asc"],
    },
    {
      collection: "labs",
      storeAs: "labs",
      orderBy: ["om", "asc"],
    },
  ]);
  const createElement = (newMeasure) => {
    if (typeof newMeasure.id === "undefined") {
      addAnalyteconversion(newMeasure);
    } else {
      updateAnalyteconversion(newMeasure, newMeasure.id);
    }
  };

  const deleteElement = (newMeasure) => {
    deleteAnalyteconversion(newMeasure);
  };

  dispatch({ type: actions.SET_MENU, payload: "Analyte conversions" });

  return (
    <Container className={classes.content}>
      <div>
        {isLoaded &&
          (isEmpty ? (
            <Redirect to="/signin" />
          ) : analyteconversions ? (
            <FSPTable
              model={analyteconversions}
              classes={classes}
              createElement={(measure) => createElement(measure)}
              deleteElement={(measure) => deleteElement(measure)}
              form={form}
              meta={{ analytes: analytes }}
            />
          ) : (
            <CircularProgress />
          ))}
      </div>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    analyteconversions: state.firestore.ordered.analyteconversions,
    analytes: state.firestore.ordered.analytes,
    labs: state.firestore.ordered.labs,
    authStatus: state.firebase.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addAnalyteconversion: (analyteconversion) =>
      dispatch(addAnalyteconversion(analyteconversion)),
    deleteAnalyteconversion: (id) => dispatch(deleteAnalyteconversion(id)),
    updateAnalyteconversion: (analyteconversion, id) =>
      dispatch(updateAnalyteconversion(analyteconversion, id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Analyteconversions);
