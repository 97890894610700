import React, { Component } from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { Delete, Add } from "@material-ui/icons";
import {
  Checkbox,
  FormControlLabel,
  Switch,
  Typography,
} from "@material-ui/core";

class IQCHeader extends Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={"jss519 jss510"}>
        <div className={"jss522"}>
          <Box display="flex" p={1}>
            <Box p={1} flexGrow={1}>
              <div className={"jss398 jss415 jss427 jss424 jss447"}>
              {this.props.title}
              </div>
              <div class="jss1094">
                <span class="">{this.props.subtitle}</span>
              </div>
            </Box>

            <Box p={1}>
              <Button
                type="submit"
                // variant="contained"
                color="primary"
                className={classes.button}
                onClick={() => {
                  this.handleCreate();
                }}
              >
                <div className={"jss508 jss398 jss406 jss536"}>
                {this.props.switchtitle}
                </div>
                {/* <Add /> */}
                <FormControlLabel
                  value="start"
                  control={<Switch color="primary" />}
                  // label="Has missing results in current surveys"
                  labelPlacement="start"
                />
              </Button>
            </Box>
          </Box>
        </div>
      </div>
    );
  }
}

export default IQCHeader;
