import React, { Component } from "react";
import IQCRowKey from "./IQCRowKey";
import IQCAnalyteRowButtons from "./IQCAnalyteRowButtons";
import { Delete, Edit } from "@material-ui/icons";
import IQCAnalyteRowButton from "./IQCAnalyteRowButton";
import IQCRowTitle from "./IQCRowTitle";

class IQCRow extends Component {
  render() {
    const { classes } = this.props;
    return (
      <div>
        <div class="jss550 jss500 jss507">
          <div>
            <ul class="jss579">
              <li
                class="jss324 jss379 jss382 jss387 jss388 jss355"
                tabindex="0"
                role="button"
              >
                {this.props.children}
                
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default IQCRow;
