import React, { Component } from 'react';

class IQCRowKey extends Component {
    render() {
        return (
            <span class="jss583 jss585">{this.props.type}</span>
        );
    }
}

export default IQCRowKey;