import React, { Component } from "react";
import IQCRowKey from "./IQCRowKey";
import IQCAnalyteRowButtons from "./IQCAnalyteRowButtons";
import { Delete, Edit } from "@material-ui/icons";
import IQCAnalyteRowButton from "./IQCAnalyteRowButton";
import IQCRowTitle from "./IQCRowTitle";
import IQCRowFloatElement from "./IQCRowFloatElement";
import Box from "@material-ui/core/Box";
import IQCRowFloatMiniElement from "./IQCRowFloatMiniElement";
import IQCHeader from "./IQCHeader";

class IQCRowFloatMini extends Component {
  render() {
    const { classes } = this.props;
    return (
      <div class="jss281 jss284 jss282 jss1030 jss1011 jss703 list-card">
        <IQCHeader
            classes={classes}
            title="Laboratory 1"
            subtitle="Department 1"
            switchtitle="Subscribe to all notification"
          />
        <div class="jss1031 jss1012 jss1019">
          <ul class="jss1088 jss1032 jss1036 jss1037a">
            <IQCRowFloatMiniElement />
            <IQCRowFloatMiniElement />
            <IQCRowFloatMiniElement />
            <IQCRowFloatMiniElement />
            <IQCRowFloatMiniElement />
            <IQCRowFloatMiniElement />
          </ul>
        </div>
      </div>
    );
  }
}

export default IQCRowFloatMini;
