import React from "react";
import { connect } from "react-redux";
import { useFirestoreConnect } from "react-redux-firebase";
import { Redirect } from "react-router-dom";
import {
  addUnitmapping,
  deleteUnitmapping,
  updateUnitmapping,
} from "../../../store/actions/unitmappingActions";
import Container from "@material-ui/core/Container";
import useStyles from "../dashboard/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../store/actions/actionTypes";
import FSPTable from "../../common/FSPTable/FSPTable";
import convertArrayByField from "../../common/utils";

const Unitmappings = ({
  unitmappings,
  units,
  labs,
  authStatus,
  addUnitmapping,
  updateUnitmapping,
  deleteUnitmapping,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { isEmpty, isLoaded } = authStatus;
  console.log('LAB', labs);
  let unitsArray = convertArrayByField(units, "id");
  let labsArray = convertArrayByField(labs, "id");
  const form = {
    titles: {
      index: "Unit mapping",
      create: "Add unit mapping",
      update: "Update unit mapping",
    },
    keyField: 0,
    indexFields: [ 0, 1, 2],
    fields: [
      // {
      //   id: 0,
      //   name: "id",
      //   required: false,
      //   // hidden: true,
      //   helper: "ID",
      //   q2: "ID",
      //   label: "ID",
      //   type: 2,
      // },
      {
        id: 0,
        required: true,
        name: "lab",
        helper: "Lab",
        q2: "Lab",
        label: "Lab",
        type: 3,
        values: labs,
        valuesArray: labsArray,
        values_label: "name",
        values_key: "id",
      },
      {
        id: 1,
        required: true,
        name: "source",
        helper: "Unit code (as reported by the lab.) ",
        q2: "Unit code (as reported by the lab.) ",
        label: "Unit code (as reported by the lab.) ",
        type: 2,
      },
      {
        id: 2,
        required: true,
        name: "target",
        helper: "Unit code (as defined in the program)",
        q2: "Unit code (as defined in the program)",
        label: "Unit code (as defined in the program)",
        type: 3,
        values: units,
        valuesArray: unitsArray,
        values_label: "name",
        values_key: "id",
      },
    
    ],
  };
  useFirestoreConnect([
    {
      collection: "unitmappings",
      storeAs: "unitmappings",
      orderBy: ["createdAt", "asc"],
    },
    {
      collection: "units",
      storeAs: "units",
      orderBy: ["db_id", "asc"],
    },
    {
      collection: "labs",
      storeAs: "labs",
      orderBy: ["om", "asc"],
    },
  ]);
  const createElement = (newMeasure) => {
    if (typeof newMeasure.id === "undefined") {
      addUnitmapping(newMeasure);
    } else {
      updateUnitmapping(newMeasure, newMeasure.id);
    }
  };

  const deleteElement = (newMeasure) => {
    deleteUnitmapping(newMeasure);
  };

  dispatch({ type: actions.SET_MENU, payload: "Unit mapping" });

  return (
    <Container className={classes.content}>
      <div>
        {isLoaded &&
          (isEmpty ? (
            <Redirect to="/signin" />
          ) : unitmappings ? (
            <FSPTable
              model={unitmappings}
              classes={classes}
              createElement={(measure) => createElement(measure)}
              deleteElement={(measure) => deleteElement(measure)}
              form={form}
              meta={{ units: units }}
            />
          ) : (
            <CircularProgress />
          ))}
      </div>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    unitmappings: state.firestore.ordered.unitmappings,
    units: state.firestore.ordered.units,
    labs: state.firestore.ordered.labs,
    authStatus: state.firebase.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addUnitmapping: (unitmapping) =>
      dispatch(addUnitmapping(unitmapping)),
    deleteUnitmapping: (id) => dispatch(deleteUnitmapping(id)),
    updateUnitmapping: (unitmapping, id) =>
      dispatch(updateUnitmapping(unitmapping, id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Unitmappings);
