import firebase from 'firebase/app';
import * as actions from './actionTypes';

export const addAnalyteconversion = (analyteconversion) => async (dispatch, getState) => {
    console.log('bbbb', analyteconversion);
  try {
    dispatch({ type: actions.ADD_TODO_START });
    const uid = getState().firebase.auth.uid;
    const firestore = firebase.firestore();
    await firestore.collection('analyteconversions').add({
      ...analyteconversion,
      authorId: uid,
      createdAt: new Date(),
      updatedAt: new Date(),
    });
    dispatch({ type: actions.ADD_TODO_SUCESS });
  } catch (err) {
    dispatch({ type: actions.ADD_TODO_FAIL, err });
  }
};

export const updateAnalyteconversion = (analyteconversion, id) => async (dispatch) => {
    console.log('bbbb', analyteconversion);
  try {
    dispatch({ type: actions.UPDATE_TODO_START });
    const firestore = firebase.firestore();
    await firestore.collection('analyteconversions').doc(id).update({
      // db_id: analyteconversion.db_id,
      name: analyteconversion.name,
      source: analyteconversion.source,
      target: analyteconversion.target,
      lab: analyteconversion.lab,
      b: analyteconversion.b,
      a: analyteconversion.a,
      updatedAt: new Date(),
    });
    dispatch({ type: actions.UPDATE_TODO_SUCESS });
  } catch (err) {
    dispatch({ type: actions.UPDATE_TODO_FAIL, err });
  }
};

export const deleteAnalyteconversion = (id) => async (dispatch) => {
    console.log('bbbb', id);
  try {
    dispatch({ type: actions.DELETE_TODO_START });
    const firestore = firebase.firestore();
    await firestore.collection('analyteconversions').doc(id).delete();
    dispatch({ type: actions.DELETE_TODO_SUCESS });
  } catch (err) {
    dispatch({ type: actions.DELETE_TODO_FAIL, err });
  }
};
