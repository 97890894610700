import React from "react";
import { connect } from "react-redux";
import { useFirestoreConnect } from "react-redux-firebase";
import { Redirect } from "react-router-dom";
import {
  addAnalyte,
  deleteAnalyte,
  updateAnalyte,
} from "../../../store/actions/analyteActions";
import Container from "@material-ui/core/Container";
import useStyles from "../dashboard/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../store/actions/actionTypes";
import FSPTable from "../../common/FSPTable/FSPTable";
import convertArrayByField from "../../common/utils";

const Analytes = ({
  analytes,
  units,
  authStatus,
  addAnalyte,
  updateAnalyte,
  deleteAnalyte,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { isEmpty, isLoaded } = authStatus;
  let unitsArray = convertArrayByField(units, "db_id");
  console.log(units);
  console.log(unitsArray);
  const form = {
    titles: {
      index: "Analytes",
      create: "Add analyte",
      update: "Update analyte",
    },
    keyField: 0,
    indexFields: [0, 1, 3, 4],
    fields: [
      {
        id: 0,
        name: "db_id",
        required: true,
        helper: "ID",
        q2: "ID",
        label: "ID",
        type: 2,
      },
      {
        id: 1,
        required: true,
        name: "name",
        helper: "Analyte name",
        q2: "Analyte name",
        label: "Analyte name",
        type: 2,
      },
      {
        id: 2,
        required: true,
        name: "abbreviation",
        helper: "Abbreviation",
        q2: "Abbreviation",
        label: "Abbreviation",
        type: 2,
      },
      {
        id: 3,
        required: true,
        name: "unit",
        helper: "Unit",
        q2: "Unit",
        label: "Unit",
        type: 3,
        values: units,
        valuesArray: unitsArray,
        values_label: "name",
        values_key: "db_id",
      },
      {
        id: 4,
        required: true,
        name: "decimals",
        helper: "Decimals",
        q2: "Decimals",
        label: "Decimals",
        type: 4,
        min: 1,
        max: 100,
      },
      {
        id: 5,
        required: false,
        name: "alternative_unit",
        helper: "Alternative unit",
        q2: "Alternative unit",
        label: "Alternative unit",
        type: 3,
        values: units,
        valuesArray: unitsArray,
        values_label: "name",
        values_key: "db_id",
      },
      {
        id: 6,
        required: false,
        name: "decimals_alternative_unit",
        helper: "Decimals alternative unit",
        q2: "Decimals alternative unit",
        label: "Decimals alternative unit",
        type: 4,
      },

      // Unit
      // Decimals
      // Alternative unit
      // Decimals alternative unit
    ],
  };
  useFirestoreConnect([
    {
      collection: "analytes",
      storeAs: "analytes",
      orderBy: ["db_id", "asc"],
    },
    {
      collection: "units",
      storeAs: "units",
      orderBy: ["db_id", "asc"],
    },
  ]);
  const createElement = (newMeasure) => {
    if (typeof newMeasure.id === "undefined") {
      addAnalyte(newMeasure);
    } else {
      updateAnalyte(newMeasure, newMeasure.id);
    }
  };

  const deleteElement = (newMeasure) => {
    deleteAnalyte(newMeasure);
  };

  dispatch({ type: actions.SET_MENU, payload: "Analytes" });

  return (
    <Container className={classes.content}>
      <div>
        {isLoaded &&
          (isEmpty ? (
            <Redirect to="/signin" />
          ) : analytes ? (
            <FSPTable
              model={analytes}
              classes={classes}
              createElement={(measure) => createElement(measure)}
              deleteElement={(measure) => deleteElement(measure)}
              form={form}
              meta={{ units: units }}
            />
          ) : (
            <CircularProgress />
          ))}
      </div>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    analytes: state.firestore.ordered.analytes,
    units: state.firestore.ordered.units,
    authStatus: state.firebase.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addAnalyte: (analyte) => dispatch(addAnalyte(analyte)),
    deleteAnalyte: (id) => dispatch(deleteAnalyte(id)),
    updateAnalyte: (analyte, id) => dispatch(updateAnalyte(analyte, id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Analytes);
