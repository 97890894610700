import React from "react";
import { connect } from "react-redux";
import { useFirestoreConnect } from "react-redux-firebase";
import { Redirect } from "react-router-dom";
import {
  addDemodatax,
  deleteDemodatax,
  updateDemodatax,
} from "../../../store/actions/demodata2Actions";
import Container from "@material-ui/core/Container";
import useStyles from "../dashboard/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../store/actions/actionTypes";
import FSPTable from "../../common/FSPTable/FSPTable";
import convertArrayByField from "../../common/utils";
import { data, meta } from "./data";
import StatisticsChartStock from "./StatisticsChartStock";

function Welcome(props) {
  return <h1>Firebase- {props.name}</h1>;
}
const ChildComponent = (props) => {
  return <h1>{props.title}</h1>;
};

const Demodata2 = ({
  demodatax,
  manufacturers,
  authStatus,
  addDemodatax,
  updateDemodatax,
  deleteDemodatax,
}) => {
  console.log("data", data);
  const classes = useStyles();
  const dispatch = useDispatch();
  const { isEmpty, isLoaded } = authStatus;
  let manufacturersArray = convertArrayByField(manufacturers, "id");
  console.log("manu", manufacturers);
  console.log("manu", manufacturersArray);
  const form = {
    titles: {
      index: "Collection: annual data for a parameter of a laboratory",
      create: "Add demo data",
      update: "Update demo data",
    },
    keyField: 0,
    indexFields: [0, 1],
    fields: [
      {
        id: 0,
        name: "db_id",
        required: true,
        helper: "ID",
        q2: "ID",
        label: "ID",
        type: 2,
      },
      {
        id: 1,
        required: true,
        name: "name",
        helper: "Demo data name",
        q2: "Demo data name",
        label: "Demo data name",
        type: 2,
      },
    ],
  };
  useFirestoreConnect([
    {
      collection: "demodatax",
      storeAs: "demodatax",
      orderBy: ["db_id", "asc"],
    },
    {
      collection: "manufacturers",
      storeAs: "manufacturers",
      orderBy: ["name", "asc"],
    },
  ]);
  const createElement = (newMeasure) => {
    if (typeof newMeasure.id === "undefined") {
      addDemodatax({ ...newMeasure, values: AnalyteMonth });
    } else {
      updateDemodatax({ ...newMeasure, values: AnalyteMonth }, newMeasure.id);
    }
  };

  const deleteElement = (newMeasure) => {
    deleteDemodatax(newMeasure);
  };

  dispatch({ type: actions.SET_MENU, payload: "Instrument demodatax" });
  let AnalyteMonth = [];
  let date1 = new Date(2009, 12, 1);
  let dateTo = new Date(2010, 1, 1);
  let date2 = new Date(date1);
  for (let inst = 0; inst < 40; inst++) {
    let month = date2.getMonth() + 1;
    // let day = date2.day() + 1;
    let year = date2.getYear() + 1900;
    let month2 =('0'+ month).substr(-2,2);
    let inst2= inst;
    for (let i = 0; i < 365; i++) {
        // let yearinst= year+'-'+inst;
      if (typeof AnalyteMonth[inst2] === "undefined") {
        AnalyteMonth[inst2] = [];
        // does not exist
      }
      let v = Math.floor(Math.random() * 261);
      let m = Math.floor(Math.random() * 10);
      // console.log(data[v]['result']+m+inst );
      AnalyteMonth[inst2].push({
        date: date2,
        // id0: 1,
        // inst: inst,

        result: data[v]["result"] + m + inst,
        hyper: data[v]["hyper"] + m + inst,
        hypo: data[v]["hypo"] + m + inst,
        all: data[v]["all"] + 2 * m + 2 * inst,
      });
    }
    date2 = new Date(date1.setDate(date1.getDate() + 1));
  }
  console.log("TESZTDATA.................", AnalyteMonth);
  console.log("demodatax.................", demodatax);

  const a = {
    id0: "4I3TxTOVUzWPuEsiig0o",
    date: new Date(1995, 11, 17),
    all: 125,
    hyper: 6,
    result: 10.7855999999999,
    hypo: 6,
  };
  return (
    <Container className={classes.content}>
      <div>
        {isLoaded &&
          (isEmpty ? (
            <Redirect to="/signin" />
          ) : demodatax ? (
            <>
              <FSPTable
                model={demodatax}
                classes={classes}
                createElement={(measure) => createElement(measure)}
                deleteElement={(measure) => deleteElement(measure)}
                form={form}
                meta={{ manufacturers: manufacturers }}
                loading={manufacturers ? manufacturers.length < 2 : true}
              />

              {/* <StatisticsChartStock
                classes={classes}
                form={{
                  param: "FT4",
                  date: new Date("2015-01-01"),
                  toDate: new Date("2015-12-31"),
                  lab: {id:1, name: "Lab 1"},
                  device: {id:1, name: "Device 1"},
                  ema12: false,
                  ema26: false,
                  macd: false,
                  distribution: "100",
                  range: true,
                  ypan: true,
                }}
                id={data ? data.id : null}
                // results={demodatax[0].values}
                results={demodatax}
                parameters={meta.parameters}
                // importAdd={  (measure, id) => this.props.importAdd(measure, id)}
              /> */}
            </>
          ) : (
            <CircularProgress />
          ))}
      </div>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    demodatax: state.firestore.ordered.demodatax,
    manufacturers: state.firestore.ordered.manufacturers,
    authStatus: state.firebase.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addDemodatax: (demodatax) => dispatch(addDemodatax(demodatax)),
    deleteDemodatax: (id) => dispatch(deleteDemodatax(id)),
    updateDemodatax: (demodatax, id) => dispatch(updateDemodatax(demodatax, id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Demodata2);
