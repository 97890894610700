import firebase from "firebase/app";
import * as actions from "./actionTypes";

export const addInstrument = (instrument) => async (dispatch, getState) => {
  console.log("bbbb", instrument);
  try {
    dispatch({ type: actions.ADD_TODO_START });
    const uid = getState().firebase.auth.uid;
    const firestore = firebase.firestore();
    await firestore.collection("instruments").add({
      ...instrument,
      authorId: uid,
      createdAt: new Date(),
      updatedAt: new Date(),
    });
    dispatch({ type: actions.ADD_TODO_SUCESS });
  } catch (err) {
    dispatch({ type: actions.ADD_TODO_FAIL, err });
  }
};

export const updateInstrument = (instrument, id) => async (dispatch) => {
  console.log("bbbb", instrument);
  try {
    dispatch({ type: actions.UPDATE_TODO_START });
    const firestore = firebase.firestore();
    await firestore.collection("instruments").doc(id).update({
      om: instrument.om,
      name: instrument.name,
      model: instrument.model,
      manufacturer: instrument.manufacturer,
      instrumenttype: instrument.instrumenttype,
      active: instrument.active,
      updatedAt: new Date(),
    });
    dispatch({ type: actions.UPDATE_TODO_SUCESS });
  } catch (err) {
    dispatch({ type: actions.UPDATE_TODO_FAIL, err });
  }
};

export const deleteInstrument = (id) => async (dispatch) => {
  console.log("bbbb", id);
  try {
    dispatch({ type: actions.DELETE_TODO_START });
    const firestore = firebase.firestore();
    await firestore.collection("instruments").doc(id).delete();
    dispatch({ type: actions.DELETE_TODO_SUCESS });
  } catch (err) {
    dispatch({ type: actions.DELETE_TODO_FAIL, err });
  }
};
