import React from "react";
import { connect } from "react-redux";
import { useFirestoreConnect } from "react-redux-firebase";
import { Redirect } from "react-router-dom";
import {
  addLab,
  deleteLab,
  updateLab,
} from "../../../store/actions/labActions";
import Container from "@material-ui/core/Container";
import useStyles from "../dashboard/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../store/actions/actionTypes";
import FSPTable from "../../common/FSPTable/FSPTable";
import convertArrayByField from "../../common/utils";
import IQCSection from "../../common/ICQ/IQCSection";

const Labs = ({
  labs,
  countries,
  authStatus,
  addLab,
  updateLab,
  deleteLab,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { isEmpty, isLoaded } = authStatus;
  let countriesArray = convertArrayByField(countries, "om");
  const form = {
    titles: {
      index: "Laboratories",
      create: "Add lab",
      update: "Update lab",
    },
    keyField: 0,
    indexFields: [0, 1, 4, 5, 10, 11],
    fields: [
      {
        id: 0,
        name: "om",
        required: true,
        helper: "Identifier",
        q2: "Identifier",
        label: "Identifier",
        type: 2,
      },
      {
        id: 1,
        required: true,
        name: "name",
        helper: "Name of laboratory",
        q2: "Name of laboratory",
        label: "Name of laboratory",
        type: 2,
      },
      {
        id: 2,
        required: true,
        name: "email",
        helper: "Email address",
        q2: "Email address",
        label: "Email address",
        type: 2,
      },
      {
        id: 3,
        required: true,
        name: "code",
        helper: "Laboratory code",
        q2: "Laboratory code",
        label: "Laboratory code",
        type: 2,
      },
      {
        id: 4,
        required: true,
        name: "department",
        helper: "Department",
        q2: "Department",
        label: "Department",
        type: 2,
      },
      {
        id: 5,
        required: true,
        name: "section",
        helper: "Section",
        q2: "Section",
        label: "Section",
        type: 2,
      },
      {
        id: 6,
        required: true,
        name: "contactperson",
        helper: "Name contact person",
        q2: "Name contact person",
        label: "Name contact person",
        type: 2,
      },
      {
        id: 7,
        required: false,
        name: "contactpersonextra",
        helper: "Name contact person(extra)",
        q2: "Name contact person(extra)",
        label: "Name contact person(extra)",
        type: 2,
      },
      {
        id: 8,
        required: true,
        name: "emailcontactperson",
        helper: "Email address contact person",
        q2: "Email address contact person",
        label: "Email address contact person",
        type: 2,
      },
      {
        id: 9,
        required: false,
        name: "emailcontactpersonextra",
        helper: "Email address contact person (Extra)",
        q2: "Email address contact person (Extra)",
        label: "Email address contact person (Extra)",
        type: 2,
      },
      {
        id: 10,
        required: true,
        name: "country",
        helper: "Country",
        q2: "Country",
        label: "Country",
        type: 3,
        values: countries,
        valuesArray: countriesArray,
        values_label: "name",
        values_key: "om",
      },
      {
        id: 11,
        required: true,
        name: "active",
        helper: "Active",
        q2: "Active",
        label: "Active",
        type: 3,
        values: [
          { id: "false", name: "Inactive" },
          { id: "true", name: "Active" },
        ],
        valuesArray: {
          false: [{ id: "false", name: "Inactive" }],
          true: [{ id: "true", name: "Active" }],
        },
        values_label: "name",
        values_key: "id",
      },
      {
        id: 12,
        required: true,
        name: "population",
        helper: "Patient population",
        q2: "Patient population",
        label: "Patient population",
        type: 3,
        values: [
          { id: "outpatients", name: "Outpatients" },
          { id: "all", name: "All patients" },
          { id: "unknown", name: "Unknown" },
        ],
        valuesArray: {
          outpatients: [{ id: "outpatients", name: "Outpatients" }],
          all: [{ id: "all", name: "All patients" }],
          unknown: [{ id: "unknown", name: "Unknown" }],
        },
        values_label: "name",
        values_key: "id",
      },
      {
        id: 13,
        required: true,
        name: "is_single",
        helper:
          "Are the results from single instruments or from a group of instruments?",
        q2: "Are the results from single instruments or from a group of instruments?",
        label:
          "Are the results from single instruments or from a group of instruments?",
        type: 3,
        values: [
          { id: "single", name: "Single instrument" },
          { id: "group", name: "A group of instruments" },
        ],
        valuesArray: {
          single: [{ id: "false", name: "Single instrument" }],
          group: [{ id: "true", name: "A group of instruments" }],
        },
        values_label: "name",
        values_key: "id",
      },
      {
        id: 14,
        required: true,
        name: "days_before_reporting",
        helper: "Number of days without data before reporting",
        q2: "Number of days without data before reporting",
        label: "Number of days without data before reporting",
        type: 2,
      },
    ],
  };
  useFirestoreConnect([
    {
      collection: "labs",
      storeAs: "labs",
      orderBy: ["om", "asc"],
    },
    {
      collection: "countries",
      storeAs: "countries",
      orderBy: ["om", "asc"],
    },
  ]);
  const createElement = (newMeasure) => {
    if (typeof newMeasure.id === "undefined") {
      addLab(newMeasure);
    } else {
      updateLab(newMeasure, newMeasure.id);
    }
  };

  const deleteElement = (newMeasure) => {
    deleteLab(newMeasure);
  };

  dispatch({ type: actions.SET_MENU, payload: "Labs" });

  return (
    <>
      <Container className={classes.content}>
        <div>
          {isLoaded &&
            (isEmpty ? (
              <Redirect to="/signin" />
            ) : labs ? (
              <FSPTable
                model={labs}
                classes={classes}
                createElement={(measure) => createElement(measure)}
                deleteElement={(measure) => deleteElement(measure)}
                form={form}
                meta={{ countries: countries }}
              />
            ) : (
              <CircularProgress />
            ))}
        </div>
        <IQCSection classes={classes} />
      </Container>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    labs: state.firestore.ordered.labs,
    authStatus: state.firebase.auth,
    countries: state.firestore.ordered.countries,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addLab: (lab) => dispatch(addLab(lab)),
    deleteLab: (id) => dispatch(deleteLab(id)),
    updateLab: (lab, id) => dispatch(updateLab(lab, id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Labs);
