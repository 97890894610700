import { combineReducers } from 'redux';
import authReducer from './authReducer';
import questionnaireReducer from './questionnaireReducer';
import { firebaseReducer } from 'react-redux-firebase';
import { firestoreReducer } from 'redux-firestore';

export default combineReducers({
  auth: authReducer,
  questionnaire: questionnaireReducer,
  firebase: firebaseReducer,
  firestore: firestoreReducer,
});
