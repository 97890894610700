const convertArrayByField = (model, field = 'docId') => {
    let converted = [];
    model && model.forEach(record => {
        let f = record[field] == undefined ? 'xxx' : record[field];
        if (!(f in converted)) {
            converted[f] = [];
        }
        converted[f].push(record);
    })
    return converted;
}
export default convertArrayByField;