import firebase from "firebase/app";
import * as actions from "./actionTypes";

export const addLaboratorysetting = (laboratorysetting) => async (dispatch, getState) => {
  console.log("bbbb", laboratorysetting);
  try {
    dispatch({ type: actions.ADD_TODO_START });
    const uid = getState().firebase.auth.uid;
    const firestore = firebase.firestore();
    await firestore.collection("laboratorysettings").add({
      ...laboratorysetting,
      authorId: uid,
      createdAt: new Date(),
      updatedAt: new Date(),
    });
    dispatch({ type: actions.ADD_TODO_SUCESS });
  } catch (err) {
    dispatch({ type: actions.ADD_TODO_FAIL, err });
  }
};

export const updateLaboratorysetting = (laboratorysetting, id) => async (dispatch) => {
  console.log("bbbb", laboratorysetting);
  try {
    dispatch({ type: actions.UPDATE_TODO_START });
    const firestore = firebase.firestore();
    await firestore.collection("laboratorysettings").doc(id).update({
      lab: laboratorysetting.lab,
      email: laboratorysetting.email,
      conditions: laboratorysetting.conditions,
      active: laboratorysetting.active,
      format: laboratorysetting.format,
      from:laboratorysetting.from,
      to:laboratorysetting.to,
      heading:laboratorysetting.heading,
      population:laboratorysetting.population,
      single:laboratorysetting.single,
      dateformat:laboratorysetting.dateformat,
      decimal:laboratorysetting.decimal,
      daysbeforereporting:laboratorysetting.daysbeforereporting,
      encoding:laboratorysetting.encoding,
      csvdelimiter:laboratorysetting.csvdelimiter,
      positions:laboratorysetting.positions,
      updatedAt: new Date(),
    });
    dispatch({ type: actions.UPDATE_TODO_SUCESS });
  } catch (err) {
    dispatch({ type: actions.UPDATE_TODO_FAIL, err });
  }
};

export const deleteLaboratorysetting = (id) => async (dispatch) => {
  console.log("bbbb", id);
  try {
    dispatch({ type: actions.DELETE_TODO_START });
    const firestore = firebase.firestore();
    await firestore.collection("laboratorysettings").doc(id).delete();
    dispatch({ type: actions.DELETE_TODO_SUCESS });
  } catch (err) {
    dispatch({ type: actions.DELETE_TODO_FAIL, err });
  }
};
