import React from "react";
import { connect } from "react-redux";
import { useFirestoreConnect } from "react-redux-firebase";
import { Redirect } from "react-router-dom";
import {
  addModelgroup,
  deleteModelgroup,
  updateModelgroup,
} from "../../../store/actions/modelgroupActions";
import Container from "@material-ui/core/Container";
import useStyles from "../dashboard/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../store/actions/actionTypes";
import FSPTable from "../../common/FSPTable/FSPTable";
import convertArrayByField from "../../common/utils";

const Modelgroups = ({
  modelgroups,
  models,
  authStatus,
  addModelgroup,
  updateModelgroup,
  deleteModelgroup,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { isEmpty, isLoaded } = authStatus;
  let modelsArray = convertArrayByField(models, "id");
  const form = {
    titles: {
      index: "Instrument model groups",
      create: "Add instrument model group",
      update: "Update instrument model group",
    },
    keyField: 0,
    indexFields: [0, 1, 2, 3],
    fields: [
      {
        id: 0,
        name: "om",
        required: true,
        helper: "ID",
        q2: "ID",
        label: "ID",
        type: 2,
      },
      {
        id: 1,
        required: true,
        name: "name",
        helper: "Instrument model group name",
        q2: "Instrument model group name",
        label: "Instrument model group name",
        type: 2,
      },
      {
        id: 2,
        required: true,
        name: "models",
        helper: "Models",
        q2: "Models",
        label: "Models",
        type: 5,
        values: models,
        valuesArray: modelsArray,
        values_label: "name",
        values_key: "id",
        model: "models",
        modelId: "id",
        modelLabel: "name",
      },
      {
        id: 3,
        required: true,
        name: "active",
        helper: "Active",
        q2: "Active",
        label: "Active",
        type: 3,
        values: [
          { id: "false", name: "Inactive" },
          { id: "true", name: "Active" },
        ],
        valuesArray: {
          false: [{ id: "false", name: "Inactive" }],
          true: [{ id: "true", name: "Active" }],
        },
        values_label: "name",
        values_key: "id",
        // model:'manufacturers',
        // modelId: 'db_id',
        // modelLabel:'name'
      },
    ],
  };
  useFirestoreConnect([
    {
      collection: "modelgroups",
      storeAs: "modelgroups",
      orderBy: ["om", "asc"],
    },
    {
      collection: "models",
      storeAs: "models",
      orderBy: ["name", "asc"],
    },
  ]);
  const createElement = (newMeasure) => {
    if (typeof newMeasure.id === "undefined") {
      addModelgroup(newMeasure);
    } else {
      updateModelgroup(newMeasure, newMeasure.id);
    }
  };

  const deleteElement = (newMeasure) => {
    deleteModelgroup(newMeasure);
  };

  dispatch({ type: actions.SET_MENU, payload: "Instrument model groups" });

  return (
    <Container className={classes.content}>
      <div>
        {isLoaded &&
          (isEmpty ? (
            <Redirect to="/signin" />
          ) : modelgroups ? (
            <FSPTable
              model={modelgroups}
              classes={classes}
              createElement={(measure) => createElement(measure)}
              deleteElement={(measure) => deleteElement(measure)}
              form={form}
              meta={{ models: models }}
            />
          ) : (
            <CircularProgress />
          ))}
      </div>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    modelgroups: state.firestore.ordered.modelgroups,
    authStatus: state.firebase.auth,
    models: state.firestore.ordered.models,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addModelgroup: (modelgroup) => dispatch(addModelgroup(modelgroup)),
    deleteModelgroup: (id) => dispatch(deleteModelgroup(id)),
    updateModelgroup: (modelgroup, id) =>
      dispatch(updateModelgroup(modelgroup, id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Modelgroups);
