import React from "react";
import { connect } from "react-redux";
import { useFirestoreConnect } from "react-redux-firebase";
import { Redirect } from "react-router-dom";
import {
  addTypegroup,
  deleteTypegroup,
  updateTypegroup,
} from "../../../store/actions/typegroupActions";
import Container from "@material-ui/core/Container";
import useStyles from "../dashboard/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../store/actions/actionTypes";
import FSPTable from "../../common/FSPTable/FSPTable";
import convertArrayByField from "../../common/utils";

const Typegroups = ({
  typegroups,
  instrumenttypes,
  authStatus,
  addTypegroup,
  updateTypegroup,
  deleteTypegroup,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { isEmpty, isLoaded } = authStatus;
  let instrumenttypesArray = convertArrayByField(instrumenttypes, "id");
  const form = {
    titles: {
      index: "Instrument type groups",
      create: "Add instrument type group",
      update: "Update instrument type group",
    },
    keyField: 0,
    indexFields: [0, 1,2,3],
    fields: [
      {
        id: 0,
        name: "om",
        required: true,
        helper: "ID",
        q2: "ID",
        label: "ID",
        type: 2,
      },
      {
        id: 1,
        required: true,
        name: "name",
        helper: "Instrument type group name",
        q2: "Instrument type group name",
        label: "Instrument type group name",
        type: 2,
      },
      {
        id: 2,
        required: true,
        name: "instrumenttypes",
        helper: "Instrument types",
        q2: "Instrument types",
        label: "Instrument types",
        type: 5,
        values: instrumenttypes,
        valuesArray: instrumenttypesArray,
        values_label: "name",
        values_key: "id",
        model: "instrumenttypes",
        modelId: "id",
        modelLabel: "name",
      },
      {
        id: 3,
        required: true,
        name: "active",
        helper: "Active",
        q2: "Active",
        label: "Active",
        type: 3,
        values: [
          { id: "false", name: "Inactive" },
          { id: "true", name: "Active" },
        ],
        valuesArray: {
          false: [{ id: "false", name: "Inactive" }],
          true: [{ id: "true", name: "Active" }],
        },
        values_label: "name",
        values_key: "id",
        // model:'manufacturers',
        // modelId: 'db_id',
        // modelLabel:'name'
      },
    ],
  };
  useFirestoreConnect([
    {
      collection: "typegroups",
      storeAs: "typegroups",
      orderBy: ["om", "asc"],
    },
    {
      collection: "instrumenttypes",
      storeAs: "instrumenttypes",
      orderBy: ["name", "asc"],
    }
    
  ]);
  const createElement = (newMeasure) => {
    if (typeof newMeasure.id === "undefined") {
      addTypegroup(newMeasure);
    } else {
      updateTypegroup(newMeasure, newMeasure.id);
    }
  };

  const deleteElement = (newMeasure) => {
    deleteTypegroup(newMeasure);
  };

  dispatch({ type: actions.SET_MENU, payload: "Instrument type groups" });

  return (
    <Container className={classes.content}>
      <div>
        {isLoaded &&
          (isEmpty ? (
            <Redirect to="/signin" />
          ) : typegroups ? (
            <FSPTable
              model={typegroups}
              classes={classes}
              createElement={(measure) => createElement(measure)}
              deleteElement={(measure) => deleteElement(measure)}
              form={form}
              meta={{ instrumenttypes: instrumenttypes }}
            />
          ) : (
            <CircularProgress />
          ))}
      </div>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    typegroups: state.firestore.ordered.typegroups,
    instrumenttypes: state.firestore.ordered.instrumenttypes,
    authStatus: state.firebase.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addTypegroup: (typegroup) => dispatch(addTypegroup(typegroup)),
    deleteTypegroup: (id) => dispatch(deleteTypegroup(id)),
    updateTypegroup: (typegroup, id) =>
      dispatch(updateTypegroup(typegroup, id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Typegroups);
