import React, { Component } from "react";
import { getData } from "./utils4";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { LineChart, Line } from "recharts";
import { MenuItem, TextField } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { trackPromise } from "react-promise-tracker";

class Demodata3 extends Component {
  constructor(props) {
    super();
    this.handleChangeShow = this.handleChangeShow.bind(this);
    this.handleChangeShow2 = this.handleChangeShow2.bind(this);
    this.handleChangeModel = this.handleChangeModel.bind(this);
    this.handleChangeDate1 = this.handleChangeDate1.bind(this);
    this.handleChangeDate2 = this.handleChangeDate2.bind(this);

    this.state = {
      data: [],
      device: "week",
      model: "gr1",
      parameter: "Chloride",
      date1: "2020-01-01",
      date2: "2020-12-31",
    };
  }

  handleChangeShow(event, newAlignment) {
    console.log(event.target);
    console.log(newAlignment);
    this.setState({
      device: event.target.value,
    });
    this.refresh(event.target.value);
  }

  handleChangeShow2(event, newAlignment) {
    console.log(event.target);
    console.log(newAlignment);
    this.setState({
      parameter: event.target.value,
    });
    this.refresh(this.state.device, event.target.value);
  }

  handleChangeModel(event, newAlignment) {
    console.log(event.target);
    console.log(newAlignment);
    this.setState({
      model: event.target.value,
    });
    this.refresh(this.state.device, this.state.parameter, event.target.value);
  }
  handleChangeDate1(event, newAlignment) {
    console.log(event.target);
    console.log(newAlignment);
    this.setState({
      date1: event.target.value,
    });
    this.refresh(this.state.device, this.state.parameter, this.state.model, event.target.value);
  }

  handleChangeDate2(event, newAlignment) {
    console.log(event.target);
    console.log(newAlignment);
    this.setState({
      date2: event.target.value,
    });
    this.refresh(this.state.device, this.state.parameter, this.state.model, this.state.date1, event.target.value);
  }

  refresh(
    device = this.state.device, 
    parameter = this.state.parameter, 
    model = this.state.model,
    date1 = this.state.date1,
    date2 = this.state.date2
    ) {
    //  var url = new URL("http://localhost/iqapi_psql/index-test.php");
    var url = new URL("http://localhost:8080/test");
    // var url = new URL("https://triple-acre-333913.ew.r.appspot.com/test");

    var params = [
      // ["r", "site/data"],
      ["parameter", parameter],
      ["group", device],
      ["model", model],
      ["date1", date1],
      ["date2", date2],
    ];

    url.search = new URLSearchParams(params).toString();
    trackPromise(
      fetch(url)
        .then((response) => response.json())
        .then((data) => {
          console.log(data.data);

          let number = 0;
          let result = [];
          data.map((row, index) => {
            // let row =data[key];
            let a = row.year + "." + row.month;
            //   console.log(a);
            if (!result[a]) {
              result[a] = [];
              result[a]["name"] = a;
            }
            result[a][row["device"]] = parseFloat(row["percentile_cont"]);
          });
          let b = [];
          // console.log(result);
          Object.keys(result).map((key, index) => {
            let row = result[key];
            //   console.log(row);
            b.push(row);
          });
          // console.log(b);
          this.setState({ data0: data });
          this.setState({ data: b });
        })
    );
  }
  componentDidMount() {
    this.refresh();
  }
  render() {
    console.log(this.state);
    return (
      <div>
        <h1>Monthly/weekly median values</h1>
        Grouping:
        <TextField
          required
          id={"distribution2"}
          value={this.state["device"]}
          name={"distribution2"}
          select
          className={this.props.classes.selectfilter}
          // label={"Laboratory"}
          onChange={this.handleChangeShow}
          InputProps={{ disableUnderline: true }}
          InputProps={{
            classes: {
              input: this.props.classes.statSelect,
            },
          }}

          //  helperText={"Select lab"}
        >
          <MenuItem key={1} value={"week"}>
            Week
          </MenuItem>
          <MenuItem key={2} value={"month"}>
            Month
          </MenuItem>
        </TextField>
        Analyte:
        <TextField
          required
          id={"distribution2"}
          value={this.state["parameter"]}
          name={"distribution2"}
          select
          className={this.props.classes.selectfilter}
          // label={"Laboratory"}
          onChange={this.handleChangeShow2}
          InputProps={{ disableUnderline: true }}
          InputProps={{
            classes: {
              input: this.props.classes.statSelect,
            },
          }}

          //  helperText={"Select lab"}
        >
          <MenuItem key={1} value={"Chloride"}>
            Chloride
          </MenuItem>
          <MenuItem key={2} value={"FT4"}>
            FT4
          </MenuItem>
          <MenuItem key={3} value={"Calcium"}>
            Calcium
          </MenuItem>
        </TextField>
        Model group:
        <TextField
          required
          id={"distribution3"}
          value={this.state["model"]}
          name={"distribution3"}
          select
          className={this.props.classes.selectfilter}
          // label={"Laboratory"}
          onChange={this.handleChangeModel}
          InputProps={{ disableUnderline: true }}
          InputProps={{
            classes: {
              input: this.props.classes.statSelect,
            },
          }}

          //  helperText={"Select lab"}
        >
          <MenuItem key={1} value={"gr1"}>
            Model group 1
          </MenuItem>
          <MenuItem key={2} value={"gr2"}>
            Model group 2
          </MenuItem>
          <MenuItem key={3} value={"gr3"}>
            Model group 3
          </MenuItem>
        </TextField>
        From:
        <TextField
          id="date"
          // label="Birthday"
          type="date"
          value={this.state["date1"]}
          onChange={this.handleChangeDate1}
          format="MM/dd/yyyy"
          // className={classes.textField}
          InputLabelProps={{
            shrink: true,
          }}
        />
        To: 
         <TextField
          id="date2"
          // label="Birthday"
          type="date"
          value={this.state["date2"]}
          onChange={this.handleChangeDate2}
          format="MM/dd/yyyy"
          // className={classes.textField}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <ResponsiveContainer width="100%" aspect={3}>
          <LineChart
            width={800}
            height={300}
            data={this.state.data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis type="number" domain={[2, 2.75]} />
            <Tooltip />
            <Legend />
            <Line dataKey="dev 11" stroke="#8884d8" />
            <Line dataKey="dev 21" stroke="#82ca9d" />
            <Line dataKey="dev 31" stroke="#4285f4" />
            <Line dataKey="dev 41" stroke="#ea4335" />
            <Line dataKey="dev 51" stroke="#fbbc04" />
          </LineChart>
        </ResponsiveContainer>
        <TableContainer component={Paper}>
          <Table
            className={this.props.classes.table}
            aria-label="a dense table"
          >
            <TableHead>
              <TableRow>
                <TableCell>Year</TableCell>

                <TableCell align="left">Data</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* {this.state.data0 && this.state.data0.map((q, index2) => (
                <TableRow>
                  <TableCell>
                    {q.percentile_cont}
                  </TableCell>
                 
                </TableRow>
        ))} */}
            </TableBody>
            {/* {this.props.questionnaire.forEach((q) =>
          q.children.forEach((x) => <div>x.name</div>)
        )} */}
          </Table>
        </TableContainer>
      </div>
    );
  }
}

export default Demodata3;
