import React, { Component } from "react";
import IQCRowKey from "./IQCRowKey";
import IQCAnalyteRowButtons from "./IQCAnalyteRowButtons";
import { Delete, Edit } from "@material-ui/icons";
import IQCAnalyteRowButton from "./IQCAnalyteRowButton";
import IQCRowTitle from "./IQCRowTitle";
import IQCRowFloatElement from "./IQCRowFloatElement";
import Box from "@material-ui/core/Box";
import IQCHeader from "./IQCHeader";

class IQCRowFloat extends Component {
  render() {
    const { classes } = this.props;
    return (
      <div class="jss281 jss284 jss282 jss1030 jss1011 jss703 list-card">
       {this.props.children}
       
      </div>
    );
  }
}

export default IQCRowFloat;
