import firebase from "firebase/app";
import * as actions from "./actionTypes";

export const addTypegroup = (typegroup) => async (dispatch, getState) => {
  console.log("bbbb", typegroup);
  try {
    dispatch({ type: actions.ADD_TODO_START });
    const uid = getState().firebase.auth.uid;
    const firestore = firebase.firestore();
    await firestore.collection("typegroups").add({
      ...typegroup,
      authorId: uid,
      createdAt: new Date(),
      updatedAt: new Date(),
    });
    dispatch({ type: actions.ADD_TODO_SUCESS });
  } catch (err) {
    dispatch({ type: actions.ADD_TODO_FAIL, err });
  }
};

export const updateTypegroup = (typegroup, id) => async (dispatch) => {
  console.log("bbbb", typegroup);
  try {
    dispatch({ type: actions.UPDATE_TODO_START });
    const firestore = firebase.firestore();
    await firestore.collection("typegroups").doc(id).update({
      om: typegroup.om,
      name: typegroup.name,
      instrumenttypes: typegroup.instrumenttypes,
      active: typegroup.active,
      updatedAt: new Date(),
    });
    dispatch({ type: actions.UPDATE_TODO_SUCESS });
  } catch (err) {
    dispatch({ type: actions.UPDATE_TODO_FAIL, err });
  }
};

export const deleteTypegroup = (id) => async (dispatch) => {
  console.log("bbbb", id);
  try {
    dispatch({ type: actions.DELETE_TODO_START });
    const firestore = firebase.firestore();
    await firestore.collection("typegroups").doc(id).delete();
    dispatch({ type: actions.DELETE_TODO_SUCESS });
  } catch (err) {
    dispatch({ type: actions.DELETE_TODO_FAIL, err });
  }
};
