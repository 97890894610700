import React from "react";
import { connect } from "react-redux";
import { useFirestoreConnect } from "react-redux-firebase";
import { Redirect } from "react-router-dom";
import {
  addMethod,
  deleteMethod,
  updateMethod,
} from "../../../store/actions/methodActions";
import Container from "@material-ui/core/Container";
import useStyles from "../dashboard/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../store/actions/actionTypes";
import FSPTable from "../../common/FSPTable/FSPTable";
import convertArrayByField from "../../common/utils";

const Methods = ({
  methods,
  instrumenttypes,
  authStatus,
  addMethod,
  updateMethod,
  deleteMethod,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { isEmpty, isLoaded } = authStatus;
  let instrumenttypesArray = convertArrayByField(instrumenttypes, "id");
  const form = {
    titles: {
      index: "Methods",
      create: "Add method",
      update: "Update method",
    },
    keyField: 0,
    indexFields: [0, 1,2,3],
    fields: [
      {
        id: 0,
        name: "om",
        required: true,
        helper: "ID",
        q2: "ID",
        label: "ID",
        type: 2,
      },
      {
        id: 1,
        required: true,
        name: "name",
        helper: "Method name",
        q2: "Method name",
        label: "Method name",
        type: 2,
      },
      {
        id: 2,
        required: true,
        name: "abbreviation",
        helper: "Abbreviation",
        q2: "Abbreviation",
        label: "Abbreviation",
        type: 2,
      },
      {
        id: 3,
        required: true,
        name: "active",
        helper: "Active",
        q2: "Active",
        label: "Active",
        type: 3,
        values: [
          { id: "false", name: "Inactive" },
          { id: "true", name: "Active" },
        ],
        valuesArray: {
          false: [{ id: "false", name: "Inactive" }],
          true: [{ id: "true", name: "Active" }],
        },
        values_label: "name",
        values_key: "id",
      },
    ],
  };
  useFirestoreConnect([
    {
      collection: "methods",
      storeAs: "methods",
      orderBy: ["om", "asc"],
    }
  ]);
  const createElement = (newMeasure) => {
    if (typeof newMeasure.id === "undefined") {
      addMethod(newMeasure);
    } else {
      updateMethod(newMeasure, newMeasure.id);
    }
  };

  const deleteElement = (newMeasure) => {
    deleteMethod(newMeasure);
  };

  dispatch({ type: actions.SET_MENU, payload: "Methods" });

  return (
    <Container className={classes.content}>
      <div>
        {isLoaded &&
          (isEmpty ? (
            <Redirect to="/signin" />
          ) : methods ? (
            <FSPTable
              model={methods}
              classes={classes}
              createElement={(measure) => createElement(measure)}
              deleteElement={(measure) => deleteElement(measure)}
              form={form}
              meta={{ instrumenttypes: instrumenttypes }}
            />
          ) : (
            <CircularProgress />
          ))}
      </div>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    methods: state.firestore.ordered.methods,
    instrumenttypes: state.firestore.ordered.instrumenttypes,
    authStatus: state.firebase.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addMethod: (method) => dispatch(addMethod(method)),
    deleteMethod: (id) => dispatch(deleteMethod(id)),
    updateMethod: (method, id) =>
      dispatch(updateMethod(method, id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Methods);
