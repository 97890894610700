import React from 'react';
import { connect } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import { Redirect } from 'react-router-dom';
import { addUnit, deleteUnit, updateUnit } from '../../../store/actions/unitActions';
import Container from '@material-ui/core/Container';
import useStyles from "../dashboard/styles";
import CircularProgress from '@material-ui/core/CircularProgress';
import { useDispatch, useSelector } from 'react-redux'
import * as actions from '../../../store/actions/actionTypes';
import FSPTable from '../../common/FSPTable/FSPTable';

const Units = ({ units, authStatus, addUnit, updateUnit, deleteUnit }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { isEmpty, isLoaded } = authStatus;
    const form = {
        titles: {
            index: 'Units',
            create: 'Add unit',
            update: 'Update unit',
        },
        keyField: 0,
        indexFields: [0, 1],
        fields: [
            {
                id: 0,
                name: 'db_id',
                required: true,
                helper: 'ID',
                q2: 'ID',
                label: 'ID',
                type: 2,
            },
            {
                id: 1,
                required: true,
                name: 'name',
                helper: 'Unit name',
                q2: 'Unit name',
                label: 'Unit name',
                type: 2,
            }
        ]
    };
    useFirestoreConnect([
        {
            collection: "units",
            storeAs: 'units',
            orderBy: ['db_id', 'asc']
        }
    ]);
    const createElement = (newMeasure) => {
        if (typeof newMeasure.id === 'undefined') {
            addUnit(newMeasure);
        } else {
            updateUnit(newMeasure, newMeasure.id)
        }
    }

    const deleteElement = (newMeasure) => {
        deleteUnit(newMeasure);
    }

    dispatch({ type: actions.SET_MENU, payload: 'Units' })

    return (
        <Container className={classes.content}>
            <div>
                {isLoaded &&
                    (isEmpty ? (
                        <Redirect to='/signin' />
                    ) : (
                        units ?
                            <FSPTable
                                model={units}
                                classes={classes}
                                createElement={(measure) => createElement(measure)}
                                deleteElement={(measure) => deleteElement(measure)}
                                form={form}
                            /> :
                            <CircularProgress />
                    ))}
            </div>
        </Container>
    );
};

const mapStateToProps = (state) => {
    return {
        units: state.firestore.ordered.units,
        authStatus: state.firebase.auth,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addUnit: (unit) => dispatch(addUnit(unit)),
        deleteUnit: (id) => dispatch(deleteUnit(id)),
        updateUnit: (unit, id) => dispatch(updateUnit(unit, id)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Units);
