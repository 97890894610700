import firebase from "firebase/app";
import * as actions from "./actionTypes";

export const addDemodatax = (manufacturer) => async (dispatch, getState) => {
  console.log("bbbb", manufacturer);
  try {
    dispatch({ type: actions.ADD_TODO_START });
    const uid = getState().firebase.auth.uid;
    const firestore = firebase.firestore();
    await firestore.collection("demodatax").add({
      ...manufacturer,
      authorId: uid,
      values: {...manufacturer.values},
      createdAt: new Date(),
      updatedAt: new Date(),
    });
    dispatch({ type: actions.ADD_TODO_SUCESS });
  } catch (err) {
    dispatch({ type: actions.ADD_TODO_FAIL, err });
  }
};

export const updateDemodatax = (manufacturer, id) => async (dispatch) => {
  console.log("bbbb", id);
  console.log("b1", manufacturer.name);
  console.log("b2", manufacturer.values);
  try {
    dispatch({ type: actions.UPDATE_TODO_START });
    const firestore = firebase.firestore();
    await firestore
      .collection("demodatax")
      .doc(id)
      .update({
        db_id: manufacturer.db_id,
        name: manufacturer.name,
         values: {...manufacturer.values},
        updatedAt: new Date(),
      });
    dispatch({ type: actions.UPDATE_TODO_SUCESS });
  } catch (err) {
    dispatch({ type: actions.UPDATE_TODO_FAIL, err });
  }
};

export const deleteDemodatax = (id) => async (dispatch) => {
  console.log("bbbb", id);
  try {
    dispatch({ type: actions.DELETE_TODO_START });
    const firestore = firebase.firestore();
    await firestore.collection("demodatax").doc(id).delete();
    dispatch({ type: actions.DELETE_TODO_SUCESS });
  } catch (err) {
    dispatch({ type: actions.DELETE_TODO_FAIL, err });
  }
};
