import React, { Component } from "react";

class IQCWarning extends Component {
  render() {
    return (
      <div class="jss281 jss284 jss282 jss704">
        <div class="jss725 jss709">
          <div class="jss726 jss765 jss771 jss796 jss823 jss824">
            <span
              class="material-icons2     jss338"
              aria-hidden="true"
            >
              warning
            </span>
          </div>
          <div class="jss726 jss765 jss775 jss806 jss823 jss831">
            <div class="jss838 jss841">
              <div>
                <h6 class="jss518 jss535">
                  <span>{this.props.title}</span>
                </h6>
                <span class="jss518 jss536 jss551">
                  <span>
                  {this.props.description}
                  </span>
                </span>
              </div>
              <div class="jss839"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default IQCWarning;
