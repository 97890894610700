import React, { Component } from "react";

class IQCAnalyteRowButton extends Component {
  render() {
    return (
      <button class="jss324 jss318 jss312 jss1050" tabindex="0" type="button">
        <span class="jss323">
          <span class="material-icons jss346 jss327" aria-hidden="true">
          {this.props.children}
          </span>
        </span>
        <span class="jss687"></span>
      </button>
    );
  }
}

export default IQCAnalyteRowButton;
