import firebase from 'firebase/app';
import * as actions from './actionTypes';

export const addComment = (comment) => async (dispatch, getState) => {
    console.log('bbbb', comment);
  try {
    dispatch({ type: actions.ADD_TODO_START });
    const uid = getState().firebase.auth.uid;
    const firestore = firebase.firestore();
    await firestore.collection('comments').add({
      ...comment,
      authorId: uid,
      createdAt: new Date(),
      updatedAt: new Date(),
    });
    dispatch({ type: actions.ADD_TODO_SUCESS });
  } catch (err) {
    dispatch({ type: actions.ADD_TODO_FAIL, err });
  }
};

export const updateComment = (comment, id) => async (dispatch) => {
    console.log('bbbb', comment);
  try {
    dispatch({ type: actions.UPDATE_TODO_START });
    const firestore = firebase.firestore();
    await firestore.collection('comments').doc(id).update({
      // db_id: comment.db_id,
      source: comment.source,
      target: comment.target,
      lab: comment.lab,
      updatedAt: new Date(),
    });
    dispatch({ type: actions.UPDATE_TODO_SUCESS });
  } catch (err) {
    dispatch({ type: actions.UPDATE_TODO_FAIL, err });
  }
};

export const deleteComment = (id) => async (dispatch) => {
    console.log('bbbb', id);
  try {
    dispatch({ type: actions.DELETE_TODO_START });
    const firestore = firebase.firestore();
    await firestore.collection('comments').doc(id).delete();
    dispatch({ type: actions.DELETE_TODO_SUCESS });
  } catch (err) {
    dispatch({ type: actions.DELETE_TODO_FAIL, err });
  }
};
