import React, { Component } from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { Delete, Add, Edit } from "@material-ui/icons";
import {
  Checkbox,
  FormControlLabel,
  Switch,
  Typography,
} from "@material-ui/core";
import IQCRow from "./IQCRow";
import IQCAnalyteRow from "./IQCAnalyteRow";
import IQCWarning from "./IQCWarning";
import IQCHeader from "./IQCHeader";
import IQCRowFloat from "./IQCRowFloat";
import IQCRowFloatMini from "./IQCRowFloatMini";
import IQCForm from "./IQCForm";
import IQCRowKey from "./IQCRowKey";
import IQCAnalyteRowButtons from "./IQCAnalyteRowButtons";
import IQCAnalyteRowButton from "./IQCAnalyteRowButton";
import IQCRowTitle from "./IQCRowTitle";
import IQCSubRow from "./IQCSubRow";
import IQCSubRowFields from "./IQCSubRowFields.js";
import IQCRowFloatElement from "./IQCRowFloatElement";
import IQCRowFloatElements from "./IQCRowFloatElements";
import IQCRowFloatSwitch from './IQCRowFloatSwitch';

class IQCSection extends Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={""}>
        <div className={""}>
          <IQCWarning
            title="Scheme version is in use"
            description="The schema version is currently assigned to at least one
          survey. Any changes will affect these surveys and their past
          and present results! The effects of these changes may be
          permanent."
          />
          <IQCHeader
            classes={classes}
            title="Laboratory 1"
            subtitle="Department 1"
            switchtitle="Subscribe to all notification"
          />
          <IQCRow classes={classes}>
            <IQCRowKey type="1" />
            <IQCRowTitle title="Laboratory 1" subtitle="Department 1" />
            <IQCAnalyteRowButtons classes={classes}>
              <IQCAnalyteRowButton classes={classes}>
                <Edit />
              </IQCAnalyteRowButton>
              <IQCAnalyteRowButton classes={classes}>
                <Delete />
              </IQCAnalyteRowButton>
            </IQCAnalyteRowButtons>
          </IQCRow>
          <IQCSubRow classes={classes}>
            <IQCSubRowFields
              classes={classes}
              title="Laboratory 1"
              subtitle="Department 1"
            />
            <IQCSubRowFields
              classes={classes}
              title="Laboratory 1"
              subtitle="Department 1"
            />
          </IQCSubRow>
          <IQCRow classes={classes}>
            <IQCRowKey type="1" />
            <IQCRowTitle title="Laboratory 1" subtitle="Department 1" />
            <IQCAnalyteRowButtons classes={classes}>
              <IQCAnalyteRowButton classes={classes}>
                <Edit />
              </IQCAnalyteRowButton>
              <IQCAnalyteRowButton classes={classes}>
                <Delete />
              </IQCAnalyteRowButton>
            </IQCAnalyteRowButtons>
          </IQCRow>
          <IQCAnalyteRow classes={classes} />
          <IQCAnalyteRow classes={classes} />
          <IQCRowFloat classes={classes}>
            <IQCHeader
              classes={classes}
              title="Laboratory 1"
              subtitle="Department 1"
              switchtitle="Automatic subscription for new channels"
            />

            <IQCRowFloatElements>
              <IQCRowFloatElement />
              <IQCRowFloatElement />
              <IQCRowFloatElement />
              <IQCRowFloatElement />
              <IQCRowFloatElement />
              <IQCRowFloatElement />
            </IQCRowFloatElements>
          </IQCRowFloat>
          <IQCRowFloat classes={classes}>
            <IQCHeader
              classes={classes}
              title="Laboratory 1"
              subtitle="Department 1"
              switchtitle="Automatic subscription for new channels"
            />

            <IQCRowFloatElements>
              <IQCRowFloatElement />
              <IQCRowFloatElement />
              <IQCRowFloatElement />
              <IQCRowFloatElement />
              <IQCRowFloatElement />
              <IQCRowFloatElement />
            </IQCRowFloatElements>
          </IQCRowFloat>
          <IQCRowFloat classes={classes}>
            <IQCHeader
              classes={classes}
              title="Automatic subscription for new laboratory channels"
              subtitle="Channels"
              switchtitle="Automatic subscription for new channels"
            />

            <IQCRowFloatElements>
              <IQCRowFloatSwitch title="Import errors" subtitle="Missing data, mapping errors..." isChecked="true" />
              <IQCRowFloatSwitch title="Statistical warnings" subtitle="Performance specifications and alarms" />
              <IQCRowFloatSwitch title="Measurement comments" subtitle="New commit, commit change" />
              <IQCRowFloatSwitch title="Laboratory messages" subtitle="Message from the laboratory" />
              <IQCRowFloatSwitch title="System messages" subtitle="Message from system and admins" />
              <IQCRowFloatSwitch title="Users errors" subtitle="Forgot password, feedback" />
            </IQCRowFloatElements>
          </IQCRowFloat>
          <IQCRowFloat classes={classes}>
            <IQCHeader
              classes={classes}
              title="Laboratory 1"
              subtitle="Channels"
              switchtitle="Automatic subscription for new channels"
            />

            <IQCRowFloatElements>
              <IQCRowFloatSwitch title="Import errors" subtitle="Missing data, mapping errors..." isChecked="true" />
              <IQCRowFloatSwitch title="Statistical warnings" subtitle="Performance specifications and alarms" />
              <IQCRowFloatSwitch title="Measurement comments" subtitle="New commit, commit change" />
              <IQCRowFloatSwitch title="Laboratory messages" subtitle="Message from the laboratory" />
              <IQCRowFloatSwitch title="System messages" subtitle="Message from system and admins" />
              <IQCRowFloatSwitch title="Users errors" subtitle="Forgot password, feedback" />
            </IQCRowFloatElements>
          </IQCRowFloat>
          <IQCRowFloat classes={classes}>
            <IQCHeader
              classes={classes}
              title="Laboratory 2"
              subtitle="Channels"
              switchtitle="Automatic subscription for new channels"
            />

            <IQCRowFloatElements>
              <IQCRowFloatSwitch title="Import errors" subtitle="Missing data, mapping errors..." isChecked="true" />
              <IQCRowFloatSwitch title="Statistical warnings" subtitle="Performance specifications and alarms" isChecked="true" />
              <IQCRowFloatSwitch title="Measurement comments" subtitle="New commit, commit change" isChecked="true" />
              <IQCRowFloatSwitch title="Laboratory messages" subtitle="Message from the laboratory" />
              <IQCRowFloatSwitch title="System messages" subtitle="Message from system and admins" />
              <IQCRowFloatSwitch title="Users errors" subtitle="Forgot password, feedback" />
            </IQCRowFloatElements>
          </IQCRowFloat>

          <IQCRowFloat classes={classes}>
            <IQCHeader
              classes={classes}
              title="Laboratory channels"
              subtitle="Channels"
              switchtitle="E-mail notification"
            />

            <IQCRowFloatElements>
              <IQCRowFloatSwitch title="Import errors" subtitle="Missing data, mapping errors..." isChecked="true" />
              <IQCRowFloatSwitch title="Statistical warnings" subtitle="Performance specifications and alarms" />
              <IQCRowFloatSwitch title="Measurement comments" subtitle="New commit, commit change" />
              <IQCRowFloatSwitch title="Laboratory messages" subtitle="Message from the laboratory" />
            </IQCRowFloatElements>
          </IQCRowFloat>
          <IQCRowFloat classes={classes}>
            <IQCHeader
              classes={classes}
              title="Laboratory Administrator"
              subtitle="Channels"
              switchtitle="E-mail notification"
            />

            <IQCRowFloatElements>
              <IQCRowFloatSwitch title="Users errors" subtitle="Forgot password" />
              <IQCRowFloatSwitch title="Authorization Request" subtitle="New registration, Laboratory Access Request" />

            </IQCRowFloatElements>
          </IQCRowFloat>
          <IQCRowFloat classes={classes}>
            <IQCHeader
              classes={classes}
              title="System"
              subtitle="Channels"
              switchtitle="E-mail notification"
            />

            <IQCRowFloatElements>
              <IQCRowFloatSwitch title="System messages" subtitle="Message from system and admins" />
              <IQCRowFloatSwitch title="EQA data" subtitle="EQA data modification eg. new analyte" />

            </IQCRowFloatElements>
          </IQCRowFloat>
          <IQCRowFloat classes={classes}>
            <IQCHeader
              classes={classes}
              title="System Administrator"
              subtitle="Channels"
              switchtitle="E-mail notification"
            />

            <IQCRowFloatElements>
              <IQCRowFloatSwitch title="System errors" subtitle="Message from system and admins" />
              <IQCRowFloatSwitch title="Feedback" subtitle="Users feeback" />

            </IQCRowFloatElements>
          </IQCRowFloat>

          <IQCRowFloatMini classes={classes} />
          {/* <IQCForm classes={classes} /> */}
        </div>
      </div>
    );
  }
}

export default IQCSection;
