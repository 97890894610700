import { format } from "d3-format";
import { timeFormat } from "d3-time-format";

import React from "react";
import PropTypes from "prop-types";

import { ChartCanvas, Chart } from "react-stockcharts";
import {
  BarSeries,
  AreaSeries,
  CandlestickSeries,
  LineSeries,
  MACDSeries,
  BollingerSeries,
  ScatterSeries,
  SquareMarker,
  TriangleMarker,
  CircleMarker,
} from "react-stockcharts/lib/series";
import { XAxis, YAxis } from "react-stockcharts/lib/axes";
import {
  CrossHairCursor,
  EdgeIndicator,
  CurrentCoordinate,
  MouseCoordinateX,
  MouseCoordinateY,
} from "react-stockcharts/lib/coordinates";
import { HoverTooltip } from "react-stockcharts/lib/tooltip";

import { discontinuousTimeScaleProviderBuilder } from "react-stockcharts/lib/scale";
import {
  OHLCTooltip,
  MovingAverageTooltip,
  MACDTooltip,
  BollingerBandTooltip,
} from "react-stockcharts/lib/tooltip";
import { ema, sma, macd, bollingerBand } from "react-stockcharts/lib/indicator";
import { fitWidth } from "react-stockcharts/lib/helper";
import { set } from "d3-collection";
import { scaleOrdinal, schemeCategory10, scalePoint } from "d3-scale";

import { StackedBarSeries } from "react-stockcharts/lib/series";
import disableScroll from "disable-scroll";
import { ClickCallback } from "react-stockcharts/lib/interactive";

function getMaxUndefined(calculators) {
  return calculators
    .map((each) => each.undefinedLength())
    .reduce((a, b) => Math.max(a, b));
}
const LENGTH_TO_SHOW = 1800;

const macdAppearance = {
  stroke: {
    macd: "#BA68C8",
    signal: "#6A1B9A",
  },
  fill: {
    divergence: "#4682B4",
  },
};
const bbStroke = {
  top: "#d6e2ec",
  middle: "#d6e2ec",
  bottom: "#d6e2ec",
};
const limitStroke = {
  top: "#d6e2ec",
  middle: "#ff0000",
  bottom: "#d6e2ec",
};

const bbFill = "#4682B4";
const dateFormat = timeFormat("%Y-%m-%d");
const numberFormat = format(".2f");

function tooltipContent(ys) {
  return ({ currentItem, xAccessor }) => {
    return {
      x: dateFormat(xAccessor(currentItem)),
      y: [
        {
          label: "Median",
          value: currentItem.open && numberFormat(currentItem.open),
        },
        {
          label: "Hypo",
          value: numberFormat(currentItem.low) + "%",
        },
        {
          label: "Hypo avg",
          value: numberFormat(currentItem.limit_hypo.ave_hypo) + "%",
        },
        {
          label: "Hyper",
          value: numberFormat(currentItem.high) + "%",
        },
        {
          label: "Hyper avg",
          value: numberFormat(currentItem.limit.ave_hyper) + "%",
        },
        {
          label: "All",
          value: numberFormat(currentItem.volume),
        },
      ]
        .concat(
          ys.map((each) => ({
            label: each.label,
            value: each.value(currentItem),
            stroke: each.stroke,
          }))
        )
        .filter((line) => line.value),
    };
  };
}

class CandleStickChartPanToLoadMore extends React.Component {
  getData() {
    const { data: inputData } = this.props;

    const ema26 = sma()
      .id(0)
      .options({ windowSize: 30 })
      .merge((d, c) => {
        d.ema26 = c;
      })
      .accessor((d) => d.ema26)
      .stroke("#6A1B9A")
      .fill("#6A1B9A");

    const ema12 = sma()
      .id(1)
      .options({ windowSize: 7 })
      .merge((d, c) => {
        d.ema12 = c;
      })
      .accessor((d) => d.ema12)
      .stroke("#BA68C8")
      .fill("#BA68C8");

    const macdCalculator = macd()
      .options({
        fast: 7,
        slow: 30,
        signal: 7,
      })
      .merge((d, c) => {
        d.macd = c;
      })
      .accessor((d) => d.macd);

    const smaVolume50 = sma()
      .id(3)
      .options({
        windowSize: 50,
        sourcePath: "volume",
      })
      .merge((d, c) => {
        d.smaVolume50 = c;
      })
      .accessor((d) => d.smaVolume50);

    const maxWindowSize = getMaxUndefined([
      ema26,
      ema12,
      macdCalculator,
      smaVolume50,
    ]);

    const bb = bollingerBand()
      .merge((d, c) => {
        d.bb = c;
      })
      .accessor((d) => d.bb);

    /* SERVER - START */
    const dataToCalculate = inputData.slice(-LENGTH_TO_SHOW - maxWindowSize);

    const calculatedData = ema26(
      ema12(macdCalculator(smaVolume50(dataToCalculate)))
    );
    // const calculatedData = ema26(ema12(macdCalculator(smaVolume50(bb(dataToCalculate)))));
    const indexCalculator =
      discontinuousTimeScaleProviderBuilder().indexCalculator();

    const { index } = indexCalculator(calculatedData);
    /* SERVER - END */

    const xScaleProvider =
      discontinuousTimeScaleProviderBuilder().withIndex(index);
    const {
      data: linearData,
      xScale,
      xAccessor,
      displayXAccessor,
    } = xScaleProvider(calculatedData.slice(-LENGTH_TO_SHOW));

    return {
      ema26,
      ema12,
      macdCalculator,
      smaVolume50,
      linearData,
      data: linearData,
      xScale,
      xAccessor,
      displayXAccessor,
    };
  }

  constructor(props) {
    super(props);
    const { data: inputData } = props;
    const ema26 = sma()
      .id(0)
      .options({ windowSize: 30 })
      .merge((d, c) => {
        d.ema26 = c;
      })
      .accessor((d) => d.ema26)
      .stroke("#6A1B9A")
      .fill("#6A1B9A");

    const ema12 = sma()
      .id(1)
      .options({ windowSize: 7 })
      .merge((d, c) => {
        d.ema12 = c;
      })
      .accessor((d) => d.ema12)
      .stroke("#BA68C8")
      .fill("#BA68C8");

    const macdCalculator = macd()
      .options({
        fast: 12,
        slow: 26,
        signal: 9,
      })
      .merge((d, c) => {
        d.macd = c;
      })
      .accessor((d) => d.macd);

    const smaVolume50 = sma()
      .id(3)
      .options({
        windowSize: 50,
        sourcePath: "volume",
      })
      .merge((d, c) => {
        d.smaVolume50 = c;
      })
      .accessor((d) => d.smaVolume50);

    const maxWindowSize = getMaxUndefined([
      ema26,
      ema12,
      macdCalculator,
      smaVolume50,
    ]);
    /* SERVER - START */
    const dataToCalculate = inputData.slice(-LENGTH_TO_SHOW - maxWindowSize);

    const calculatedData = ema26(
      ema12(macdCalculator(smaVolume50(dataToCalculate)))
    );
    const indexCalculator =
      discontinuousTimeScaleProviderBuilder().indexCalculator();

    const { index } = indexCalculator(calculatedData);
    /* SERVER - END */

    const xScaleProvider =
      discontinuousTimeScaleProviderBuilder().withIndex(index);
    const {
      data: linearData,
      xScale,
      xAccessor,
      displayXAccessor,
    } = xScaleProvider(calculatedData.slice(-LENGTH_TO_SHOW));

    this.state = {
      ema26,
      ema12,
      macdCalculator,
      smaVolume50,
      linearData,
      data: linearData,
      xScale,
      xAccessor,
      displayXAccessor,
      ypan: true,
    };
    this.handleDownloadMore = this.handleDownloadMore.bind(this);
  }
  componentDidMount() {
    if (!this.props.ypan) {
      console.log("componentDidMount", this.state);
      this.setState({
        yPan: true,
      });
    }
  }
  handleDownloadMore(start, end) {
    if (Math.ceil(start) === end) return;
    const {
      data: prevData,
      ema26,
      ema12,
      macdCalculator,
      smaVolume50,
    } = this.state;
    const { data: inputData } = this.props;

    if (inputData.length === prevData.length) return;

    const rowsToDownload = end - Math.ceil(start);

    const maxWindowSize = getMaxUndefined([
      ema26,
      ema12,
      macdCalculator,
      smaVolume50,
    ]);

    /* SERVER - START */
    const dataToCalculate = inputData.slice(
      -rowsToDownload - maxWindowSize - prevData.length,
      -prevData.length
    );

    const calculatedData = ema26(
      ema12(macdCalculator(smaVolume50(dataToCalculate)))
    );
    const indexCalculator = discontinuousTimeScaleProviderBuilder()
      .initialIndex(Math.ceil(start))
      .indexCalculator();
    const { index } = indexCalculator(
      calculatedData.slice(-rowsToDownload).concat(prevData)
    );
    /* SERVER - END */

    const xScaleProvider = discontinuousTimeScaleProviderBuilder()
      .initialIndex(Math.ceil(start))
      .withIndex(index);

    const {
      data: linearData,
      xScale,
      xAccessor,
      displayXAccessor,
    } = xScaleProvider(calculatedData.slice(-rowsToDownload).concat(prevData));

    setTimeout(() => {
      // simulate a lag for ajax
      this.setState({
        data: linearData,
        xScale,
        xAccessor,
        displayXAccessor,
      });
    }, 300);
  }

  changeScrollOn() {
    disableScroll.off();
  }
  changeScrollOff() {
    disableScroll.on();
  }

  render() {
    console.log("xxxxx", this.props.data);
    const { type, width, ratio } = this.props;
    const d = this.getData();
    const bb = bollingerBand()
      .merge((d, c) => {
        d.bb = c;
      })
      .accessor((d) => d.bb);
    const {
      data,
      ema26,
      ema12,
      macdCalculator,
      smaVolume50,
      xScale,
      xAccessor,
      displayXAccessor,
    } = d;
    const col = ["#64b5f6", "#bdbdbd", "#F1F8E9", "#bdbdbd"];
    const col2 = ["#000000", "#000000", "#000000", "#000000"];
    const fill = (d, i) => col[i + 1];
    const fill2 = (d, i) => col2[i + 1];

    return (
      <div
        onWheel={(event) => {
          event.preventDefault();
        }}
        onMouseEnter={this.changeScrollOff}
        onMouseLeave={this.changeScrollOn}
      >
        {/* <ChartCanvas ratio={ratio} width={width} height={650} */}
        <ChartCanvas
          ratio={ratio}
          width={width}
          height={800}
          margin={{ left: 50, right: 50, top: 0, bottom: 0 }}
          type={type}
          seriesName="Results"
          data={data}
          xScale={xScale}
          xAccessor={xAccessor}
          displayXAccessor={displayXAccessor}
          onLoadMore={this.handleDownloadMore}
        >
          <Chart
            id={1}
            height={420}
            // yPan={!this.props.form.ypan}
            yPan={false}
            // yExtents={[d => [d.close, d.close], ema26.accessor(), ema12.accessor()]}
            yExtents={[
              (d) => [
                parseFloat(d.values[5].result),
                parseFloat(d.values[5].result),
              ],
              ema26.accessor(),
              ema12.accessor(),
            ]}
            // yExtents={[d => [d.close, d.close*0.9], ema26.accessor(), ema12.accessor()]}
            padding={{ top: 10, bottom: 40 }}
          >
            <XAxis
              axisAt="bottom"
              orient="bottom"
              showTicks={true}
              outerTickSize={0}
            />
            <YAxis axisAt="left" orient="left" ticks={5} />

            <MouseCoordinateY
              at="right"
              orient="right"
              displayFormat={format(".2f")}
            />

            {this.props.form.ema26 && (
              <LineSeries
                yAccessor={ema26.accessor()}
                stroke={ema26.stroke()}
                // stroke="#6A1B9A"
                strokeWidth="3"
              />
            )}

            {this.props.form.ema12 && (
              <LineSeries
                yAccessor={ema12.accessor()}
                // stroke="#BA68C8"
                stroke={ema12.stroke()}
                strokeWidth="2"
              />
            )}

            <LineSeries
              yAccessor={(d) => parseFloat(d.values[0].result)}
              strokeDasharray="Dot"
            />
            <LineSeries
              yAccessor={(d) => parseFloat(d.values[1].result)}
              strokeDasharray="Dot"
            />
            <LineSeries
              yAccessor={(d) => parseFloat(d.values[2].result)}
              strokeDasharray="Dot"
            />
            <LineSeries
              yAccessor={(d) => parseFloat(d.values[3].result)}
              strokeDasharray="Dot"
            />
            <LineSeries
              yAccessor={(d) => parseFloat(d.values[4].result)}
              strokeDasharray="Dot"
            />
            <LineSeries
              yAccessor={(d) => parseFloat(d.values[5].result)}
              strokeDasharray="Dot"
              strokeWidth="4"
            />
            <LineSeries
              yAccessor={(d) => parseFloat(d.values[6].result)}
              strokeDasharray="Dot"
            />
            <LineSeries
              yAccessor={(d) => parseFloat(d.values[7].result)}
              strokeDasharray="Dot"
            />
            <LineSeries
              yAccessor={(d) => parseFloat(d.values[8].result)}
              strokeDasharray="Dot"
            />
            <LineSeries
              yAccessor={(d) => parseFloat(d.values[9].result)}
              strokeDasharray="Dot"
            />
            <LineSeries
              yAccessor={(d) => parseFloat(d.values[10].result)}
              strokeDasharray="Dot"
            />
            <LineSeries
              yAccessor={(d) => parseFloat(d.values[11].result)}
              strokeDasharray="Dot"
            />
            <LineSeries
              yAccessor={(d) => parseFloat(d.values[12].result)}
              strokeDasharray="Dot"
            />
            <LineSeries
              yAccessor={(d) => parseFloat(d.values[13].result)}
              strokeDasharray="Dot"
            />
            <LineSeries
              yAccessor={(d) => parseFloat(d.values[14].result)}
              strokeDasharray="Dot"
            />
            <LineSeries
              yAccessor={(d) => parseFloat(d.values[15].result)}
              strokeDasharray="LongDash"
              stroke="#ff7f0e"
            />
            <LineSeries
              yAccessor={(d) => parseFloat(d.values[16].result)}
              strokeDasharray="Dot"
            />
            <LineSeries
              yAccessor={(d) => parseFloat(d.values[17].result)}
              strokeDasharray="Dot"
            />
            <LineSeries
              yAccessor={(d) => parseFloat(d.values[18].result)}
              strokeDasharray="Dot"
            />
            <LineSeries
              yAccessor={(d) => parseFloat(d.values[19].result)}
              strokeDasharray="Dot"
            />
            <LineSeries
              yAccessor={(d) => parseFloat(d.values[20].result)}
              strokeDasharray="Dot"
            />
            <LineSeries
              yAccessor={(d) => parseFloat(d.values[21].result)}
              strokeDasharray="Dot"
            />
            <LineSeries
              yAccessor={(d) => parseFloat(d.values[22].result)}
              strokeDasharray="Dot"
            />
            <LineSeries
              yAccessor={(d) => parseFloat(d.values[23].result)}
              strokeDasharray="Dot"
            />
            <LineSeries
              yAccessor={(d) => parseFloat(d.values[24].result)}
              strokeDasharray="Dot"
            />
            <LineSeries
              yAccessor={(d) => parseFloat(d.values[25].result)}
              strokeDasharray="Dot"
            />
            <LineSeries
              yAccessor={(d) => parseFloat(d.values[26].result)}
              strokeDasharray="Dot"
            />
            <LineSeries
              yAccessor={(d) => parseFloat(d.values[27].result)}
              strokeDasharray="Dot"
            />
            <LineSeries
              yAccessor={(d) => parseFloat(d.values[28].result)}
              strokeDasharray="Dot"
            />
            <LineSeries
              yAccessor={(d) => parseFloat(d.values[29].result)}
              strokeDasharray="Dot"
            />
            <LineSeries
              yAccessor={(d) => parseFloat(d.values[30].result)}
              strokeDasharray="Dot"
            />
            <LineSeries
              yAccessor={(d) => parseFloat(d.values[31].result)}
              strokeDasharray="Dot"
            />
            <LineSeries
              yAccessor={(d) => parseFloat(d.values[32].result)}
              strokeDasharray="Dot"
            />
            <LineSeries
              yAccessor={(d) => parseFloat(d.values[33].result)}
              strokeDasharray="Dot"
            />
            <LineSeries
              yAccessor={(d) => parseFloat(d.values[34].result)}
              strokeDasharray="Dot"
            />
            <LineSeries
              yAccessor={(d) => parseFloat(d.values[35].result)}
              strokeDasharray="LongDash"
              stroke="#ff7f0e"
            />
            <LineSeries
              yAccessor={(d) => parseFloat(d.values[36].result)}
              strokeDasharray="Dot"
            />
            <LineSeries
              yAccessor={(d) => parseFloat(d.values[37].result)}
              strokeDasharray="Dot"
            />
            <LineSeries
              yAccessor={(d) => parseFloat(d.values[38].result)}
              strokeDasharray="Dot"
            />
            <LineSeries
              yAccessor={(d) => parseFloat(d.values[39].result)}
              strokeDasharray="Dot"
            />
            
            <LineSeries yAccessor={(d) => parseFloat(d.values[40].result)} strokeDasharray="Dot"  />
            <LineSeries yAccessor={(d) => parseFloat(d.values[41].result)} strokeDasharray="Dot"  />
            <LineSeries yAccessor={(d) => parseFloat(d.values[42].result)} strokeDasharray="Dot"  />
            <LineSeries yAccessor={(d) => parseFloat(d.values[43].result)} strokeDasharray="Dot"  />
            <LineSeries yAccessor={(d) => parseFloat(d.values[44].result)} strokeDasharray="Dot"  />
            <LineSeries yAccessor={(d) => parseFloat(d.values[45].result)} strokeDasharray="Dot"  />
            <LineSeries yAccessor={(d) => parseFloat(d.values[46].result)} strokeDasharray="Dot"  />
            <LineSeries yAccessor={(d) => parseFloat(d.values[47].result)} strokeDasharray="Dot"  />
            <LineSeries yAccessor={(d) => parseFloat(d.values[48].result)} strokeDasharray="Dot"  />
            <LineSeries yAccessor={(d) => parseFloat(d.values[49].result)} strokeDasharray="Dot"  />

            <LineSeries yAccessor={(d) => parseFloat(d.values[50].result)} strokeDasharray="Dot"  />
            <LineSeries yAccessor={(d) => parseFloat(d.values[51].result)} strokeDasharray="Dot"  />
            <LineSeries yAccessor={(d) => parseFloat(d.values[52].result)} strokeDasharray="Dot"  />
            <LineSeries yAccessor={(d) => parseFloat(d.values[53].result)} strokeDasharray="Dot"  />
            <LineSeries yAccessor={(d) => parseFloat(d.values[54].result)} strokeDasharray="Dot"  />
            <LineSeries yAccessor={(d) => parseFloat(d.values[55].result)} strokeDasharray="Dot"  />
            <LineSeries yAccessor={(d) => parseFloat(d.values[56].result)} strokeDasharray="Dot"  />
            <LineSeries yAccessor={(d) => parseFloat(d.values[57].result)} strokeDasharray="Dot"  />
            <LineSeries yAccessor={(d) => parseFloat(d.values[58].result)} strokeDasharray="Dot"  />
            <LineSeries yAccessor={(d) => parseFloat(d.values[59].result)} strokeDasharray="Dot"  />

            <LineSeries yAccessor={(d) => parseFloat(d.values[60].result)} strokeDasharray="Dot"  />
            <LineSeries yAccessor={(d) => parseFloat(d.values[61].result)} strokeDasharray="Dot"  />
            <LineSeries yAccessor={(d) => parseFloat(d.values[62].result)} strokeDasharray="Dot"  />
            <LineSeries yAccessor={(d) => parseFloat(d.values[63].result)} strokeDasharray="Dot"  />
            <LineSeries yAccessor={(d) => parseFloat(d.values[64].result)} strokeDasharray="Dot"  />
            <LineSeries yAccessor={(d) => parseFloat(d.values[65].result)} strokeDasharray="Dot"  />
            <LineSeries yAccessor={(d) => parseFloat(d.values[66].result)} strokeDasharray="Dot"  />
            <LineSeries yAccessor={(d) => parseFloat(d.values[67].result)} strokeDasharray="Dot"  />
            <LineSeries yAccessor={(d) => parseFloat(d.values[68].result)} strokeDasharray="Dot"  />
            <LineSeries yAccessor={(d) => parseFloat(d.values[69].result)} strokeDasharray="Dot"  />

            <LineSeries yAccessor={(d) => parseFloat(d.values[70].result)} strokeDasharray="Dot"  />
            <LineSeries yAccessor={(d) => parseFloat(d.values[71].result)} strokeDasharray="Dot"  />
            <LineSeries yAccessor={(d) => parseFloat(d.values[72].result)} strokeDasharray="Dot"  />
            <LineSeries yAccessor={(d) => parseFloat(d.values[73].result)} strokeDasharray="Dot"  />
            <LineSeries yAccessor={(d) => parseFloat(d.values[74].result)} strokeDasharray="Dot"  />
            <LineSeries yAccessor={(d) => parseFloat(d.values[75].result)} strokeDasharray="Dot"  />
            <LineSeries yAccessor={(d) => parseFloat(d.values[76].result)} strokeDasharray="Dot"  />
            <LineSeries yAccessor={(d) => parseFloat(d.values[77].result)} strokeDasharray="Dot"  />
            <LineSeries yAccessor={(d) => parseFloat(d.values[78].result)} strokeDasharray="Dot"  />
            <LineSeries yAccessor={(d) => parseFloat(d.values[79].result)} strokeDasharray="Dot"  />

            <LineSeries yAccessor={(d) => parseFloat(d.values[80].result)} strokeDasharray="Dot"  />
            <LineSeries yAccessor={(d) => parseFloat(d.values[81].result)} strokeDasharray="Dot"  />
            <LineSeries yAccessor={(d) => parseFloat(d.values[82].result)} strokeDasharray="Dot"  />
            <LineSeries yAccessor={(d) => parseFloat(d.values[83].result)} strokeDasharray="Dot"  />
            <LineSeries yAccessor={(d) => parseFloat(d.values[84].result)} strokeDasharray="Dot"  />
            <LineSeries yAccessor={(d) => parseFloat(d.values[85].result)} strokeDasharray="Dot"  />
            <LineSeries yAccessor={(d) => parseFloat(d.values[86].result)} strokeDasharray="Dot"  />
            <LineSeries yAccessor={(d) => parseFloat(d.values[87].result)} strokeDasharray="Dot"  />
            <LineSeries yAccessor={(d) => parseFloat(d.values[88].result)} strokeDasharray="Dot"  />
            <LineSeries yAccessor={(d) => parseFloat(d.values[89].result)} strokeDasharray="Dot"  />

            <LineSeries yAccessor={(d) => parseFloat(d.values[90].result)} strokeDasharray="Dot"  />
            <LineSeries yAccessor={(d) => parseFloat(d.values[91].result)} strokeDasharray="Dot"  />
            <LineSeries yAccessor={(d) => parseFloat(d.values[92].result)} strokeDasharray="Dot"  />
            <LineSeries yAccessor={(d) => parseFloat(d.values[93].result)} strokeDasharray="Dot"  />
            <LineSeries yAccessor={(d) => parseFloat(d.values[94].result)} strokeDasharray="Dot"  />
            <LineSeries yAccessor={(d) => parseFloat(d.values[95].result)} strokeDasharray="Dot"  />
            <LineSeries yAccessor={(d) => parseFloat(d.values[96].result)} strokeDasharray="Dot"  />
            <LineSeries yAccessor={(d) => parseFloat(d.values[97].result)} strokeDasharray="Dot"  />
            <LineSeries yAccessor={(d) => parseFloat(d.values[98].result)} strokeDasharray="Dot"  />
            <LineSeries yAccessor={(d) => parseFloat(d.values[99].result)} strokeDasharray="Dot"  />

            <LineSeries yAccessor={(d) => parseFloat(d.values[100].result)} strokeDasharray="Dot"  />
            <LineSeries yAccessor={(d) => parseFloat(d.values[101].result)} strokeDasharray="Dot"  />
            <LineSeries yAccessor={(d) => parseFloat(d.values[102].result)} strokeDasharray="Dot"  />
            <LineSeries yAccessor={(d) => parseFloat(d.values[103].result)} strokeDasharray="Dot"  />
            <LineSeries yAccessor={(d) => parseFloat(d.values[104].result)} strokeDasharray="Dot"  />
            <LineSeries yAccessor={(d) => parseFloat(d.values[105].result)} strokeDasharray="Dot"  />
            <LineSeries yAccessor={(d) => parseFloat(d.values[106].result)} strokeDasharray="Dot"  />
            <LineSeries yAccessor={(d) => parseFloat(d.values[107].result)} strokeDasharray="Dot"  />
            <LineSeries yAccessor={(d) => parseFloat(d.values[108].result)} strokeDasharray="Dot"  />
            <LineSeries yAccessor={(d) => parseFloat(d.values[109].result)} strokeDasharray="Dot"  />

            <ScatterSeries
              yAccessor={(d) =>
                (d.values[5].result > d.bb.top ||
                  d.values[5].result < d.bb.bottom) &&
                d.values[5].result
              }
              marker={CircleMarker}
              markerProps={{ r: 2, stroke: "#ff0000", fill: "#ff0000" }}
              highlightOnHover="true"
              hoverStrokeWidth="10"
            />
            <ScatterSeries
              yAccessor={(d) =>
                d.values[5].result <= d.bb.top &&
                d.values[5].result >= d.bb.bottom &&
                d.values[5].result
              }
              marker={CircleMarker}
              markerProps={{ r: 2, stroke: "#00ff00", fill: "#00ff00" }}
              highlightOnHover="true"
              hoverStrokeWidth="10"
            />
            <CurrentCoordinate
              yAccessor={ema26.accessor()}
              fill={ema26.stroke()}
            />
            <CurrentCoordinate
              yAccessor={ema12.accessor()}
              fill={ema12.stroke()}
            />

            <EdgeIndicator
              itemType="last"
              orient="right"
              edgeAt="right"
              yAccessor={(d) => d.close}
              fill={(d) => (d.close > d.open ? "#6BA583" : "#FF0000")}
            />

            {false && <OHLCTooltip origin={[-40, 0]} />}

            {(this.props.form.ema26 || this.props.form.ema12) && false && (
              <MovingAverageTooltip
                onClick={(e) => console.log(e)}
                origin={[-38, 15]}
                options={[
                  {
                    yAccessor: ema26.accessor(),
                    type: ema26.type(),
                    stroke: ema26.stroke(),
                    ...ema26.options(),
                  },
                  {
                    yAccessor: ema12.accessor(),
                    type: ema12.type(),
                    stroke: ema12.stroke(),
                    ...ema12.options(),
                  },
                ]}
              />
            )}

            {this.props.form.range && (
              <BollingerSeries
                yAccessor={(d) => d.bb}
                stroke={bbStroke}
                opacity={0.07}
                fill={bbFill}
              />
            )}

            {this.props.form.range && false && (
              <BollingerBandTooltip
                origin={[-38, 60]}
                yAccessor={(d) => d.bb}
                options={bb.options()}
              />
            )}
            <HoverTooltip
              bgFill={"#ebebeb"}
              bgOpacity={0.5}
              // opacity={0.07}
              // fill={bbFill}
              yAccessor={ema26.accessor()}
              tooltipContent={tooltipContent([
                {
                  label: "7-day avg.",
                  value: (d) => numberFormat(ema12.accessor()(d)),
                  stroke: ema12.stroke(),
                },
                // {
                // 	label: `${ema12.type()}(${ema12.options()
                // 		.windowSize})`,
                // 	value: d => numberFormat(ema12.accessor()(d)),
                // 	stroke: ema12.stroke()
                // },
                // {
                // 	label: `${ema26.type()}(${ema26.options()
                // 		.windowSize})`,
                // 	value: d => numberFormat(ema26.accessor()(d)),
                // 	stroke: ema26.stroke()
                // },
                {
                  label: "30-day avg.",
                  value: (d) => numberFormat(ema26.accessor()(d)),
                  stroke: ema26.stroke(),
                },
              ])}
              fontSize={15}
            />
            <ClickCallback
              // onMouseMove={(moreProps, e) => { console.log("onMouseMove", moreProps, e); }}
              onMouseDown={(moreProps, e) => {
                console.log("onMouseDown", moreProps, e);
              }}
              onClick={(moreProps, e) => {
                console.log("onClick", moreProps, e);
              }}
              onDoubleClick={(moreProps, e) => {
                console.log("onDoubleClick", moreProps, e);
              }}
              onContextMenu={(moreProps, e) => {
                console.log("onContextMenu", moreProps, e);
              }}
              onPan={(moreProps, e) => {
                console.log("onPan", moreProps, e);
              }}
              onPanEnd={(moreProps, e) => {
                console.log("onPanEnd", moreProps, e);
              }}
            />
          </Chart>

          {this.props.form.distribution && (
            <Chart
              id={2}
              height={300}
              // yExtents={[d => 0, smaVolume50.accessor()]}
              yExtents={[(d) => [0, d.volume], 10, 0]}
              // yExtents={[d => [0, d.volume+50], 50, 0]}
              origin={(w, h) => [0, h - 330]}
            >
              {/* origin={(w, h) => [0, h - 150]} padding={{ top: 10, bottom: 10 }} >	 */}
              <YAxis
                axisAt="right"
                orient="right"
                ticks={5}
                tickFormat={format(".2s")}
              />

              <MouseCoordinateY
                at="left"
                orient="left"
                displayFormat={format(".4s")}
              />

              {/* <BarSeries yAccessor={d => d.volume} fill={d => d.close > d.open ? "#6BA583" : "#FF0000"} /> */}
              <StackedBarSeries
                // yExtents={[d => [0, d.volume], 10, 0]}
                yAccessor={[
                  (d) => (d.low * d.volume) / 100,
                  (d) =>
                    d.volume -
                    (d.high * d.volume) / 100 -
                    (d.low * d.volume) / 100,
                  (d) => (d.high * d.volume) / 100,
                ]}
                fill={fill}
                stroke={false}
                // width="2"
              />
              {/* <AreaSeries yAccessor={smaVolume50.accessor()} stroke={smaVolume50.stroke()} fill={smaVolume50.fill()} /> */}
              <BollingerSeries
                yAccessor={(d) => d.limit}
                stroke={limitStroke}
                opacity={0.17}
                fill={bbFill}
              />
              <BollingerSeries
                yAccessor={(d) => d.limit_hypo}
                stroke={limitStroke}
                opacity={0.17}
                fill={bbFill}
              />
              <ScatterSeries
                // yAccessor={d => (((d.low * d.volume) / 100>d.limit.top) && d.limit.top )}
                //yAccessor={d => (d.high > d.limit.ave_hyper_max) ? d.limit.top : -10}
                yAccessor={(d) =>
                  d.high > d.limit.ave_hyper_max ? d.limit.high : -10
                }
                marker={CircleMarker}
                markerProps={{ r: 3, stroke: "#e22046", fill: "#e22046" }}
                highlightOnHover="true"
                hoverStrokeWidth="10"
              />
              <ScatterSeries
                // yAccessor={d => (((d.low * d.volume) / 100>d.limit.top) && d.limit.top )}
                //yAccessor={d => (d.high < d.limit.ave_hyper_min) ? d.limit.bottom : -10}
                yAccessor={(d) =>
                  d.high < d.limit.ave_hyper_min ? d.limit.high : -10
                }
                marker={CircleMarker}
                markerProps={{ r: 3, stroke: "#e22046", fill: "#e22046" }}
                highlightOnHover="true"
                hoverStrokeWidth="10"
              />
              <ScatterSeries
                // yAccessor={d => (((d.low * d.volume) / 100>d.limit.top) && d.limit.top )}
                // yAccessor={d => (d.low > d.limit_hypo.ave_hypo_max) ? d.limit_hypo.bottom : -10}
                yAccessor={(d) =>
                  d.low > d.limit_hypo.ave_hypo_max ? d.limit_hypo.low : -10
                }
                marker={CircleMarker}
                markerProps={{ r: 3, stroke: "#e22046", fill: "#e22046" }}
                highlightOnHover="true"
                hoverStrokeWidth="10"
              />
              <ScatterSeries
                // yAccessor={d => (((d.low * d.volume) / 100>d.limit.top) && d.limit.top )}
                // yAccessor={d => (d.low < d.limit_hypo.ave_hypo_min) ? d.limit_hypo.top : -10}
                yAccessor={(d) =>
                  d.low < d.limit_hypo.ave_hypo_min ? d.limit_hypo.low : -10
                }
                marker={CircleMarker}
                markerProps={{ r: 3, stroke: "#e22046", fill: "#e22046" }}
                highlightOnHover="true"
                hoverStrokeWidth="10"
              />
              <LineSeries
                yAccessor={(d) => d.limit_hypo.low}
                // stroke={ema26.stroke()}
                // stroke="#6A1B9A"
                // strokeWidth="2"
                strokeDasharray="Dot"
              />
              <LineSeries
                yAccessor={(d) => d.limit.high}
                // stroke={ema26.stroke()}
                // stroke="#6A1B9A"
                // strokeWidth="2"
                strokeDasharray="Dot"
              />
            </Chart>
          )}

          {this.props.form.macd && (
            <Chart
              id={3}
              height={150}
              yExtents={macdCalculator.accessor()}
              origin={(w, h) => [0, h - 150]}
              padding={{ top: 10, bottom: 10 }}
            >
              <XAxis axisAt="bottom" orient="bottom" />
              <YAxis axisAt="right" orient="right" ticks={2} />

              <MouseCoordinateX
                at="bottom"
                orient="bottom"
                displayFormat={timeFormat("%Y-%m-%d")}
              />
              <MouseCoordinateY
                at="right"
                orient="right"
                displayFormat={format(".2f")}
              />

              <MACDSeries yAccessor={(d) => d.macd} {...macdAppearance} />

              {this.props.form.macd && false && (
                <MACDTooltip
                  origin={[-38, 15]}
                  yAccessor={(d) => d.macd}
                  options={macdCalculator.options()}
                  appearance={macdAppearance}
                />
              )}
            </Chart>
          )}

          {/* <Chart id={4} height={150}
						yExtents={[d => [0, d.volume], 10, 0]}
						origin={(w, h) => [0, h - 150]} padding={{ top: 10, bottom: 10 }} >

						<XAxis axisAt="bottom" orient="bottom" />
					</Chart> */}
          <CrossHairCursor />
        </ChartCanvas>
      </div>
    );
  }
}

CandleStickChartPanToLoadMore.propTypes = {
  data: PropTypes.array.isRequired,
  width: PropTypes.number.isRequired,
  ratio: PropTypes.number.isRequired,
  type: PropTypes.oneOf(["svg", "hybrid"]).isRequired,
};

CandleStickChartPanToLoadMore.defaultProps = {
  type: "svg",
};

CandleStickChartPanToLoadMore = fitWidth(CandleStickChartPanToLoadMore);

export default CandleStickChartPanToLoadMore;
