import React, { Component } from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { Delete, Add } from "@material-ui/icons";
import {
  Checkbox,
  FormControlLabel,
  Switch,
  Typography,
} from "@material-ui/core";

class IQCSubRow extends Component {
  render() {
    const { classes } = this.props;
    return (
        <div class="">
          <div>
            <ul class="jss579a">
              <li
                class="jss387 jss388 jss355"
                tabindex="0"
                role="button"
              >
                {this.props.children}
                
              </li>
            </ul>
          </div>
        </div>
    );
  }
}

export default IQCSubRow;
