import { MenuItem, TextField } from "@material-ui/core";
import React, { Component } from "react";
import IQCAnalyteRowButton from "./IQCAnalyteRowButton";
import IQCAnalyteRowButtons from "./IQCAnalyteRowButtons";
import IQCSAnalyteRowButtons from "./IQCAnalyteRowButtons";
import IQCAnalyteRowField from "./IQCAnalyteRowField";
import IQCAnalyteRowKey from "./IQCAnalyteRowKey";
import { Delete, Edit } from "@material-ui/icons";

class IQCAnalyteRow extends Component {
  render() {
    const { classes } = this.props;
    return (
      <div class="jss281 jss284 jss282 jss1030 jss1011 jss1045 jss1026">
        <div class="jss1031 jss1012 jss1046">
          <div class="jss725 jss709 jss1051 jss1045">
            <IQCAnalyteRowKey>K</IQCAnalyteRowKey>
            <div class="jss725 jss709 jss1053">
              <div class="jss726 jss765 jss823 jss1054 jss835">
                <IQCAnalyteRowField classes={classes}>
                  <TextField
                    required
                    id={"distribution2"}
                    // value={this.state["device"]}
                    name={"distribution2"}
                    select
                    className={this.props.classes.selectfilter}
                    label={"Laboratory"}
                    onChange={this.handleChangeShow}
                    // InputProps={{ disableUnderline: true }}
                    InputProps={{
                      classes: {
                        input: this.props.classes.statSelect,
                      },
                    }}

                    //  helperText={"Select lab"}
                  >
                    <MenuItem key={1} value={"Demo 1"}>
                      Demo 1
                    </MenuItem>
                    <MenuItem key={2} value={"Demo 2"}>
                      Demo 2
                    </MenuItem>
                    <MenuItem key={3} value={"dev 1"}>
                      Device 1
                    </MenuItem>
                  </TextField>
                </IQCAnalyteRowField>
                <IQCAnalyteRowField classes={classes}>
                  <TextField
                    required
                    id={"distribution2"}
                    // value={this.state["device"]}
                    name={"distribution2"}
                    select
                    className={this.props.classes.selectfilter}
                    label={"Laboratory"}
                    onChange={this.handleChangeShow}
                    // InputProps={{ disableUnderline: true }}
                    InputProps={{
                      classes: {
                        input: this.props.classes.statSelect,
                      },
                    }}

                    //  helperText={"Select lab"}
                  >
                    <MenuItem key={1} value={"Demo 1"}>
                      Demo 1
                    </MenuItem>
                    <MenuItem key={2} value={"Demo 2"}>
                      Demo 2
                    </MenuItem>
                    <MenuItem key={3} value={"dev 1"}>
                      Device 1
                    </MenuItem>
                  </TextField>
                </IQCAnalyteRowField>
                <IQCAnalyteRowButtons classes={classes}>
                  <IQCAnalyteRowButton classes={classes}>
                    <Edit />
                  </IQCAnalyteRowButton>
                  <IQCAnalyteRowButton classes={classes}>
                    <Delete />
                  </IQCAnalyteRowButton>
                </IQCAnalyteRowButtons>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default IQCAnalyteRow;
