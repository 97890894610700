import React, { Component } from "react";
import { MenuItem, TextField } from "@material-ui/core";

class IQCAnalyteRowField extends Component {
  render() {
    return (
      <div class="jss1057">
        <div class="jss875 jss945 jss946 jss1058">{this.props.children}</div>
      </div>
    );
  }
}

export default IQCAnalyteRowField;
